import { Spinner } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  background: #e3e7eb; // neutral100 - customizations may not be loaded
  background: radial-gradient(
    #ffffff,
    #e3e7eb
  ); // white to neutral100 - customizations may not be loaded
  height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledSpinner = styled(Spinner)`
  width: 100px !important;
  height: 100px !important;
  color: #40454c; // spinner will inherit this color - neutral700
`;

export const LoadingPage = () => (
  <Container>
    <StyledSpinner size="xxxl" type="spinner5" />
  </Container>
);
