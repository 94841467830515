import { type EmailValidationRequiredError } from '@mortgagehippo/auth';
import { type IErrorComponentProps } from '@mortgagehippo/util';

import { Content, Layout } from '../../layouts/login';
import { auth } from '../../services/auth';
import { TaskVerifyEmail } from '../tasks/task-verify-email';

export const EmailValidationRequiredErrorPage = (
  props: IErrorComponentProps<EmailValidationRequiredError>
) => {
  const { error } = props;
  const { email } = error;
  const title = 'Email Validation Required';
  const handleFinish = () => {
    auth.login(undefined, {}, false);
  };

  return (
    <Layout title={title} hideTitle>
      <Content>
        <TaskVerifyEmail email={email} onFinish={handleFinish} />
      </Content>
    </Layout>
  );
};
