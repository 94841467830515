import { AuthState, useAuth } from '@mortgagehippo/auth';
import {
  FieldType,
  type ICreditPullScore,
  type IPanel,
  type IPricingLoan,
  type ITask,
  TaskContainer,
  taskHasCompleteAction,
  TaskSkeleton,
  TaskState,
  useTask,
} from '@mortgagehippo/tasks';
import { NotFoundError, usePrevious } from '@mortgagehippo/util';
import * as Sentry from '@sentry/browser';
import { isNil, isNumber } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type IApplicationFile } from '../../hooks/use-application-file';
import { usePrimaryAgent } from '../../hooks/use-primary-agent';
import { useTouchTask } from '../../hooks/use-touch-task';
import { analytics } from '../../services/analytics';
import {
  TASK_CONTAINER_STANDARD_MAX_WIDTH,
  TASK_CONTAINER_WIDE_MAX_WIDTH,
  WIDE_CONTAINER_TASK_TYPES,
} from '../../util/task-width';
import { TaskAuthenticate } from './task-authenticate';
import { TaskCompleted } from './task-completed';
import { TaskLocked } from './task-locked';
import { TaskVerifyEmail } from './task-verify-email';

const TaskContainerWrapper = styled.div<{ wide?: boolean }>`
  position: relative;
  margin: 0 auto;
  max-width: ${(p) =>
    p.wide ? TASK_CONTAINER_WIDE_MAX_WIDTH : TASK_CONTAINER_STANDARD_MAX_WIDTH}px;
`;

const isFieldEnumerable = (fieldType: FieldType) =>
  [
    FieldType.BOOLEAN,
    FieldType.CHECKBOX,
    FieldType.CHOICE,
    FieldType.CONSTANT,
    FieldType.COUNTRY,
    FieldType.DURATION,
    FieldType.SELECT,
    FieldType.USSTATE,
  ].includes(fieldType);

interface ITaskPageContentProps {
  taskId: string;
  applicationFile?: IApplicationFile;
  applicantId?: string;
  handleComplete: () => Promise<any>;
  onError?: (message?: string) => void;
  onPanelChange: (i: number) => void;
}

export const TaskPageContent = (props: ITaskPageContentProps) => {
  const {
    taskId,
    applicationFile,
    applicantId,
    handleComplete: onComplete,
    onPanelChange,
    onError,
  } = props;
  const [user, authState, auth] = useAuth();
  const [emailVerifiedInSession, setEmailVerifiedInSession] = useState(false);
  const applicationFileId = applicationFile?.id ?? null;

  const [, , refetchPrimaryAgent] = usePrimaryAgent(applicationFileId!, true);

  const [task, taskApplicant, taskLoading] = useTask<ITask>(taskId, {
    skip: !taskId,
  });

  const touchTask = useTouchTask();

  const applicant = useMemo(() => {
    if (!applicationFile) return undefined;
    const { applicants } = applicationFile;
    return applicants.items.find((a) => `${a.id}` === applicantId);
  }, [applicationFile, applicantId]);

  const requiresAuth = task?.meta && task.meta.requiresAuthentication;
  const requiresEmailVerification = task?.meta && task.meta.requiresEmailVerification;
  const emailVerified = (applicant && applicant.emailVerified) || emailVerifiedInSession;

  const renderComplete = useCallback(() => {
    if (!applicantId || !applicationFile || !task) return null;
    return (
      <TaskCompleted
        applicationFile={applicationFile}
        currentTaskId={task.id}
        applicantId={applicantId}
      />
    );
  }, [applicantId, applicationFile, task]);

  const handleAction = (action: any) => {
    if (!task?.key) return;

    const { type_name: typeName, payload = {} } = action;

    switch (typeName) {
      case 'VIEW_PANEL': {
        const { panel, panelIndex }: { panel: IPanel; panelIndex: number } = payload;

        if (!panel) {
          return;
        }

        const { title } = panel;
        const defaultTitle = `Panel ${panelIndex + 1}`;

        analytics.event('panel', 'view', {
          label: title,
          applicationFileId,
          taskId: task.id,
          taskKey: task.key,
          taskTitle: task.meta.title,
          panelTitle: title || defaultTitle,
          panelIndex: panelIndex + 1,
        });
        break;
      }
      case 'SET_ANSWER': {
        const { key: fieldKey, value: fieldValue, type: fieldType } = payload;

        if (fieldKey === 'email_address' && authState === 'ANONYMOUS_ACCOUNT') {
          auth.setLoginHint(fieldValue);
          return;
        }

        if (!isFieldEnumerable(fieldType)) {
          return;
        }

        analytics.event('answer', 'set', {
          label: fieldKey,
          value: isNumber(fieldValue) ? fieldValue : undefined,
          applicationFileId,
          taskId: task.id,
          taskKey: task.key,
          taskTitle: task.meta.title,
          answerKey: fieldKey,
          answerValue: fieldValue,
        });
        break;
      }
      case 'PUSH_ANSWER': {
        const { key: fieldKey, label: fieldLabel, value: fieldValue } = payload;

        analytics.event('answer', 'push', {
          label: fieldLabel,
          applicationFileId,
          taskId: task.id,
          taskKey: task.key,
          taskTitle: task.meta.title,
          answerKey: fieldKey,
          answerValue: fieldValue,
        });
        break;
      }
      case 'DISPLAY_CREDIT_SCORE': {
        const { scores = [] }: { scores: ICreditPullScore[] } = payload;

        const validScores = scores.filter(
          (s) => !isNil(s.equifax) || !isNil(s.transunion) || !isNil(s.experian)
        );

        if (!validScores.length) {
          return;
        }

        const scoresResult = validScores.map(({ equifax, transunion, experian }) => ({
          equifax: !isNil(equifax) ? Number(equifax) : undefined,
          transunion: !isNil(transunion) ? Number(transunion) : undefined,
          experian: !isNil(experian) ? Number(experian) : undefined,
        }));

        analytics.event('credit', 'show', {
          label: task.key,
          scores: scoresResult,
          applicationFileId,
          taskId: task.id,
          taskKey: task.key,
          taskTitle: task.meta.title,
        });
        break;
      }
      case 'SELECT_PRICING_OPTION': {
        const { pricingLoan }: { pricingLoan: IPricingLoan } = payload;

        if (!pricingLoan) {
          return;
        }

        const { productName, apr, rate, price, productId } = pricingLoan;

        analytics.event('pricing', 'set', {
          label: task.key,
          productId,
          productName,
          apr,
          rate,
          price,
          applicationFileId,
          taskId: task.id,
          taskKey: task.key,
          taskTitle: task.meta.title,
        });
        break;
      }
      case 'CHOOSE_AGENT': {
        const { agentId }: { agentId: string | undefined } = payload;
        if (applicationFileId && agentId) {
          refetchPrimaryAgent(); // refetch so sidebar chat will appear if enabled
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleEmailVerified = useCallback(() => {
    setEmailVerifiedInSession(true);
  }, []);

  const handleComplete = useCallback(async () => {
    if (task) {
      const blueprintJson = applicationFile?.blueprint?.json || {};

      analytics.event('tasks', 'complete', {
        label: task.key,
        applicationFileId,
        taskId: task.id,
        taskKey: task.key,
        taskTitle: task.meta.title,
      });

      if (taskHasCompleteAction(task, blueprintJson, 'application_submitted')) {
        analytics.event('application', 'submitted', {
          applicationFileId,
        });
      }
    }

    return onComplete();
  }, [task, onComplete, applicationFile?.blueprint?.json, applicationFileId]);

  // Handle analytics
  const previousTaskKey = usePrevious(task?.key);
  useEffect(() => {
    if (!task) return;
    if (task.key === previousTaskKey) {
      return;
    }

    if (!task.isStarted) {
      analytics.event('tasks', 'start', {
        label: task.key,
        applicationFileId,
        taskId: task.id,
        taskKey: task.key,
        taskTitle: task.meta.title,
      });
    }

    analytics.event('tasks', 'view', {
      label: task.key,
      applicationFileId,
      taskId: task.id,
      taskKey: task.key,
      taskTitle: task.meta.title,
    });
  }, [applicationFileId, previousTaskKey, task]);

  // Mark the task as touched (not new)
  useEffect(() => {
    const shouldTouch = !!task && task.isNew && task.state === TaskState.open && !task.isFrozen;

    if (!task || !shouldTouch) {
      return;
    }

    // Mark the task as touched (not new)
    touchTask(task.id).catch((e) => Sentry.captureException(e));
  }, [requiresAuth, task, touchTask]);

  useEffect(() => {
    if (!applicationFileId) {
      return;
    }

    const nextUserId = user?.id ? `${user.id}` : null;

    analytics.identify(nextUserId, {
      'Application File Id': Number(applicationFileId),
    });
  }, [applicationFileId, user?.id]);

  if (!applicationFile) {
    return (
      <TaskContainerWrapper wide={false}>
        <TaskSkeleton />
      </TaskContainerWrapper>
    );
  }

  if (!applicantId || taskLoading) {
    // eslint-disable-next-line eqeqeq
    const taskSkeleton = applicationFile.tasks.find((t) => t.id == taskId);
    const taskSkeletonWide = WIDE_CONTAINER_TASK_TYPES.includes(taskSkeleton!.typeName);
    return (
      <TaskContainerWrapper wide={taskSkeletonWide}>
        <TaskSkeleton type={taskSkeleton?.typeName} />
      </TaskContainerWrapper>
    );
  }

  if (!task || !task.isVisible || !applicant) {
    throw new NotFoundError();
  }

  if (
    (requiresAuth || requiresEmailVerification) &&
    authState !== AuthState.AUTHENTICATED_ACCOUNT
  ) {
    return (
      <TaskContainerWrapper wide={false}>
        <TaskAuthenticate />
      </TaskContainerWrapper>
    );
  }

  if (requiresEmailVerification && !emailVerified) {
    return (
      <TaskContainerWrapper wide={false}>
        <TaskVerifyEmail email={applicant.email!} onFinish={handleEmailVerified} />
      </TaskContainerWrapper>
    );
  }

  if (task.state === TaskState.locked) {
    return (
      <TaskContainerWrapper wide={false}>
        <TaskLocked />
      </TaskContainerWrapper>
    );
  }

  /*
   * TODO - CH Story ID 20906.
   * if the logged in applicant should not complete a co-applicant's task content, show this screen
   * if (<task content is accessible only to co-borrower>) {
   *   contentEl = <TaskPrivate task={task}/>;
   * }
   */

  const useWideContainer = WIDE_CONTAINER_TASK_TYPES.includes(task.typeName);
  const showApplicantName = !task.common
    ? applicationFile.tasks.some((t) => !t.common && `${t.primaryApplicantId}` !== applicantId)
    : false;

  return (
    <TaskContainerWrapper wide={useWideContainer}>
      <TaskContainer
        key={task.id}
        task={task}
        taskBlueprintType="borrower"
        taskApplicant={taskApplicant || undefined}
        applicantId={`${task.primaryApplicantId}`}
        applicationFileId={applicationFile.id}
        onAction={handleAction}
        onComplete={handleComplete}
        onError={onError}
        onPanelChange={onPanelChange}
        frame
        showApplicantName={showApplicantName}
        activeApplicantId={applicantId}
        renderComplete={renderComplete}
        auth={auth}
      />
    </TaskContainerWrapper>
  );
};
