import type { IBaseTask } from '@mortgagehippo/tasks';
import { uniq } from 'lodash-es';

export const getTasksListSettings = (
  tasks: IBaseTask[],
  applicants: { firstName: string | null }[],
  activeApplicantId?: string
) => {
  const showNames = tasks.some((t) => !t.common && `${t.primaryApplicantId}` !== activeApplicantId);

  const showInitials =
    showNames && uniq(applicants.map((v) => v.firstName)).length < applicants.length;

  return { showNames, showInitials };
};
