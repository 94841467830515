import { AuthState, useAuth } from '@mortgagehippo/auth';
import { LandingPage as LandingPageView, useLandingPage } from '@mortgagehippo/tasks';
import { NotFoundError } from '@mortgagehippo/util';
import { trim } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../hooks/use-site';
import { useSiteId } from '../../hooks/use-site-id';
import { useStartAnonymousSession } from '../../hooks/use-start-anonymous-session';
import { Content, Layout } from '../../layouts/landing';
import { history } from '../../services/history';
import { reconcileTargetProperty } from './util';

interface ILandingPageParams {
  slug: string;
}

type ILandingPageProps = RouteComponentProps<ILandingPageParams>;

export const LandingPage = (props: ILandingPageProps) => {
  const { location } = props;
  const path = trim(location.pathname, '/');

  const siteId = useSiteId();
  const [site, siteLoading] = useSite();
  const [{ data: landingPage, loading: landingPageLoading }] = useLandingPage(siteId, path);

  const [, authState, auth] = useAuth();

  const startAnonymousSession = useStartAnonymousSession();

  const loading = siteLoading || landingPageLoading;

  const handleStartApplication = useCallback(
    async (answers?: any) => {
      /*
       * since we don't necessarily want to redirect to /start we need
       * to handle this stuff here
       */
      if (authState !== AuthState.NO_ACCOUNT) {
        history.push(auth.getAfterLoginPath());
        return;
      }

      if (!auth.getAllowAnonymousAccounts()) {
        history.push('/signup');
        return;
      }

      if (answers) {
        // manually start an anonymous session so we can save answers
        await startAnonymousSession((initialAnswers) =>
          reconcileTargetProperty(answers, initialAnswers)
        );

        history.push(auth.getAfterLoginPath());
        return;
      }

      history.push('/start');
    },
    [auth, authState, startAnonymousSession]
  );

  const handleResumeApplication = useCallback(() => {
    // will automatically redirect person to afterLoginPath if they are already logged in
    history.push('/login');
  }, []);

  const variables = useMemo(() => {
    if (!site) {
      return {};
    }

    const { name } = site;
    return {
      site: {
        name,
      },
    };
  }, [site]);

  if (loading) {
    return null;
  }

  if (!site || !landingPage) {
    throw new NotFoundError();
  }

  return (
    <Layout title={landingPage.title || 'Page'}>
      <Content>
        <LandingPageView
          landingPage={landingPage}
          variables={variables}
          onStartApplication={handleStartApplication}
          onResumeApplication={handleResumeApplication}
        />
      </Content>
    </Layout>
  );
};
