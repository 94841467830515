import {
  Form,
  type FormSubmitHandler,
  Input,
  SubmitButton,
  T,
  useCustomizations,
} from '@mortgagehippo/ds';

import { Actions } from '../common';

interface IPasswordTabFormProps {
  onSubmit: FormSubmitHandler;
}

export const PasswordSettingsForm = (props: IPasswordTabFormProps) => {
  const { onSubmit } = props;

  const customizations = useCustomizations();
  const mismatchMsg = customizations.text(
    'pageSettings:changePassword.error.passwordMismatch.message',
    'New Password and Confirm New Password do not match.'
  );

  const passwordLabel = customizations.text(
    'pageSettings:changePassword.fields.currentPassword.label',
    'Current Password'
  );

  const newPasswordLabel = customizations.text(
    'pageSettings:changePassword.fields.newPassword.label',
    'New Password'
  );

  const newPasswordRepeatLabel = customizations.text(
    'pageSettings:changePassword.fields.confirmPassword.label',
    'Confirm New Password'
  );

  const handleValidateConfirm = async (value: any, allValues: any) => {
    const { newPassword } = allValues;

    if (value !== newPassword) {
      return mismatchMsg;
    }

    return undefined;
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input.Box label={passwordLabel} name="currentPassword" type="password" required />
      <Input.Box label={newPasswordLabel} name="newPassword" type="password" required />
      <Input.Box
        label={newPasswordRepeatLabel}
        name="confirmPassword"
        type="password"
        validate={handleValidateConfirm}
        required
      />
      <Actions>
        <SubmitButton importance="primary">
          <T cid="pageSettings:changePassword.submitButton.label">Save</T>
        </SubmitButton>
      </Actions>
    </Form>
  );
};
