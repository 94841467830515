import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import { type MeQuery, type MeQueryVariables } from '../apollo/graphql';
import { auth } from '../services/auth';

export type IBPUser = NonNullable<MeQuery['me']>;

const QMe = graphql(`
  query Me {
    me {
      id
      createdAt
      lastLoginAt
      loginCount
      email
      name
      phoneNumber
      jobTitle
    }
  }
`);

export const useUser = () => {
  const [user] = useAuth();
  const userId = user?.id;

  const [data, loading] = useQuery(QMe, {}, { skip: !userId });

  const result = data?.me || null;

  return [result, loading] as const;
};

export const getUser = async () => {
  const user = auth.getAccessTokenPayload();
  const userId = user?.id;

  if (!userId) {
    return null;
  }

  const result = await client.query<MeQuery, MeQueryVariables>({
    query: QMe,
    variables: {},
  });

  const { data } = result;

  return data.me;
};
