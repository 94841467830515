import { type FunctionComponent } from 'react';
import { Route, type RouteComponentProps } from 'react-router';

import { useApplicationFileMilestones } from '../../hooks/use-application-file-milestones';
import { MilestonesList } from './milestones-list';

interface IMilestonesListRouteParams {
  applicationFileId: string;
}

const MilestonesListRoute: FunctionComponent<RouteComponentProps<IMilestonesListRouteParams>> = (
  props
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  const [milestones, milestonesLoading] = useApplicationFileMilestones(applicationFileId);

  if (milestonesLoading || !milestones.length) {
    return null;
  }

  const activeIndex = milestones.findIndex((milestone) => milestone.active === true);

  return <MilestonesList activeIndex={activeIndex} milestones={milestones} />;
};

export const MilestonesListContainer = () => (
  <Route path="/applications/:applicationFileId" component={MilestonesListRoute} />
);
