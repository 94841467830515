import {
  type IMilestoneListTheme,
  MediaBreakpoint,
  MILESTONE_CIRCLE_SIZE,
  MilestoneList,
  type MilestoneListMilestones,
  spacing,
  useCustomizations,
  useTheme,
} from '@mortgagehippo/ds';
import { memo } from 'react';
import styled from 'styled-components';

const ListWrapper = styled('div')`
  position: absolute;
  direction: rtl; // needed to not exit right edge on expand
  width: 100%;
  height: 100%;

  ${MediaBreakpoint.PHONE} {
    position: relative;
    padding: 0;
  }
`;

const StyledMilestoneList = styled(MilestoneList)`
  direction: ltr;
  top: calc(50% - ${MILESTONE_CIRCLE_SIZE / 2}px - ${spacing(3)});
`;

interface IMilestonesListProps {
  activeIndex: number;
  milestones: MilestoneListMilestones;
}

export const MilestonesList = memo((props: IMilestonesListProps) => {
  const { activeIndex, milestones } = props;
  const customizations = useCustomizations();
  const theme = useTheme();
  const backgroundHor = customizations.color('app:header.background', theme.pageBackground.start);
  const completedColorHor = customizations.color('app:milestones.hor.color.completed', 'white');
  const upcomingColorHor = customizations.color('app:milestones.hor.color.upcoming', 'primary700');
  const backgroundVert = theme.palette.white;
  const completedColorVert = customizations.color(
    'app:milestones.vert.color.completed',
    'primary700'
  );
  const upcomingColorVert = customizations.color(
    'app:milestones.vert.color.upcoming',
    'primary100'
  );
  const milestoneTheme: IMilestoneListTheme = {
    horizontal: {
      completedColor: completedColorHor,
      upcomingColor: upcomingColorHor,
      backgroundColor: backgroundHor,
    },
    vertical: {
      completedColor: completedColorVert,
      upcomingColor: upcomingColorVert,
      backgroundColor: backgroundVert,
    },
  };

  if (!milestones?.length) {
    return null;
  }

  return (
    <ListWrapper>
      <StyledMilestoneList
        milestoneTheme={milestoneTheme}
        milestones={milestones as MilestoneListMilestones}
        activeIndex={activeIndex}
        compact
      />
    </ListWrapper>
  );
});
