import { type IErrorComponentProps } from '@mortgagehippo/util';
import { useEffect, useRef } from 'react';

import { GeneralError } from '$components/general-error';

export const ContentError = (props: IErrorComponentProps) => {
  const { reset } = props;
  const originalUrl = useRef(window.location.href);
  const url = window.location.href;

  /* Reset the error state on url change */
  useEffect(() => {
    if (originalUrl.current !== url) {
      reset();
    }
  }, [reset, url]);

  return <GeneralError {...props} />;
};
