import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';

const MVerifyEmailByLink = graphql(`
  mutation VerifyEmailByLink($code: String!) {
    emailVerification {
      verifyEmailByLink(code: $code) {
        success
        error_code
        error_message
      }
    }
  }
`);

export const useVerifyEmailByLink = () => {
  const fn = useMutation(MVerifyEmailByLink);
  return useCallback(
    async (code: string) => {
      const result = await fn({ code });
      return result.data!.emailVerification.verifyEmailByLink;
    },
    [fn]
  );
};
