import { AuthState, useAuth } from '@mortgagehippo/auth';
import { useEffect, useState } from 'react';
import { Redirect, type RouteComponentProps } from 'react-router';

import { LoadingPage } from '../loading';

interface ITeamPageParams {
  slug: string;
}

type ITeamPageProps = RouteComponentProps<ITeamPageParams>;

export const TeamPage = (props: ITeamPageProps) => {
  const { match } = props;
  const { params } = match;
  const { slug } = params;
  const [redirectTo, setRedirectTo] = useState<string | undefined>();

  const [, authState, auth] = useAuth();

  useEffect(() => {
    (async () => {
      if (authState === AuthState.STARTING_SESSION) {
        return;
      }

      if (authState === AuthState.NO_ACCOUNT) {
        await auth.setApplicationMetadata({ team_slug: slug });
      }

      setRedirectTo('/');
    })();
  }, [auth, authState, slug]);

  if (!redirectTo) {
    return <LoadingPage />;
  }

  return <Redirect to={redirectTo} />;
};
