import { ButtonRouterLink, spacing, T, useResponsive } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { MobilePageNavBar } from '$components/mobile-page-nav-bar';

import { Content, ContentNav, Layout } from '../../layouts/main';
import { TASK_CONTAINER_STANDARD_MAX_WIDTH } from '../../util/task-width';
import { PasswordSettings } from './password-settings';

const BackButton = styled(ButtonRouterLink)`
  margin-left: -${spacing(4)};
`;

const SettingsContainerWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: ${TASK_CONTAINER_STANDARD_MAX_WIDTH}px;
`;

const Container = styled.div`
  margin-bottom: ${spacing(5)};
`;

export const SettingsPage = () => {
  const responsive = useResponsive();
  const isMobile = responsive.PHONE.EXACT_OR_SMALLER;

  return (
    <Layout title="Account Settings" titleCid="pageSettings:title">
      {isMobile ? (
        <ContentNav aria-label="Settings Page Navigation">
          <MobilePageNavBar
            leftAction={{
              to: '/applications',
              icon: 'left-arrow',
              label: <T cid="pageSettings:backButton.label">Back to application</T>,
            }}
          />
        </ContentNav>
      ) : null}
      <Content>
        <SettingsContainerWrapper>
          {!isMobile && (
            <BackButton
              to="/applications"
              importance="tertiary"
              icon="left-arrow"
              iconLocation="left"
              size="sm"
            >
              <T cid="pageSettings:backButton.label">Back to application</T>
            </BackButton>
          )}
          <Container>
            <PasswordSettings />
          </Container>
        </SettingsContainerWrapper>
      </Content>
    </Layout>
  );
};
