import { AuthState, useAuth } from '@mortgagehippo/auth';
import {
  ApplyStyleIf,
  Card,
  Content,
  fontWeight,
  MediaBreakpoint,
  palette,
  spacing,
  T,
  Text,
  Title,
} from '@mortgagehippo/ds';
import { type IBaseTask } from '@mortgagehippo/tasks';
import { useEffect } from 'react';
import styled from 'styled-components';

import { NextTaskNav } from '$components/next-task-nav';

import { type IApplicationFile } from '../../hooks/use-application-file';
import { analytics } from '../../services/analytics';
import { ApplicationStatus } from '../../services/find-next-task';
import { TASK_CONTAINER_STANDARD_MAX_WIDTH } from '../../util/task-width';
import { ApplicationDashboardSkeleton } from './application-dashboard-skeleton';
import { ApplicationStateImage } from './application-state-image';
import { QuickApplyLogo } from './quickapply-logo';
import { SecureDataMessage } from './secure-data-msg';

const DashboardTitle = styled(Title)``;

const StyledSubtitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: ${spacing(4)};
`;

const StyledCard = styled(Card)`
  text-align: center;
  margin-bottom: ${spacing(4)};
`;

const StyledQuickApplyContainer = styled.div`
  margin-bottom: ${spacing(4)};
`;

const StyledTextContainer = styled.div`
  margin-bottom: ${spacing(4)};
  color: ${palette('neutral900')};
  text-align: left;
  font-size: 14px;
`;
const DashboardContent = styled.div`
  max-width: ${TASK_CONTAINER_STANDARD_MAX_WIDTH}px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding: ${spacing(4)} 0 0;

  ${MediaBreakpoint.PHONE} {
    padding: 0;
  }

  ${DashboardTitle},
  h1 {
    // adding h1 to account for custom html content
    font-weight: ${fontWeight('semibold', 'secondary')};
  }
`;

type IDashboardStateConfig = Record<
  string,
  {
    image: string;
    imageCid: string;
    title: string;
    titleCid: string;
    description: string;
    descriptionCid: string;
    linkTitle?: string;
    linkTitleCid?: string;
    showNextTaskLink?: boolean;
    dangerouslyReplaceContent: string;
    dangerouslyReplaceContentAnonymous: string;
  }
>;

const DASHBOARD_STATES: IDashboardStateConfig = {
  [ApplicationStatus.DONE]: {
    image: 'dashboard-done.svg',
    imageCid: 'dashboard:application.completed.image',
    title: "You've Completed All Tasks!",
    titleCid: 'dashboard:application.completed.title',
    description:
      'There are no tasks to complete at the moment. Please be on the watch as new tasks may appear in the future.',
    descriptionCid: 'dashboard:application.completed.description',
    showNextTaskLink: false,
    dangerouslyReplaceContent: 'dashboard:application.completed.dangerouslyReplaceContent.html',
    dangerouslyReplaceContentAnonymous:
      'dashboard:application.completed.anonymous.dangerouslyReplaceContent.html',
  },
  [ApplicationStatus.START]: {
    image: 'dashboard-start.svg',
    imageCid: 'dashboard:application.start.image',
    title: "Let's Start Your Application",
    titleCid: 'dashboard:application.start.title',
    description:
      'Use this hub to fill out and submit your loan application. We will walk you through each step of the process.',
    descriptionCid: 'dashboard:application.start.description',
    linkTitle: 'Start Application',
    linkTitleCid: 'dashboard:application.start.nextButton.label',
    showNextTaskLink: true,
    dangerouslyReplaceContent: 'dashboard:application.start.dangerouslyReplaceContent.html',
    dangerouslyReplaceContentAnonymous:
      'dashboard:application.start.anonymous.dangerouslyReplaceContent.html',
  },
};

interface IApplicationDashboardContentProps {
  applicationFile?: IApplicationFile;
  applicantId?: string;
  nextTask?: IBaseTask;
  appStatus?: ApplicationStatus;
}

export const ApplicationDashboardContent = (props: IApplicationDashboardContentProps) => {
  const { applicationFile, applicantId, nextTask, appStatus } = props;

  const [, authState] = useAuth();

  const applicationFileId = applicationFile?.id;
  useEffect(() => {
    if (applicationFileId) {
      analytics.event('application', 'start_page', {
        applicationFileId,
      });
    }
  }, [applicationFileId]);

  if (!applicantId || !applicationFile || !appStatus) {
    return <ApplicationDashboardSkeleton />;
  }

  const dashboard = DASHBOARD_STATES[appStatus];

  if (!dashboard) {
    return null;
  }

  const dashboardDangerousHtmlCid =
    authState === AuthState.ANONYMOUS_ACCOUNT
      ? dashboard.dangerouslyReplaceContentAnonymous
      : dashboard.dangerouslyReplaceContent;

  const showQuickApply = applicationFile?.voeIntegrationType || applicationFile?.voaIntegrationType;

  return (
    <DashboardContent>
      <ApplicationStateImage cid={dashboard.imageCid} src={dashboard.image} />
      <Content cid={dashboardDangerousHtmlCid}>
        <DashboardTitle align="center" cid={dashboard.titleCid}>
          {dashboard.title}
        </DashboardTitle>
        <ApplyStyleIf wordsMoreThan={40} applyStyle={{ textAlign: 'left' }}>
          <Content cid={dashboard.descriptionCid}>
            <Text align="center" as="p">
              {dashboard.description}
            </Text>
          </Content>
        </ApplyStyleIf>
        {showQuickApply ? (
          <StyledCard>
            <StyledQuickApplyContainer>
              <QuickApplyLogo />
            </StyledQuickApplyContainer>
            <StyledTextContainer>
              <StyledSubtitle>
                <T cid="dashboard:application.quickApply.subtitle">
                  Look out for the QuickApply logo throughout this application to help complete your
                  application faster!
                </T>
              </StyledSubtitle>
              <T cid="dashboard:application.quickApply.message">
                Use QuickApply connected data sources to help complete your application and qualify
                faster. Save time looking for documents and information needed to fill out your
                application and reduce days in application processing with accurate and verified
                information upfront.
              </T>
            </StyledTextContainer>
            <SecureDataMessage />
          </StyledCard>
        ) : null}
        {dashboard.showNextTaskLink && nextTask ? (
          <NextTaskNav
            nextTaskId={nextTask.id}
            linkTitle={
              (dashboard.linkTitle && <T cid={dashboard.linkTitleCid}>{dashboard.linkTitle}</T>) ||
              null
            }
            applicationFileId={applicationFile.id}
          />
        ) : null}
      </Content>
    </DashboardContent>
  );
};
