import { useEffect, useRef } from 'react';
import { type RouteComponentProps } from 'react-router';

import { auth } from '../../services/auth';

export const ConnectPage = (props: RouteComponentProps<{ id?: string }>) => {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const calledLogIn = useRef(false);

  useEffect(() => {
    // Make sure we don't call login twice
    if (calledLogIn.current) {
      return;
    }
    calledLogIn.current = true;

    if (id === 'new') {
      auth.login('/applications/latest', { startApplication: true }, false);
    } else if (id) {
      auth.login(`/applications/${id}`, {}, false);
    } else {
      auth.login('/applications/', {}, false);
    }
  }, [id]);

  return null;
};
