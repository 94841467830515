import { useAuth } from '@mortgagehippo/auth';
import { notifications } from '@mortgagehippo/ds';
import { useCreateApplicationFromApplicant } from '@mortgagehippo/tasks';
import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useState } from 'react';
import { type RouteComponentProps, withRouter } from 'react-router';
import { graphql } from 'src/apollo';

import { client } from '../../apollo/apollo-client';
import { useApplicationFiles } from '../../hooks/use-application-files';
import { useDuplicateApplicationFile } from '../../hooks/use-duplicate-application-file';
import { useTaskId } from '../../hooks/use-task-id';
import { history } from '../../services/history';
import { ProfileMenu } from './profile-menu';

const QNameQuery = graphql(`
  query Name {
    me {
      id
      firstName
      lastName
    }
  }
`);

interface IProfileMenuContainerProps extends RouteComponentProps<any> {
  inverted?: boolean;
  buttonColor?: string;
}

const ProfileMenuContainerWithRouter = (props: IProfileMenuContainerProps) => {
  const { inverted, buttonColor, match } = props;
  const { params } = match;
  const { applicationFileId: activeApplicationFileId } = params;

  const [{ taskId }] = useTaskId();
  const [user] = useAuth();

  const applicantId = user?.id || undefined;

  const [applicantFirstName, setApplicantFirstName] = useState<string | undefined>(undefined);

  const [applicationFiles, loading, refetch] = useApplicationFiles();

  const createApplication = useCreateApplicationFromApplicant();
  const duplicateApplication = useDuplicateApplicationFile();

  const handleStartApplication = useCallback(
    async (importData: boolean) => {
      const isNewApplicant = !activeApplicationFileId && !applicationFiles?.length;
      // if not on application specific page, use the latest one (if exists)
      const applicationId = activeApplicationFileId || applicationFiles?.[0]?.id;
      try {
        let redirectId;

        if (isNewApplicant || !importData) {
          redirectId = await createApplication(
            applicantId,
            {
              assignmentOptions: { agentId: null, copyFromApplicationFileId: applicationId },
              sendInvitation: null,
            },
            true
          );
        } else {
          redirectId = await duplicateApplication(applicationId, undefined, true);
        }

        notifications.success({
          messageCid: 'modalStartApplication:success.message',
          message: 'Successfully created application',
        });

        history.push(`/applications/${redirectId}/tasks`);

        refetch();

        return true;
      } catch (e) {
        notifications.error({
          messageCid: 'modalStartApplication:error.message',
          message: 'There was an unexpected error creating application',
        });
        return false;
      }
    },
    [
      activeApplicationFileId,
      applicantId,
      applicationFiles,
      createApplication,
      duplicateApplication,
      refetch,
    ]
  );

  // Every time we change a task, refresh the name
  useEffect(() => {
    // don't try to do this query if we don't have an user
    if (!applicantId) {
      return;
    }

    client
      .query({ query: QNameQuery, fetchPolicy: 'no-cache' })
      .then((res) => {
        const { data } = res;
        setApplicantFirstName(data?.me?.firstName);
        return undefined;
      })
      .catch((e: any) => {
        Sentry.captureException(e);
      });
  }, [applicantId, taskId]);

  if (!applicantId || loading) {
    return null;
  }

  return (
    <ProfileMenu
      applicationFiles={applicationFiles || []}
      inverted={inverted}
      onStartApplication={handleStartApplication}
      applicantFirstName={applicantFirstName}
      buttonColor={buttonColor}
      activeApplicationFileId={activeApplicationFileId}
    />
  );
};

export const ProfileMenuContainer = withRouter(ProfileMenuContainerWithRouter);
