import 'url-polyfill';

import {
  AdobeAnalyticsPlugin,
  Analytics,
  GoogleAnalyticsPlugin,
  GoogleTagManagerPlugin,
  HotjarPlugin,
  PostHogPlugin,
} from '@mortgagehippo/analytics';
import { AuthEventType } from '@mortgagehippo/auth';
import * as Sentry from '@sentry/browser';
import { isEmpty, isNil } from 'lodash-es';
import qs from 'qs';

import { config } from '../config';
import { getSite, type ISite } from '../hooks/use-site';
import { getUser, type IBPUser } from '../hooks/use-user';
import { auth } from './auth';
import { customizationsService } from './customizations';
import { history } from './history';

const APP_CODE = 'bhub';
let sitePromise: Promise<ISite | null> | null;
let siteData: ISite | null = null;
let userId: string | null = null;
let userData: IBPUser | null = null;

export const analytics = new Analytics(history, {
  startPageViewTracking: false,
});

const getParams = (location: string) => {
  const url = new URL(location);
  const { search } = url;

  const parsedQs = qs.parse(search || '', {
    ignoreQueryPrefix: true,
  });

  return parsedQs;
};

const getSiteData = async () => {
  if (siteData) {
    return siteData;
  }

  sitePromise ||= getSite();

  try {
    siteData = await sitePromise;
    return siteData;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    return null;
  }
};

const getUserData = async () => {
  if (isNil(userId)) {
    return null;
  }

  if (userData && userData.id === userId) {
    return userData;
  }

  try {
    const result = await getUser();

    return result;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    return null;
  }
};

const identify = async (nextUserId: string | null) => {
  userId = nextUserId;

  const [site, user] = await Promise.all([getSiteData(), getUserData()]);

  // has it changed while we went to the server?
  const canceled = userId !== nextUserId;

  if (canceled) {
    return;
  }

  userData = user;

  analytics.identify(nextUserId, {
    App: APP_CODE,
    Domain: window.location.host,
    'Partner Id': site?.partnerId || null,
    Partner: site?.partnerName || null,
    'Site Id': site?.id ? Number(site.id) : null,
    Site: site?.name || null,
    'User Created': userData?.createdAt || null,
    'Last Login': userData?.lastLoginAt || null,
    'Login Count': userData?.loginCount || null,
    'Access Level': null,
    Email: userData?.email || null,
    Name: userData?.name || null,
    Phone: userData?.phoneNumber || null,
    'Job Title': userData?.jobTitle || null,
    Environment: config.ENVIRONMENT,
  });
};

(async () => {
  // grab params as soon as possible in case of redirects
  const params = getParams(window.location.href);

  if (!isEmpty(params)) {
    analytics.event('page', 'params', params);
  }

  // we are doing this to force the params event to happen before the first page view event....:(
  analytics.startPageviewTracking();

  const provider = await customizationsService.get('settings:analytics.provider');
  const accountKey = await customizationsService.get('settings:analytics.accountKey');

  if (provider && accountKey) {
    switch (provider.toLowerCase()) {
      case 'gtag': {
        analytics.addPlugin(new GoogleTagManagerPlugin(accountKey));
        break;
      }
      case 'ga': {
        analytics.addPlugin(new GoogleAnalyticsPlugin(accountKey));
        break;
      }
      case 'adobe': {
        analytics.addPlugin(new AdobeAnalyticsPlugin(accountKey));
        break;
      }
      default: {
        Sentry.captureMessage('Unsupported Analytics Plugin');
        break;
      }
    }
  }

  if (config.BP_GOOGLE_ANALYTICS_KEY) {
    analytics.addPlugin(new GoogleAnalyticsPlugin(config.BP_GOOGLE_ANALYTICS_KEY, 'mh'));
  }

  if (config.HOTJAR_ID) {
    analytics.addPlugin(new HotjarPlugin(config.HOTJAR_ID, APP_CODE));
  }

  if (config.POSTHOG_ID) {
    analytics.addPlugin(new PostHogPlugin(config.POSTHOG_ID, APP_CODE));
  }
})();

// Track Authorization Events
auth.subscribe(async (event, _state, user) => {
  if (
    !event ||
    event === AuthEventType.RESTART_SESSION_FAILED ||
    event === AuthEventType.FORCED_LOGOUT
  ) {
    identify(null);
  }

  if (event === AuthEventType.AUTHENTICATED) {
    identify(user?.id ? `${user.id}` : null);
  }

  if (event === AuthEventType.CREATE_ANONYMOUS_ACCOUNT_SUCCESS) {
    analytics.event('application', 'start');
  }

  if (event === AuthEventType.LOGIN_SUCCESS) {
    analytics.event('account', 'login');
  }

  if (event === AuthEventType.SIGNUP_SUCCESS) {
    analytics.event('account', 'signup');
  }
});
