import { spacing, Spinner, Title } from '@mortgagehippo/ds';
import { NotFoundError } from '@mortgagehippo/util';
import { type ComponentType, lazy, type ReactElement, Suspense, useEffect } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/legal';

const PageBody = styled.div`
  margin-top: ${spacing(4)};
`;

const TermsPage: ComponentType = lazy(() =>
  import('./terms-page').then((m) => ({ default: m.TermsPage }))
);

const PrivacyPage: ComponentType = lazy(() =>
  import('./privacy-page').then((m) => ({ default: m.PrivacyPage }))
);

const EConsentPage: ComponentType = lazy(() =>
  import('./econsent-page').then((m) => ({ default: m.EConsentPage }))
);

interface ILegalPageParams {
  slug: string;
}

type ILegalPageProps = RouteComponentProps<ILegalPageParams>;

export const LegalPage = (props: ILegalPageProps) => {
  const { match } = props;
  const { params } = match;
  const { slug } = params;

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [slug]);

  let pageEl: ReactElement | null = null;
  let title: string;

  switch (slug) {
    case 'terms': {
      pageEl = <TermsPage />;
      title = 'Terms of Use';
      break;
    }
    case 'privacy': {
      pageEl = <PrivacyPage />;
      title = 'Privacy Policy';
      break;
    }
    case 'econsent': {
      pageEl = <EConsentPage />;
      title = 'E-Sign Disclosure and Consent to use Electronic Records and Signatures';
      break;
    }
    default: {
      throw new NotFoundError();
    }
  }

  const loadingSpinner = (
    <div style={{ textAlign: 'center' }}>
      <Spinner size="xxl" type="spinner1" />
    </div>
  );

  return (
    <Layout title={title}>
      <Content>
        <Suspense fallback={loadingSpinner}>
          <Title align="center">{title}</Title>
          <PageBody className="content-renderer">{pageEl}</PageBody>
        </Suspense>
      </Content>
    </Layout>
  );
};
