import {
  borderRadius,
  type IImageProps,
  Image,
  spacing,
  useCustomizations,
} from '@mortgagehippo/ds';
import styled, { css } from 'styled-components';

export interface IStyledHSLImage extends IImageProps {
  h?: number;
  s?: number;
  l?: number;
  c?: number;
}

const HSLImage = ({ h: _h, s: _s, l: _l, ...rest }: IStyledHSLImage) => <Image {...rest} />;

const StyledImage = styled(HSLImage)<IStyledHSLImage>`
  max-width: 100%;
  border-radius: ${borderRadius(2)};
  margin-bottom: ${spacing(6)};

  ${(p) => {
    const filters: string[] = [];
    if (p.h !== undefined) {
      filters.push(`hue-rotate(${p.h}deg)`);
    }

    if (p.s !== undefined) {
      filters.push(`saturate(${p.s}%)`);
    }

    if (p.l !== undefined) {
      filters.push(`brightness(${p.l}%)`);
    }

    if (p.c !== undefined) {
      filters.push(`contrast(${p.c}%)`);
    }

    if (filters.length) {
      return css`
        filter: ${filters.join(' ')};
      `;
    }

    return '';
  }}
`;

interface IApplicationStateImage {
  src: string;
  cid: string;
}

export const ApplicationStateImage = (props: IApplicationStateImage) => {
  const { src, cid } = props;

  const customizations = useCustomizations();
  const stateStyle = customizations.text('app:dashboard.stateStyle.name', 'isometric');

  let stateStyleHue = customizations.number('app:dashboard.stateStyle.hue');
  // 213 is the hue of the default images
  stateStyleHue = (stateStyleHue || 213) - 213;

  const stateStyleSaturation = customizations.number('app:dashboard.stateStyle.saturation');
  const stateStyleLuminosity = customizations.number('app:dashboard.stateStyle.luminosity');
  const stateStyleContrast = customizations.number('app:dashboard.stateStyle.contrast');

  return (
    <StyledImage
      role="presentation"
      cid={cid}
      src={`/application-state-styles/${stateStyle}/${src}`}
      h={stateStyleHue}
      s={stateStyleSaturation}
      l={stateStyleLuminosity}
      c={stateStyleContrast}
    />
  );
};
