import {
  Alert,
  ApplyStyleIf,
  ButtonLink,
  Code,
  Content as CustomizableContent,
  fontWeight,
  MediaBreakpoint,
  spacing,
  Spinner,
  T,
  Text,
  Title,
} from '@mortgagehippo/ds';
import { unreachable } from '@mortgagehippo/util';
import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import sysend from 'sysend';

import { VerificationEmailErrorCode } from '../../apollo/graphql';
import { useSendVerificationEmail } from '../../hooks/use-send-verification-email';
import { useVerifyEmailByCode } from '../../hooks/use-verify-email-by-code';
import { ApplicationStateImage } from './application-state-image';
import { TaskSuccess } from './task-success';

const TaskVerifyEmailTitle = styled(Title)``;

const TaskVerifyEmailContent = styled.div`
  padding: ${spacing(4)} 0 0;
  text-align: center;

  ${MediaBreakpoint.PHONE} {
    padding: 0;
  }

  ${TaskVerifyEmailTitle},
  h1 {
    // adding h1 to account for custom html content
    font-weight: ${fontWeight('semibold', 'secondary')};
  }
`;

const StyledAlert = styled(Alert)`
  margin: ${spacing(3)} 0 ${spacing(5)};
`;

const SpinnerContainer = styled.div`
  min-height: 32px;
  margin: ${spacing(3)} 0;
`;

const applyIfCSS = css`
  && * {
    text-align: left;
  }
`;

const CODE_LENGTH = 4;
const CODE_ALPHABET = 'alphanum';

interface ITaskVerifyEmailProps {
  email: string;
  onFinish: () => void;
}

export const TaskVerifyEmail = (props: ITaskVerifyEmailProps) => {
  const { email, onFinish } = props;
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCount, setErrorCount] = useState('');
  const [verified, setVerified] = useState(false);
  const sendVerificationEmail = useSendVerificationEmail();
  const verifyEmailByCode = useVerifyEmailByCode();

  const setError = useCallback((e: string) => {
    setErrorMessage(e);
    setErrorCount((c) => c + 1);
  }, []);

  const handleCodeChange = async (nextCode: string) => {
    setCode(nextCode);

    // Don't send code until it is complete
    if (nextCode.length !== CODE_LENGTH) {
      return;
    }

    // If we have filled the code, start verification
    setLoading(true);
    try {
      const result = await verifyEmailByCode(email, nextCode);

      switch (result.error_code) {
        case VerificationEmailErrorCode.CODE_EXPIRED: {
          setError('Code Expired');
          break;
        }
        case VerificationEmailErrorCode.INVALID_CODE: {
          setError('Invalid Code');
          break;
        }
        case VerificationEmailErrorCode.UNEXPECTED_ERROR: {
          setError('Unexpected Error');
          break;
        }
        case null: {
          if (result.success) {
            setError('');
            setVerified(true);
          }
          break;
        }
        default: {
          unreachable.warn(result.error_code);
          setError('Unexpected Error');
          break;
        }
      }
    } catch (e) {
      setError('There was an unexpected error');
      setErrorCount((c) => c + 1);
      Sentry.captureException(e);
    } finally {
      setLoading(false);
      setCode('');
    }
  };

  const handleResendVerificationEmail = useCallback(
    async (force = true) => {
      try {
        const result = await sendVerificationEmail('Applicant', email, force);
        if (!result.success) {
          setError('There was an unexpected error contacting the server');
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        setError('There was an unexpected error contacting the server');
      }
      setLoading(false);
    },
    [email, sendVerificationEmail, setError]
  );

  // Handle external verification
  useEffect(() => {
    const fn = () => {
      setError('');
      setVerified(true);
    };
    sysend.on('email_verified', fn);
    return () => {
      sysend.off('email_verified', fn);
    };
  }, [setError]);

  useEffect(() => {
    handleResendVerificationEmail(false);
  }, [handleResendVerificationEmail]);

  return (
    <TaskVerifyEmailContent>
      <ApplicationStateImage cid="dashboard:task.verifyEmail.image" src="task-verify-email.svg" />

      <TaskVerifyEmailTitle align="center" cid="dashboard:task.verifyEmail.title">
        Please verify your email to continue
      </TaskVerifyEmailTitle>
      <ApplyStyleIf wordsMoreThan={40} applyCSS={applyIfCSS}>
        <CustomizableContent cid="dashboard:task.verifyEmail.description" context={{ email }}>
          <p>
            We sent a code to <strong>{email}</strong>. Please check your inbox and enter the code
            below.
          </p>
        </CustomizableContent>
      </ApplyStyleIf>

      {verified ? (
        <TaskSuccess onFinish={onFinish} startDelayMs={0} finishDelayMs={1000}>
          <T cid="dashboard:task.verifyEmail.completed.message">Email Verified</T>
        </TaskSuccess>
      ) : (
        <>
          {errorMessage ? (
            <StyledAlert key={`${errorCount}`} animation="shake">
              {errorMessage}
            </StyledAlert>
          ) : null}
          <Code.Input
            name="code"
            length={CODE_LENGTH}
            alphabet={CODE_ALPHABET}
            value={code}
            error={!!errorMessage}
            disabled={loading}
            onChange={handleCodeChange}
            aria-label="Enter code"
            centered
          />
          {loading ? (
            <SpinnerContainer>
              <Spinner size="xl" />
            </SpinnerContainer>
          ) : null}

          <Text as="p" size="sm" align="center">
            <T cid="dashboard:task.verifyEmail.resend.description">Don&apos;t have a code?</T>{' '}
            <ButtonLink onClick={() => handleResendVerificationEmail(true)}>
              <T cid="dashboard:task.verifyEmail.resend.button.label">Resend verification email</T>
            </ButtonLink>
          </Text>
        </>
      )}
    </TaskVerifyEmailContent>
  );
};
