import { Alert, ButtonLink, Spinner, Success, T } from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useState } from 'react';
import { type RouteComponentProps } from 'react-router';
import sysend from 'sysend';

import { VerificationEmailErrorCode } from '../../apollo/graphql';
import { useSendVerificationEmail } from '../../hooks/use-send-verification-email';
import { useVerifyEmailByLink } from '../../hooks/use-verify-email-by-link';
import { Content as LayoutContent, Layout } from '../../layouts/login';

interface VerifyEmailPageParams {
  applicantId: string;
  code: string;
}

export const VerifyEmailPage = (props: RouteComponentProps<VerifyEmailPageParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicantId, code } = params;
  const [status, setStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [error, setError] = useState('');
  const verifyEmailByLink = useVerifyEmailByLink();
  const sendVerificationEmail = useSendVerificationEmail();

  const handleResendVerificationEmail = useCallback(async () => {
    try {
      const result = await sendVerificationEmail('Applicant', applicantId, true);
      if (!result.success) {
        setError('There was an unexpected error contacting the server');
      }
    } catch (e) {
      Sentry.captureException(e);
      setError('There was an unexpected error contacting the server');
    }
  }, [applicantId, sendVerificationEmail, setError]);

  useEffect(() => {
    (async () => {
      try {
        const result = await verifyEmailByLink(code);
        switch (result.error_code) {
          case VerificationEmailErrorCode.INVALID_CODE:
          case VerificationEmailErrorCode.CODE_EXPIRED: {
            setStatus('error');
            setError('Your code is invalid or is expired');
            break;
          }
          case VerificationEmailErrorCode.UNEXPECTED_ERROR: {
            setStatus('error');
            setError('There was an unexpected error contacting the server');
            break;
          }
          case null: {
            if (!result.success) {
              setStatus('error');
              setError('There was an unexpected error validating your email');
            } else {
              setError('');
              setStatus('success');
              sysend.broadcast('email_verified', { type: 'EMAIL_VERIFIED' });
            }
            break;
          }
          default: {
            break;
          }
        }
      } catch (e) {
        setStatus('error');
        setError('There was an unexpected error validating your email');
        Sentry.captureException(e);
      }
    })();
  }, [code, verifyEmailByLink]);

  return (
    <Layout title="Email Verification">
      <LayoutContent>
        {status === 'verifying' && <Spinner size="xxl" />}

        {status === 'success' && (
          <Success
            message={<T cid="dashboard:task.verifyEmail.completed.message">Email Verified</T>}
          />
        )}

        {status === 'error' && (
          <>
            <Alert animation="shake">{error}</Alert>
            <ButtonLink onClick={handleResendVerificationEmail}>
              <T cid="dashboard:task.verifyEmail.resend.button.label">Resend verification email</T>
            </ButtonLink>
          </>
        )}
      </LayoutContent>
    </Layout>
  );
};
