import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';
import { TaskEvent } from '../apollo/graphql';

export const QSendTaskEvent = graphql(`
  mutation SendTaskEvent($taskId: ID!, $event: TaskEvent!) {
    sendTaskEvent(taskId: $taskId, event: $event)
  }
`);

export const useCompleteTask = () => {
  const fn = useMutation(QSendTaskEvent, undefined);
  return useCallback((taskId: string) => fn({ taskId, event: TaskEvent.complete }), [fn]);
};
