/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createLayout,
  createSlot,
  type ITemplateProps,
  MediaBreakpoint,
  palette,
  shadow,
  spacing,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { ErrorBoundary } from '@mortgagehippo/util';
import { uniqueId } from 'lodash-es';
import { type ComponentType, forwardRef, useMemo } from 'react';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle, css } from 'styled-components';

import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';
import { useSite } from '../../hooks/use-site';
import { HEADER_SIZE, HEADER_SIZE_PHONE } from '../constants';
import { ContentError } from '../main/content-error';
import { DefaultHeader } from './default-header';

export const LayoutGlobalStyles = createGlobalStyle<{ bgrImageSrc?: string }>`
  body, html {
    ${(p) =>
      p.bgrImageSrc &&
      css`
        background-image: url(${p.bgrImageSrc});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
      `}
  }
`;

export const Container = styled('div')`
  min-height: 100vh;
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;

/*
 * we need the header bgr to be a separate element to be positioned below
 * Box and below HeaderComponent. This way HeaderComponent can be above Box.
 * This combination will allow the Box's Content shadow to be above the header background
 * while the header content is above the Box to allow for Header dropdown and milestone content to
 * overflow above the content and Box as necessary.
 */
export const HeaderBackground = styled('div')<{
  bgColor: string;
  headerSize: number;
  headerSizeMobile: number;
}>`
  height: ${(p) => p.headerSize}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${(p) => p.bgColor};
  z-index: 0;

  ${MediaBreakpoint.PHONE} {
    /* the header background element will be placed inside the header */
    height: 100%;
  }
`;

export const HeaderComponent = styled('header')<{
  headerSize: number;
  headerSizeMobile: number;
  headerBorderColor: string;
}>`
  height: ${(p) => p.headerSize}px;
  position: relative;
  z-index: 20;
  box-shadow: ${shadow(1)};

  border-bottom: ${(p) =>
    p.headerBorderColor && p.headerBorderColor.toLowerCase() !== 'transparent'
      ? `1px solid ${p.headerBorderColor}`
      : 'none'};

  ${MediaBreakpoint.PHONE} {
    height: 36px;
    max-height: 36px;
    flex: 0 0 auto;
    padding: ${spacing(1)} 0;
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export const BodyComponent = styled('div')<{ headerSize: number }>`
  position: relative;
  height: calc(100vh - ${(p) => p.headerSize}px);

  ${MediaBreakpoint.PHONE} {
    height: auto;
    min-height: 100vh;
  }
`;

export const StyledContentComponent = styled('main')`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;

  background: ${palette('neutral50')};
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${MediaBreakpoint.PHONE} {
    overflow: hidden;
    height: auto;
    min-height: 100vh;
  }
`;

export const ContentComponent: ComponentType<any> = forwardRef<any>((props: any, ref) => (
  <ErrorBoundary errorComponent={ContentError}>
    <StyledContentComponent tabIndex={-1} {...props} ref={ref} />
  </ErrorBoundary>
));

const mainContentId = uniqueId('main_content_');

interface IDisclosuresTemplateProps extends ITemplateProps {
  title?: string;
  titleCid?: string;
  applicationFileId: string;
  taskId: string;
}

const Template = (props: IDisclosuresTemplateProps) => {
  const { title: rawTitle, titleCid, applicationFileId, taskId } = props;

  const customizations = useCustomizations();
  const [site] = useSite();
  const title = customizations.text(titleCid || '', rawTitle || 'Sign Disclosures');
  const pageTitle = (site && `${title} | ${site.name} `) || title;
  const faviconSrc = useFavicon();
  const headerSize = customizations.number('app:header.height', HEADER_SIZE);
  const headerSizeMobile = customizations.number('app:header.heightMobile', HEADER_SIZE_PHONE);
  const headerBgColor = customizations.color('app:header.background', 'transparent');
  const headerBorderColor = customizations.color('app:header.border.color', 'transparent');
  const appBgrSrc = customizations.image('app:background.image');
  const responsive = useResponsive();

  const HeaderBackgroundEl = useMemo(
    () => (
      <HeaderBackground
        bgColor={headerBgColor!}
        headerSize={headerSize!}
        headerSizeMobile={headerSizeMobile!}
      />
    ),
    [headerBgColor, headerSize, headerSizeMobile]
  );

  return (
    <>
      <LayoutGlobalStyles bgrImageSrc={appBgrSrc || undefined} />
      <GlobalScripts />
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <Container id="container">
        {!responsive.PHONE.EXACT_OR_SMALLER && HeaderBackgroundEl}
        <Header.Slot
          headerSize={headerSize}
          headerSizeMobile={headerSizeMobile}
          headerBorderColor={headerBorderColor}
        >
          {responsive.PHONE.EXACT_OR_SMALLER ? HeaderBackgroundEl : null}
          <DefaultHeader applicationFileId={applicationFileId} taskId={taskId} />
        </Header.Slot>
        <BodyComponent headerSize={headerSize!} id={mainContentId}>
          <Content.Slot />
        </BodyComponent>
      </Container>
    </>
  );
};

export const Layout = createLayout(Template);
export const Header = createSlot(Layout, HeaderComponent);
export const Content = createSlot(Layout, ContentComponent);
