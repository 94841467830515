import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';
import { useSiteId } from './use-site-id';

const MVerifyEmailByCode = graphql(`
  mutation VerifyEmailByCode($siteId: ID, $email: String!, $code: String!) {
    emailVerification {
      verifyEmailByCode(siteId: $siteId, email: $email, code: $code) {
        success
        error_code
        error_message
      }
    }
  }
`);

export const useVerifyEmailByCode = () => {
  const siteId = useSiteId();

  const fn = useMutation(MVerifyEmailByCode);

  return useCallback(
    async (email: string, code: string) => {
      const result = await fn({ siteId, email, code });

      return result.data!.emailVerification.verifyEmailByCode;
    },
    [fn, siteId]
  );
};
