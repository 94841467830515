import { useMutation, useQuery } from '@mortgagehippo/apollo-hooks';
import { AuthState, createUrl, useAuth } from '@mortgagehippo/auth';
import {
  Checkbox,
  Content,
  fontSize,
  Form,
  lineHeight,
  Modal,
  palette,
  spacing,
  Spinner,
  SubmitButton,
  T,
  Text,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import { useJornaya } from '@mortgagehippo/tasks';
import { useSessionStorage } from '@mortgagehippo/util';
import { memoize } from 'lodash-es';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { graphql } from '../../apollo';
import { Content as LayoutContent, Layout } from '../../layouts/login';
import { auth } from '../../services/auth';
import { LoadingPage } from '../loading';

/*
 * creating the full url to be able to compare ANCHOR.href from
 * links coming from the customization content editor which will contain the full path + protocol
 */
const legalPaths = {
  TERMS: createUrl('/legal/terms'),
  PRIVACY: createUrl('/legal/privacy'),
  ECONSENT: createUrl('/legal/econsent'),
};

const IFrame = styled.iframe`
  width: 100%;
  height: 65vh;
  margin-top: ${spacing(3)};
  margin-bottom: -${spacing(2)};
  border: 1px solid ${palette('neutral200')};
  border-left: none;
  border-right: none;
  background: ${palette('neutral50')};
`;

const Description = styled.div`
  text-align: left;
`;

const Row = styled.div`
  text-align: left;
`;

const TermContent = styled.span`
  display: inline-block;

  p {
    display: inline-block;
    margin: 0;

    & + p {
      margin-top: ${spacing(2)};
    }
  }
`;

const TermContentBottom = styled.span`
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
  margin-top: ${spacing(2)};
  display: inline-block;

  p {
    display: inline-block;
    margin: 0;

    & + p {
      margin-top: ${spacing(2)};
    }
  }
`;

const DescriptionContent = styled.span`
  display: inline-block;

  p {
    margin: 0;

    & + p {
      margin-top: ${spacing(2)};
    }
  }
`;

export interface ITermsPageProps {
  agreeToTerms: () => Promise<unknown>;
}

const QAgreeToTerms = graphql(`
  query QAgreeToTerms {
    me {
      id
      agreedToTermsAt
    }
  }
`);

const MAgreeToTerms = graphql(`
  mutation AgreeToTermsMutation {
    agreeToApplicantTerms
  }
`);

export const TermsPage = (props: ITermsPageProps) => {
  const { agreeToTerms } = props;
  const customizations = useCustomizations();
  const termsNamespace = customizations.namespace('pageTerms');

  // If jornaya is configured via globalscripts this will set the information
  useJornaya(auth);

  const terms: Array<{
    label: string;
    descriptionTop: string | undefined;
    descriptionBottom: string | undefined;
    name: string;
  }> = [];

  // figure out a way to determine if the customization service failed and not allow people to proceed to agree to incomplete terms
  if (termsNamespace) {
    /*
     * this is set as HTML in a string so it can be passed down to the checkbox as the value
     * so that we can store it in the DB in the future for each submitted agreement
     */
    const defaultSoftwareTermsLabel = `I consent to the 
        <a href='${legalPaths.ECONSENT}' rel='noopener noreferrer' target='_blank'>use of electronic signatures</a>
        and I also agree to the
        <a href='${legalPaths.TERMS}' rel='noopener noreferrer' target='_blank'>Terms of Use</a>
        and
        <a href='${legalPaths.PRIVACY}' rel='noopener noreferrer' target='_blank'>Privacy Policy</a>.        
      `;

    // software terms
    terms.push({
      label: termsNamespace['checkbox0Software.label'] || defaultSoftwareTermsLabel,
      descriptionTop: termsNamespace['checkbox0Software.descriptionTop'],
      descriptionBottom: termsNamespace['checkbox0Software.descriptionBottom'],
      name: termsNamespace['checkbox0Software.inputHtmlId'] || 'software_terms',
    });

    // custom terms
    for (let i = 1; i <= 4; i += 1) {
      if (termsNamespace[`checkbox${i}.label`]) {
        terms.push({
          label: termsNamespace[`checkbox${i}.label`]!,
          descriptionTop: termsNamespace[`checkbox${i}.descriptionTop`],
          descriptionBottom: termsNamespace[`checkbox${i}.descriptionBottom`],
          name: termsNamespace[`checkbox${i}.inputHtmlId`] || `terms${i}`,
        });
      }
    }
  }

  const handleSubmit = async () => {
    await agreeToTerms();
  };

  const handleValidateTerm = async (value: any) => (!value ? 'required' : undefined);

  const [isModalOpen, openModal, closeModal] = useModal(false);
  const [termsSrc, setTermsSrc] = useState();

  const handleDescriptionTopChildrenClick = useCallback(
    (e: any) => {
      // prevent checking the box when clicking on a potential link inside the checkbox label
      if (e?.target?.tagName && e.target.tagName === 'A') {
        e.stopPropagation();
        if ([legalPaths.TERMS, legalPaths.PRIVACY, legalPaths.ECONSENT].includes(e.target.href)) {
          e.preventDefault();
          setTermsSrc(e.target.href);
          openModal();
        }
      }
    },
    [openModal, setTermsSrc]
  );

  /*
   * TODO:
   * also the error should appear if there is an error loading the customizations to avoid
   * agreeing to fewer things.
   */

  return (
    <Layout title="Terms" titleCid="pageTerms:title">
      <LayoutContent>
        {!customizations.ready() && (
          <Text as="p" align="center">
            <Spinner size="lg" />
          </Text>
        )}
        {customizations.ready() && (
          <Form onSubmit={handleSubmit}>
            <Description>
              <Content cid="pageTerms:description">
                <p>To continue you must agree to the following terms and conditions.</p>
              </Content>
            </Description>
            {terms.map((term) => (
              <Row key={term.name} onClick={handleDescriptionTopChildrenClick}>
                <Checkbox.Box
                  label={
                    (term.descriptionTop && (
                      <DescriptionContent
                        dangerouslySetInnerHTML={{
                          __html: term.descriptionTop,
                        }}
                      />
                    )) ||
                    undefined
                  }
                  labelInvisible={!term.descriptionTop}
                  name={term.name}
                  validate={handleValidateTerm}
                  required
                  hideRequiredLabel
                  propagateLabelLinkClickEvent
                  showSuccess
                >
                  <TermContent dangerouslySetInnerHTML={{ __html: term.label }} />
                  {term.descriptionBottom ? (
                    <TermContentBottom
                      dangerouslySetInnerHTML={{
                        __html: term.descriptionBottom,
                      }}
                    />
                  ) : null}
                </Checkbox.Box>
              </Row>
            ))}

            <SubmitButton importance="primary" disableOnError block>
              <T cid="pageTerms:button.label">Agree</T>
            </SubmitButton>
          </Form>
        )}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          cancelButtonLabel={<T cid="pageTerms:modal.closeButton.label">Close</T>}
          hideOkButton
          compact
          width={700}
        >
          <IFrame title="Legal" src={termsSrc} />
        </Modal>
      </LayoutContent>
    </Layout>
  );
};

export const withTerms = memoize((C: any) => (props: any) => {
  const [user, authState] = useAuth();
  const [remoteAgreedToTermsData, remoteAgreedToTermsLoading] = useQuery(QAgreeToTerms);
  const customizations = useCustomizations();

  const termsDisabled = customizations.bool('app:pageTerms.disabled', false);

  const setAgreeToTerms = useMutation(MAgreeToTerms);
  const [localAgreedToTerms, setLocalAgreedToTerms] = useSessionStorage(
    `agreed_to_terms:${user?.id}`
  );

  if (remoteAgreedToTermsLoading || customizations.loading()) {
    return <LoadingPage />;
  }

  const remoteAgreedToTerms = !!remoteAgreedToTermsData?.me?.agreedToTermsAt;

  if (
    !user ||
    authState !== AuthState.AUTHENTICATED_ACCOUNT ||
    remoteAgreedToTerms ||
    localAgreedToTerms ||
    termsDisabled
  ) {
    return <C {...props} />;
  }

  const handleAgreeToTerms = async () => {
    await setAgreeToTerms();
    setLocalAgreedToTerms('1');
  };

  return <TermsPage agreeToTerms={handleAgreeToTerms} />;
});
