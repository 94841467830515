import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';

export const MTouchTask = graphql(`
  mutation TouchTask($taskId: ID!) {
    touchTask(taskId: $taskId) {
      ...ITaskFragment
    }
  }
`);

export const useTouchTask = () => {
  const touchTask = useMutation(MTouchTask, undefined);

  return useCallback(
    async (taskId: string) => {
      await touchTask({ taskId });
    },
    [touchTask]
  );
};
