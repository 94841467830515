import {
  borderRadius,
  fontSize,
  MediaBreakpoint,
  NewBadge,
  palette,
  shadow,
  spacing,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  z-index: 200;
  top: -${spacing(3)};
  background: white;
  border-radius: ${borderRadius(4)};
  box-shadow: ${shadow(4)};
  padding: ${spacing(1)};
  display: flex;
  align-items: center;
  transform: translateX(25%);
  right: 0;

  ${MediaBreakpoint.PHONE} {
    left: -${spacing(3)};
    right: auto;
    transform: none;
  }
`;

const Label = styled.span`
  flex: 1 1 auto;
  color: ${palette('danger500')};
  font-size: ${fontSize('xs')};
  line-height: 1.2em;
  white-space: nowrap;
  display: block;
`;

const StyledNewBadge = styled(NewBadge)`
  > * {
    display: block;
    flex: 0 0 auto;
    margin-right: ${spacing(1)};
  }
`;

export interface SidebarNewMessageLabelProps {
  count: number;
}

// TODO: CHAT - Label Customization
export const SidebarNewMessageLabel = (props: SidebarNewMessageLabelProps) => {
  const { count } = props;
  if (!count) return null;
  return (
    <Container>
      <StyledNewBadge ariaLabel="New Messages" count={count} bounce />
      <Label>{`new message${count !== 1 ? 's' : ''}`}</Label>
    </Container>
  );
};
