import { Skeleton } from '@mortgagehippo/ds';

export const ApplicationDashboardSkeleton = () => (
  <div>
    <Skeleton active paragraph={false} />

    <br />
    <Skeleton active paragraph />

    <br />
    <Skeleton active paragraph />

    <br />
    <Skeleton active paragraph />
  </div>
);
