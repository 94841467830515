import {
  EmailValidationRequiredError,
  ForbiddenError,
  InvalidTimeError,
} from '@mortgagehippo/auth';
import { type IErrorComponentProps } from '@mortgagehippo/util';

import { EmailValidationRequiredErrorPage } from './email-validation-required-error-page';
import { ForbiddenErrorPage } from './forbidden-error-page';
import { InvalidTimeErrorPage } from './invalid-time-error-page';
import { UnknownAuthErrorPage } from './unknown-auth-error-page';

export const AuthErrorPage = (props: IErrorComponentProps<any>) => {
  const { error, ...rest } = props;

  if (error instanceof InvalidTimeError) {
    return <InvalidTimeErrorPage {...rest} error={error} />;
  }

  if (error instanceof ForbiddenError) {
    return <ForbiddenErrorPage {...rest} error={error} />;
  }

  if (error instanceof EmailValidationRequiredError) {
    return <EmailValidationRequiredErrorPage {...rest} error={error} />;
  }

  return <UnknownAuthErrorPage {...props} error={error} />;
};
