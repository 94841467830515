import { useAuth } from '@mortgagehippo/auth';
import {
  ApplyStyleIf,
  borderRadius,
  ButtonLink,
  ButtonRouterLink,
  Content,
  Image,
  spacing,
  T,
  Text,
} from '@mortgagehippo/ds';
import { useEffect } from 'react';
import { Redirect, type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { useContinueApplication } from '../../hooks/use-continue-application';
import { Content as LayoutContent, Layout } from '../../layouts/login';

const BLANK_IMG_SRC = '/blank.svg';

const SignupPanel = styled.div`
  margin-bottom: ${spacing(4)};
  text-align: center;
`;

const StyledImage = styled(Image)`
  max-width: 100%;
  border-radius: ${borderRadius(2)};
  margin-bottom: ${spacing(4)};

  &[src='${BLANK_IMG_SRC}'] {
    display: none;
  }
`;

const LoginPanel = styled.div``;

interface IStartPageParams {
  applicationFileId?: string;
}

export const StartPage = (props: RouteComponentProps<IStartPageParams>) => {
  const {
    location: { pathname },
    match: {
      params: { applicationFileId },
    },
  } = props;
  const [applicant] = useAuth();

  const [activeApplication, setActiveApplication] = useContinueApplication();

  useEffect(() => {
    if (!applicationFileId) {
      return;
    }

    setActiveApplication(applicationFileId);
  }, [applicationFileId, setActiveApplication]);

  // if the user is logged in go to the application
  if (applicant) {
    const applicationRedirectId = applicationFileId || activeApplication;
    return (
      <Redirect
        to={`/applications${applicationRedirectId ? `/${applicationRedirectId}/tasks` : ''}`}
      />
    );
  }

  const continueApplication =
    !!applicationFileId || !!activeApplication || pathname.includes('/continue');

  if (continueApplication) {
    return (
      <Layout title="Continue Application" titleCid="pageStart:continueMode.title">
        <LayoutContent>
          <Content
            cid="pageStart:continueMode.dangerouslyReplacePageContent.html"
            className="mh-start-container"
          >
            <SignupPanel>
              <StyledImage
                cid="pageStart:image"
                src={BLANK_IMG_SRC}
                alt="Start page intro"
                className="mh-start-image"
              />
              <ApplyStyleIf wordsMoreThan={40} applyStyle={{ textAlign: 'left' }}>
                <Content cid="pageStart:continueMode.description" className="mh-start-content">
                  <Text align="center" as="p">
                    Sign in to continue your application.
                  </Text>
                </Content>
              </ApplyStyleIf>
              <ButtonRouterLink
                to="/login"
                importance="primary"
                size="normal"
                icon="right-arrow"
                iconLocation="right"
                className="mh-start-new-button"
              >
                <T cid="pageStart:continueMode.signinButton.label">Sign in</T>
              </ButtonRouterLink>
            </SignupPanel>

            <LoginPanel className="mh-start-signin">
              <Text as="p" align="center">
                <T cid="pageStart:continueMode.start.description">
                  Haven&apos;t started an application yet?
                </T>{' '}
                <ButtonLink to="/start" className="mh-start-signin-button">
                  <T cid="pageStart:continueMode.start.button.label">Start a new application...</T>
                </ButtonLink>
              </Text>
            </LoginPanel>
          </Content>
        </LayoutContent>
      </Layout>
    );
  }

  return (
    <Layout title="Let's Get Started" titleCid="pageStart:title">
      <LayoutContent>
        <Content cid="pageStart:dangerouslyReplacePageContent.html" className="mh-start-container">
          <SignupPanel>
            <StyledImage
              cid="pageStart:image"
              src={BLANK_IMG_SRC}
              alt="Start page intro"
              className="mh-start-image"
            />
            <ApplyStyleIf wordsMoreThan={40} applyStyle={{ textAlign: 'left' }}>
              <Content cid="pageStart:description" className="mh-start-content">
                <Text align="center" as="p">
                  Welcome to a better mortgage experience.
                </Text>
              </Content>
            </ApplyStyleIf>
            <ButtonRouterLink
              to="/start"
              importance="primary"
              size="normal"
              icon="right-arrow"
              iconLocation="right"
              className="mh-start-new-button"
            >
              <T cid="pageStart:startButton.label">Start new application</T>
            </ButtonRouterLink>
          </SignupPanel>

          <LoginPanel className="mh-start-signin">
            <Text as="p" align="center">
              <T cid="pageStart:signin.description">Already started?</T>{' '}
              <ButtonLink to="/login" className="mh-start-signin-button">
                <T cid="pageStart:signin.button.label">Sign in</T>
              </ButtonLink>
            </Text>
          </LoginPanel>
        </Content>
      </LayoutContent>
    </Layout>
  );
};
