import { AuthState, useAuth } from '@mortgagehippo/auth';
import {
  ButtonRouterLink,
  Image,
  type IShadow,
  MediaBreakpoint,
  Responsive,
  shadow,
  spacing,
  T,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MilestonesListContainer } from '$components/milestones';
import { ProfileMenuContainer } from '$components/profile-menu';

import { useSite } from '../../hooks/use-site';
import { DEFAULT_LOGO_HEIGHT, DEFAULT_LOGO_HEIGHT_MOBILE } from '../constants';

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 5;
`;

const LogoColumn = styled.div<{ logoHeight: number; logoHeightMobile: number }>`
  flex: 0 0 auto;
  padding-left: ${spacing(4)};

  a {
    // ensures tabFocus border to wrap the image properly
    display: inline-block;
  }

  & img {
    height: ${(p) => p.logoHeight}px;
    width: auto;
  }

  ${MediaBreakpoint.PHONE} {
    padding-left: ${spacing(3)};

    & img {
      height: ${(p) => p.logoHeightMobile}px;
    }
  }
`;

const MilestonesColumn = styled.div`
  flex: 1 1 auto;
  text-align: center;
  height: 100%;
  position: relative;
  z-index: 10;

  ${MediaBreakpoint.PHONE} {
    padding: ${spacing(1)} 0 ${spacing(2)};

    &:empty {
      padding: 0;
    }
  }
`;

const AccountColumn = styled.div`
  flex: 0 1 auto;
  text-align: right;
  padding-right: ${spacing(4)};
  white-space: nowrap;
  color: white;

  a,
  a:visited,
  a:active,
  a:hover {
    color: white;
  }

  ${MediaBreakpoint.PHONE} {
    padding-right: ${spacing(3)};
    align-self: center;
    margin-left: auto;
  }
`;

const AuthLinks = styled.div`
  white-space: nowrap;
`;

const MobileSignUpWrapper = styled.div`
  padding: ${spacing(3)} ${spacing(3)} ${spacing(1)};
  text-align: center;
`;

const SignUpButton = styled(
  ({
    buttonColor: _buttonColor,
    buttonBackground: _buttonBackground,
    buttonBorderColor: _buttonBorderColor,
    buttonShadowLevel: _buttonShadowLevel,
    mobile: _mobile,
    ...rest
  }) => <ButtonRouterLink {...rest} />
)<{
  buttonColor?: string;
  buttonBackground?: string;
  signUpBorderColor?: string;
  signUpShadowLevel?: IShadow;
  mobile?: boolean;
}>`
  && {
    ${(p) =>
      p.mobile &&
      css`
        width: 100%;
      `}

    ${(p) =>
      p.buttonColor &&
      css`
        &,
        &:visited,
        &:hover,
        &:visited:hover,
        &:active,
        &:focus,
        &:visited:focus {
          color: ${p.buttonColor};
        }
      `}

    ${(p) =>
      p.buttonBackground &&
      css`
        &,
        &:visited,
        &:hover,
        &:visited:hover,
        &:active,
        &:focus,
        &:visited:focus {
          background: ${p.buttonBackground};
        }

        &:hover,
        &:focus {
          opacity: 0.8;
        }
      `}

    ${(p) =>
      p.buttonBorderColor &&
      css`
        &,
        &:visited,
        &:hover,
        &:visited:hover,
        &:active,
        &:focus,
        &:visited:focus {
          border-color: ${p.buttonBorderColor};
        }

        &:hover,
        &:focus {
          opacity: 0.8;
        }
      `}

    ${(p) =>
      !isNil(p.buttonShadowLevel) &&
      css`
        &,
        &:visited,
        &:hover,
        &:visited:hover,
        &:active,
        &:focus,
        &:visited:focus {
          box-shadow: ${shadow(p.buttonShadowLevel)};
        }
      `}
  }
`;

const SignInButton = styled(({ buttonColor: _buttonColor, ...rest }) => (
  <ButtonRouterLink {...rest} />
))<{
  buttonColor?: string;
}>`
  && {
    ${(p) =>
      p.buttonColor &&
      css`
        &,
        &:visited,
        &:hover,
        &:active {
          color: ${p.buttonColor};
          background: transparent;
        }

        &:hover {
          opacity: 0.8;
        }
      `}
  }
`;

export const DefaultHeader = () => {
  const [, authState] = useAuth();
  const authenticated = authState === AuthState.AUTHENTICATED_ACCOUNT;

  const [site] = useSite();

  const responsive = useResponsive();
  const signInButtonSize = (responsive.PHONE.EXACT_OR_SMALLER && 'xxs') || 'sm';

  const customizations = useCustomizations();
  const logoHeight = customizations.number('app:header.logo.height', DEFAULT_LOGO_HEIGHT);

  const logoHeightMobile = customizations.number(
    'app:header.logo.heightMobile',
    DEFAULT_LOGO_HEIGHT_MOBILE
  );

  const profileInverted = customizations.bool('app:header.profile.inverted', true);
  const profileColor = customizations.color('app:header.profile.color');

  const signUpInverted = customizations.bool('app:header.signup.inverted', true);
  const signUpColor = customizations.color('app:header.signup.color');
  const signUpBackground = customizations.color('app:header.signup.background');
  const signUpBorderColor = customizations.color('app:header.signup.border.color');
  const signUpShadowLevel = customizations.shadow('app:header.signup.shadow.level');

  const signInInverted = customizations.bool('app:header.signin.inverted', true);
  const signInColor = customizations.color('app:header.signin.color');

  return (
    <>
      <Row className="mh-header-wrapper">
        <LogoColumn
          logoHeight={logoHeight!}
          logoHeightMobile={logoHeightMobile!}
          className="mh-header-logo-col"
        >
          <Link to="/">
            <Image
              cid="app:header.logo"
              src="/maxwell.svg"
              alt={`${site?.name || ''} logo`}
              className="mh-header-logo"
            />
          </Link>
        </LogoColumn>

        <Responsive>
          <Responsive.Phone />
          <Responsive.Desktop>
            <MilestonesColumn className="mh-header-milestone-col">
              <MilestonesListContainer />
            </MilestonesColumn>
          </Responsive.Desktop>
        </Responsive>

        <AccountColumn className="mh-header-account-col">
          {authenticated ? (
            <ProfileMenuContainer inverted={profileInverted} buttonColor={profileColor} />
          ) : null}

          {!authenticated && (
            <AuthLinks className="mh-header-auth">
              <SignInButton
                importance="tertiary"
                inverted={signInInverted}
                compact
                size={signInButtonSize}
                to="/login"
                buttonColor={signInColor}
                className="mh-header-signin-button"
              >
                <T cid="header:signin.label">Sign in</T>
              </SignInButton>
              <Responsive>
                <Responsive.Phone />
                <Responsive.Desktop>
                  &nbsp;
                  <SignUpButton
                    importance="primary"
                    inverted={signUpInverted}
                    icon="right-arrow"
                    iconLocation="right"
                    compact
                    size={signInButtonSize}
                    to="/signup"
                    buttonColor={signUpColor}
                    buttonBackground={signUpBackground}
                    buttonBorderColor={signUpBorderColor}
                    buttonShadowLevel={signUpShadowLevel}
                    className="mh-header-signup-button"
                  >
                    <T cid="header:signup.label">Save your progress</T>
                  </SignUpButton>
                </Responsive.Desktop>
              </Responsive>
            </AuthLinks>
          )}
        </AccountColumn>
      </Row>
      <Responsive>
        <Responsive.Phone>
          {!authenticated && (
            <MobileSignUpWrapper className="mh-header-auth">
              <SignUpButton
                mobile
                importance="primary"
                inverted={signUpInverted}
                icon="right-arrow"
                iconLocation="right"
                to="/signup"
                compact
                size="xs"
                buttonColor={signUpColor}
                buttonBackground={signUpBackground}
                buttonBorderColor={signUpBorderColor}
                buttonShadowLevel={signUpShadowLevel}
                className="mh-header-signup-button"
              >
                <T cid="header:signup.label">Save your progress</T>
              </SignUpButton>
            </MobileSignUpWrapper>
          )}
          <MilestonesColumn className="mh-header-milestone-col">
            <MilestonesListContainer />
          </MilestonesColumn>
        </Responsive.Phone>
        <Responsive.Desktop />
      </Responsive>
    </>
  );
};
