import { Chat, ChatError, ChatSkeleton } from '@mortgagehippo/chat';
import { Divider, MediaBreakpoint, spacing } from '@mortgagehippo/ds';
import { ErrorBoundary } from '@mortgagehippo/util';
import { Suspense } from 'react';
import styled from 'styled-components';

import { type IPrimaryAgent } from '../../hooks/use-primary-agent';
import { chatService } from '../../services/chat';
import { SidebarAgentCard } from './sidebar-agent-card';

interface ISidebarChatProps {
  agent: IPrimaryAgent;
  applicationFileId: string;
}

const StyledDivider = styled(Divider)`
  margin: ${spacing(3)} 0 0;
  position: relative;
  z-index: 2;
`;

const CardContainer = styled.div`
  flex: 0 0 auto;
  padding: ${spacing(3)} ${spacing(3)} ${spacing(1)};

  ${MediaBreakpoint.PHONE} {
    padding: 0 ${spacing(3)} ${spacing(1)} 0;
  }
`;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  ${MediaBreakpoint.PHONE} {
    margin: 0 -${spacing(3)};
  }
`;

export const SidebarChat = (props: ISidebarChatProps) => {
  const { agent, applicationFileId } = props;
  return (
    <>
      <CardContainer>
        <SidebarAgentCard agent={agent} />
        <StyledDivider uppercase={false} align="center" size="xs" color="neutral600" />
      </CardContainer>
      <ChatContainer>
        <ErrorBoundary errorComponent={ChatError}>
          <Suspense fallback={<ChatSkeleton />}>
            <Chat
              service={chatService}
              channel={`applicationfile:${applicationFileId}`}
              emptyLabel={
                <>
                  <strong>No Messages Yet</strong>
                  <br />
                  If you have questions, you can send a message to {agent.name} here.
                </>
              }
            />
          </Suspense>
        </ErrorBoundary>
      </ChatContainer>
    </>
  );
};
