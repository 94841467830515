import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';
import {
  type ApplicationFileMilestonesQuery,
  type ApplicationFileMilestonesQueryVariables,
} from '../apollo/graphql';

const QApplicationFileMilestonesQuery = graphql(`
  query ApplicationFileMilestones($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      milestones {
        id
        key
        name
        active
      }
    }
  }
`);

export const useApplicationFileMilestones = (applicationFileId: string) => {
  const [data, loading, , { refetch }] = useQuery<
    ApplicationFileMilestonesQuery,
    ApplicationFileMilestonesQueryVariables
  >(
    QApplicationFileMilestonesQuery,
    {
      applicationFileId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  const milestones = data?.applicationFile?.milestones || [];

  return [milestones, loading, handleRefetch] as const;
};
