import { Alert, Button, T } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/login';

const StyledAlert = styled(Alert)`
  text-align: left;
`;

export const NotFoundPage = () => (
  <Layout title="Page Not Found (404)" titleCid="page404:title">
    <Content>
      <StyledAlert type="error">
        <T cid="page404:message">
          Hi! We can&apos;t seem to find the page you are trying to view. The best option is to
          proceed to the home page and continue from there.
        </T>
      </StyledAlert>
      <Button href="/" importance="primary" icon="right-arrow" iconLocation="right" block>
        <T cid="page404:button.label">Proceed to home</T>
      </Button>
    </Content>
  </Layout>
);
