import { Avatar, Icon, shadow, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  box-shadow: ${shadow(4)};
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: -${spacing(3)};
  right: -${spacing(3)};
  z-index: 100;
  filter: drop-shadow(-1px 1px 1px white);
`;

export interface IAgentAvatar {
  name?: string;
  avatarUrl?: string;
  color?: string;
  background?: string;
  chatEnabled: boolean;
}

export const SidebarAgentAvatar = (props: IAgentAvatar) => {
  const { name, avatarUrl, color, background, chatEnabled } = props;
  const avatarName = chatEnabled ? undefined : name || undefined;
  const showChatIcon = chatEnabled && avatarUrl;

  return (
    <Container>
      {showChatIcon ? <StyledIcon name="chat" color="primary500" size="xl" /> : null}
      <StyledAvatar
        src={avatarUrl}
        icon="chat"
        iconOutline={false}
        name={avatarName}
        alt="Contact"
        compact
        size="md"
        color={color}
        background={background}
      />
    </Container>
  );
};
