import { CustomizationService } from '@mortgagehippo/ds';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import {
  type CustomizationValuesQuery,
  type CustomizationValuesQueryVariables,
} from '../apollo/graphql';

export const customizationsService = new CustomizationService({
  cachePrefix: 'bp',
  fetcher: async (namespaces: string[], language: string) => {
    const { host } = window.location;
    const result = await client.query<CustomizationValuesQuery, CustomizationValuesQueryVariables>({
      query: graphql(`
        query CustomizationValues(
          $domain: String!
          $namespaces: [String!]!
          $languages: [String!]!
        ) {
          customizations(domain: $domain, namespaces: $namespaces, languages: $languages) {
            id
            key
            namespace
            value
            language
          }
        }
      `),
      variables: {
        domain: host,
        namespaces,
        languages: [language],
      },
    });

    const data = result?.data?.customizations || [];
    return CustomizationService.fromArray(namespaces, data);
  },
});
