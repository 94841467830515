import { useAuth } from '@mortgagehippo/auth';
import {
  Card,
  Filename,
  Format,
  type ITableCols,
  spacing,
  T,
  Table,
  Text,
  Title,
} from '@mortgagehippo/ds';
import { isNil, keyBy } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Footer } from '$components/footer';

import { DocumentType } from '../../apollo/graphql';
import { useApplicationFileApplicants } from '../../hooks/use-application-file-applicants';
import { useApplicationFileTasks } from '../../hooks/use-application-file-tasks';
import { Content, Layout } from '../../layouts/main';
import { analytics } from '../../services/analytics';
import { getTasksListSettings } from '../../util/get-tasks-list-settings';
import { TaskColumn } from './task-column';
import {
  type IApplicationFileDocument,
  useApplicationFileDocuments,
} from './use-application-file-documents';

const DOCUMENT_TYPES = [DocumentType.SubmittedDocument, DocumentType.SentDocument];

interface IApplicationDocumentsParams {
  applicationFileId: string;
}

const Disclaimer = styled.div`
  margin-top: ${spacing(7)};
  padding-bottom: ${spacing(7)};
  text-align: center;
`;

const rowKey = (item: IApplicationFileDocument) => item.id;

export const DocumentsPage = (props: RouteComponentProps<IApplicationDocumentsParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  const [user] = useAuth();
  const activeApplicantId = (user && `${user.id}`) || undefined;

  const [documents, documentsLoading] = useApplicationFileDocuments(
    applicationFileId,
    DOCUMENT_TYPES
  );
  const [tasks, tasksLoading] = useApplicationFileTasks(applicationFileId);
  const [applicants, applicantsLoading] = useApplicationFileApplicants(applicationFileId);

  const loading = !activeApplicantId || documentsLoading || tasksLoading || applicantsLoading;

  const columns: ITableCols<IApplicationFileDocument> = useMemo(() => {
    const applicantsById = keyBy(applicants, 'id');

    const { showNames = false, showInitials = false } = getTasksListSettings(
      tasks,
      applicants,
      activeApplicantId
    );

    return [
      {
        title: <T cid="pageDocuments:table.column.documents.title">Document</T>,
        key: 'document',
        render: (record) => (
          <Filename
            name={record.filename}
            url={record.url}
            download
            openNewTab
            description={record.description}
          />
        ),
        alignMobile: 'left',
        colTitleLocationMobile: 'top',
      },
      {
        key: 'task',
        title: <T cid="pageDocuments:table.column.task.title">Task</T>,
        render(record) {
          const { task } = record;

          const applicant = !isNil(task) ? applicantsById[`${task.primaryApplicantId}`] : null;

          if (!task || !applicant) {
            return <>&mdash;</>;
          }

          return (
            <TaskColumn
              applicationFileId={applicationFileId}
              task={task}
              applicant={applicant}
              showName={showNames}
              showInitial={showInitials}
            />
          );
        },
        minWidth: '300px',
        alignMobile: 'left',
        colTitleLocationMobile: 'top',
      },
      {
        title: <T cid="pageDocuments:table.column.uploadedBy.title">Uploaded by</T>,
        key: 'uploaded_by',
        render: (record) => record.uploadedBy?.name || <>&mdash;</>,
        alignMobile: 'left',
        colTitleLocationMobile: 'top',
      },
      {
        title: <T cid="pageDocuments:table.column.dateUploaded.title">Date uploaded</T>,
        key: 'date_uploaded',
        render: (record) => <Format.Date format="date-short" value={record.createdAt} />,
        alignMobile: 'left',
        colTitleLocationMobile: 'top',
      },
    ];
  }, [activeApplicantId, applicants, applicationFileId, tasks]);

  useEffect(() => {
    const nextUserId = user?.id ? `${user.id}` : null;

    analytics.identify(nextUserId, {
      'Application File Id': Number(applicationFileId),
    });
  }, [applicationFileId, user?.id]);

  return (
    <Layout applicationFileId={applicationFileId}>
      <Content>
        <Title level={1} cid="pageDocuments:title">
          Documents
        </Title>
        <Text cid="pageDocuments:description" as="p">
          These are all the documents you sent and received.
        </Text>
        <Card compact>
          <Table
            caption="Documents"
            rowKey={rowKey}
            cols={columns}
            data={documents || []}
            loading={loading}
            verticalAlign="top"
            size="sm"
          />
        </Card>
        <Disclaimer>
          <Footer modal />
        </Disclaimer>
      </Content>
    </Layout>
  );
};
