import { Icon, palette, spacing, styled } from '@mortgagehippo/ds';

const StyledSecondaryTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${palette('neutral600')};
  font-size: 14px;
`;

const LockIcon = styled(Icon)`
  padding-right: ${spacing(1)};
`;

export const SecureDataMessage = () => (
  <StyledSecondaryTextContainer>
    <LockIcon name="lock" outline />
    Your data is always secure and encrypted.
  </StyledSecondaryTextContainer>
);
