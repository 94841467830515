import { Skeleton } from '@mortgagehippo/ds';

export const TasksListSkeleton = () => (
  <div style={{ padding: '20px' }}>
    <Skeleton active paragraph={false} />
    <br />
    <Skeleton active paragraph={false} />
    <br />
    <Skeleton active paragraph={false} />
    <br />
  </div>
);
