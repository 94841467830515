/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createLayout,
  createSlot,
  type ITemplateProps as IBaseTemplateProps,
  palette,
  spacing,
  useCustomizations,
} from '@mortgagehippo/ds';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle, css } from 'styled-components';

import { Footer } from '$components/footer';
import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';
import { useSite } from '../../hooks/use-site';

/*
 * The UNIQUE COMMENT below starting with --- XXX --- is required so that styled components creates a new ID that does not conflict with other FUNCTION global styles (this is fixed in v5.0+)
 * https://github.com/styled-components/styled-components/issues/3097
 */
export const LayoutGlobalStyles = createGlobalStyle<{ backgroundImageSrc?: string }>`
  // --- LANDING LAYOUT --- ad555d0c-bcda-45fb-bbd2-cd38adb797d1
  ${(p) =>
    p.backgroundImageSrc &&
    css`
      body,
      html {
        background-image: url(${p.backgroundImageSrc});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
      }
    `}
`;

const ContentComponent = styled.div``;

const FooterColor = styled('div')<{ background?: string; color?: string }>`
  background: ${(p) => p.background || 'transparent'};
  color: ${(p) => p.color || palette('primary50')};
  padding: ${spacing(7)} ${spacing(3)} ${spacing(5)};
`;

export interface ITemplateProps extends IBaseTemplateProps {
  title: string;
  titleCid?: string;
}

const Template = (props: ITemplateProps) => {
  const { title: rawTitle, titleCid } = props;

  const [site] = useSite();

  const customizations = useCustomizations();
  const title = customizations.text(titleCid || '', rawTitle);
  const pageTitle = (site && `${title} | ${site.name} `) || title;

  const faviconSrc = useFavicon();

  const footerBackgroundColor = customizations.color('app:start.footer.background');
  const footerColor = customizations.color('app:start.footer.color');
  const footerInvertLinks = customizations.bool('app:start.footer.links.invert', true);

  const appBackgroundSrc = customizations.image('app:background.image');

  return (
    <>
      <LayoutGlobalStyles backgroundImageSrc={appBackgroundSrc || undefined} />
      <GlobalScripts />
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <Content.Slot />
      <FooterColor background={footerBackgroundColor} color={footerColor}>
        <Footer invertLinks={footerInvertLinks} />
      </FooterColor>
    </>
  );
};

export const Layout = createLayout(Template);
export const Content = createSlot(Layout, ContentComponent);
