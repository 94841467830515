import { PreQualification, PreQualificationFormViewer } from '@mortgagehippo/tasks';

export interface IApplicationPreQualificationPageProps {
  applicationFileId: string;
}

export const ApplicationPreQualificationPage = (props: IApplicationPreQualificationPageProps) => {
  const { applicationFileId } = props;

  return (
    <PreQualification
      applicationFileId={applicationFileId}
      preventDownloads={false}
      viewer={PreQualificationFormViewer.APPLICANT}
    />
  );
};
