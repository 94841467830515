import { useAuth } from '@mortgagehippo/auth';
import { notifications } from '@mortgagehippo/ds';
import { useCreateApplicationFromApplicant } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import { useContinueApplication } from '../../hooks/use-continue-application';
import { useLastCreatedApplicationFileId } from '../../hooks/use-last-created-application-file-id';
import { useLatestApplicationFileId } from '../../hooks/use-latest-application-file-id';
import { Content, Layout } from '../../layouts/main';
import { LoadingPage } from '../loading';
import { ApplicationsEmpty } from './applications-empty';

interface IApplicationsContainerProps {
  applicationFileId?: string;
}

export const ApplicationsContainer = (props: IApplicationsContainerProps) => {
  const { applicationFileId: paramsApplicationFileId } = props;
  const [user] = useAuth();
  const [, setActiveApplication] = useContinueApplication();

  const applicantId = user?.id || undefined;
  const [manualRedirectId, setManualRedirectId] = useState<string | undefined>();

  const goToLatest = !paramsApplicationFileId;
  const goToLastCreated = paramsApplicationFileId === 'latest';
  const applicationFileId = !goToLastCreated ? paramsApplicationFileId : undefined;

  const [latestApplicationFileId, loadingLatest] = useLatestApplicationFileId({
    skip: !goToLatest,
  });

  const [lastCreatedApplicationFileId, loadingLastCreated] = useLastCreatedApplicationFileId({
    skip: !goToLastCreated,
  });

  const loading = loadingLatest || loadingLastCreated;

  const createApplication = useCreateApplicationFromApplicant();

  const handleStartApplication = useCallback(async () => {
    try {
      if (!applicantId) {
        return;
      }

      const newApplicationFileId = await createApplication(applicantId, undefined, true);

      notifications.success({
        messageCid: 'applicationsPage:startApplication.success.message',
        message: 'Successfully created application',
      });

      setManualRedirectId(newApplicationFileId);
    } catch (e) {
      notifications.error({
        messageCid: 'applicationsPage:startApplication.error.message',
        message: 'There was an unexpected error creating application',
      });
    }
  }, [applicantId, createApplication]);

  const redirectApplicationFileId =
    manualRedirectId ||
    applicationFileId ||
    lastCreatedApplicationFileId ||
    latestApplicationFileId;

  useEffect(() => {
    if (!redirectApplicationFileId) {
      return;
    }
    // set application file id in local storage for start page continue mode
    setActiveApplication(redirectApplicationFileId);
  }, [redirectApplicationFileId, setActiveApplication]);

  if (loading) {
    return <LoadingPage />;
  }

  if (redirectApplicationFileId) {
    return <Redirect to={`/applications/${redirectApplicationFileId}/tasks`} push />;
  }

  return (
    <Layout title="Your Applications">
      <Content>
        <ApplicationsEmpty onStartApplication={handleStartApplication} />
      </Content>
    </Layout>
  );
};
