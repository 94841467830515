import {
  type IBaseTask,
  TaskState,
  toApplicationFileTaskGroups,
} from '@mortgagehippo/tasks';
import { flatten } from 'lodash-es';

import { TaskType } from '../apollo/graphql';
import { type IApplicationFile } from '../hooks/use-application-file';

export enum ApplicationStatus {
  UNDEFINED,
  OPEN,
  DONE,
  START,
}

export const findNextTask = (
  applicationFile: IApplicationFile,
  currentTaskId?: string
): [IBaseTask | undefined, ApplicationStatus] => {
  const blueprint = applicationFile.blueprint?.json || {};

  const taskGroups = toApplicationFileTaskGroups(applicationFile.tasks, blueprint);
  const tasks: IBaseTask[] = flatten(taskGroups.map((group) => group.tasks || []));

  const taskIndex = tasks.findIndex((t) => `${t.id}` === `${currentTaskId}`);
  let prevTasks = [] as typeof tasks;
  let nextTasks = [] as typeof tasks;

  if (taskIndex < 0) {
    nextTasks = [...tasks];
  } else {
    prevTasks = tasks.slice(0, taskIndex);
    nextTasks = tasks.slice(taskIndex + 1);
  }

  const nextOpenTask = nextTasks.filter((t) => t.state === TaskState.open)[0];
  const prevOpenTask = prevTasks.filter((t) => t.state === TaskState.open)[0];
  const completedTasks = tasks.filter((t) => t.state === TaskState.completed);

  let appStatus = completedTasks.length ? ApplicationStatus.OPEN : ApplicationStatus.START;

  // if applicant has a disclosures task, treat app as open so not to show start messaging (to handle when added through encompass)
  const hasDisclosuresTask = tasks.some((t) => t.typeName === TaskType.EncompassDisclosuresTask);
  if (hasDisclosuresTask) {
    appStatus = ApplicationStatus.OPEN;
  }

  if (nextOpenTask) {
    return [nextOpenTask, appStatus];
  }

  if (prevOpenTask) {
    return [prevOpenTask, appStatus];
  }

  return [undefined, ApplicationStatus.DONE];
};
