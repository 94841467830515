import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../apollo';
import { type ApplicationFilePrimaryAgentQuery } from '../apollo/graphql';

export const QApplicationFilePrimaryAgentQuery = graphql(`
  query ApplicationFilePrimaryAgent($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      primaryAgent {
        id
        name
        email
        phone
        jobTitle
        avatarUrl
        license
      }
    }
  }
`);

export type IPrimaryAgent = NonNullable<
  NonNullable<ApplicationFilePrimaryAgentQuery['applicationFile']>['primaryAgent']
>;

export const usePrimaryAgent = (applicationFileId: string, skip?: boolean) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationFilePrimaryAgentQuery,
    { applicationFileId },
    {
      skip,
    }
  );

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  return [data?.applicationFile?.primaryAgent, loading, handleRefetch] as const;
};
