import { useCallback, useState } from 'react';
import { Redirect } from 'react-router';

import { type IApplicationFile } from '../../hooks/use-application-file';
import { findNextTask } from '../../services/find-next-task';
import { TaskSuccess } from './task-success';

export interface ITaskCompletedProps {
  applicationFile: IApplicationFile;
  currentTaskId: string;
  applicantId: string;
}

export const TaskCompleted = (props: ITaskCompletedProps) => {
  const { applicationFile, currentTaskId } = props;
  const [nextTask] = findNextTask(applicationFile, currentTaskId);
  const [timerDone, setTimerDone] = useState(false);

  const handleFinish = useCallback(() => {
    setTimerDone(true);
  }, [setTimerDone]);

  const url =
    (nextTask && `/applications/${applicationFile.id}/tasks/${nextTask.id}`) ||
    `/applications/${applicationFile.id}`;

  return (
    <>
      <TaskSuccess onFinish={handleFinish} startDelayMs={0} finishDelayMs={200} />
      {timerDone ? <Redirect to={url} /> : null}
    </>
  );
};
