/* global CONFIG */
/* global PRODUCTION */
import { asBool } from '@mortgagehippo/util';

export interface IConfig {
  PRODUCTION?: boolean;
  APPLICATION: string;
  DEFAULT_INACTIVITY_TIMEOUT?: number;
  INACTIVITY_COUNTDOWN?: number;
  SENTRY_DSN?: string;
  SENTRY_RELEASE?: string;
  GRAPHQL_ENDPOINT: string;
  GRAPHQL_SUBSCRIPTION_ENDPOINT: string;
  AUTHENTICATE_ENDPOINT: string;
  ENVIRONMENT: string;
  BP_GOOGLE_ANALYTICS_KEY?: string;
  HOTJAR_ID?: string;
  POSTHOG_ID?: string;
  UPSCOPE_JS_PACKAGE_KEY?: string;
}

const { API_DOMAIN } = CONFIG;
const API_SECURE = asBool(CONFIG.API_SECURE, true);
const s = API_SECURE ? 's' : '';
const GRAPHQL_ENDPOINT = `http${s}://${API_DOMAIN}/graphql`;
const GRAPHQL_SUBSCRIPTION_ENDPOINT = `ws${s}://${API_DOMAIN}/graphql`;
const AUTHENTICATE_ENDPOINT = `http${s}://${API_DOMAIN}`;

export const config: IConfig = {
  PRODUCTION,
  APPLICATION: 'BORROWER_PORTAL',
  DEFAULT_INACTIVITY_TIMEOUT: Number(CONFIG.INACTIVITY_TIMEOUT) || 60 * 30 * 1000,
  INACTIVITY_COUNTDOWN: Number(CONFIG.INACTIVITY_COUNTDOWN) || 60 * 5 * 1000,
  SENTRY_DSN: CONFIG.BORROWER_PORTAL_SENTRY_DSN,
  SENTRY_RELEASE: CONFIG.SENTRY_RELEASE,
  GRAPHQL_ENDPOINT,
  GRAPHQL_SUBSCRIPTION_ENDPOINT,
  AUTHENTICATE_ENDPOINT,
  ENVIRONMENT: CONFIG.ENVIRONMENT,
  BP_GOOGLE_ANALYTICS_KEY: CONFIG.GOOGLE_ANALYTICS_KEY,
  HOTJAR_ID: CONFIG.HOTJAR_ID,
  POSTHOG_ID: CONFIG.BORROWER_PORTAL_POSTHOG_ID,
  UPSCOPE_JS_PACKAGE_KEY: CONFIG.UPSCOPE_JS_PACKAGE_KEY,
};
