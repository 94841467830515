import { type ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

const DRAWER_TRANSITION_TIME = 500;

export interface IDrawerProps {
  width: number;
  isOpen: boolean;
  children?: ReactNode;
}

const DrawerContainer = styled.aside<{ isOpen: boolean; width: number }>`
  height: 100%;
  width: ${({ isOpen, width }) => (isOpen ? width : 0)}px;
  transition: width ${DRAWER_TRANSITION_TIME}ms;
`;

const DrawerBody = styled.div<{
  isOpen: boolean;
  width: number;
}>`
  height: 100%;
  width: ${({ width }) => width}px;
`;

export const Drawer = (props: IDrawerProps) => {
  const { isOpen, width, children } = props;
  const [mounted, setMounted] = useState(isOpen);

  useEffect(() => {
    let tm: NodeJS.Timeout | null = null;
    if (!isOpen) {
      tm = setTimeout(() => {
        setMounted(isOpen);
      }, DRAWER_TRANSITION_TIME);
    } else {
      setMounted(true);
    }
    return () => {
      if (tm) clearTimeout(tm);
    };
  }, [isOpen]);

  return (
    <DrawerContainer isOpen={isOpen} width={width}>
      <DrawerBody isOpen={isOpen} width={width}>
        {mounted ? children : null}
      </DrawerBody>
    </DrawerContainer>
  );
};
