import { Alert, Button, T, useCustomizations } from '@mortgagehippo/ds';
import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/login';
import { auth } from '../../services/auth';

const StyledAlert = styled(Alert)`
  text-align: left;
`;

export const InvalidTimeErrorPage = (_props: IErrorComponentProps<any>) => {
  const customizations = useCustomizations();
  const titleTime = customizations.text('pageAuthError:invalidTime.title', 'Clock out of sync');
  const messageTime = customizations.text(
    'pageAuthError:invalidTime.message',
    `It looks like your computer or mobile device's clock is out of sync with the current time. You can fix this by opening your system settings and adjusting the time and date to match the correct one.`
  );
  const buttonLabelTime = customizations.text(
    'pageAuthError:invalidTime.signInButton.label',
    'Sign in again'
  );

  const handleLogin = () => {
    auth.login();
  };

  return (
    <Layout title={titleTime}>
      <Content>
        <StyledAlert type="warning">{messageTime}</StyledAlert>

        <Button
          onClick={handleLogin}
          importance="primary"
          icon="right-arrow"
          iconLocation="right"
          block
        >
          <T>{buttonLabelTime}</T>
        </Button>
      </Content>
    </Layout>
  );
};
