export const IGNORED_ERRORS = [
  'Object Not Found Matching Id:', // Seems to be caused by some browser on Windows systems (https://github.com/cefsharp/CefSharp/blob/445fb9ab2d178b55e3ca6561722d151ce3d9337e/CefSharp/Internals/JavascriptObjectRepository.cs#L293)
  'ResizeObserver loop limit exceeded', // Seems to be caused by react-virtuoso package used for Chat, transparent to user
  'ResizeObserver loop completed with undelivered notifications',
  'jQuery is not a function', // WAFD gtag error,
  'jQuery is not defined', // WAFD gtag error
  "Can't find variable: jQuery", // WAFD gtag error
  "Can't find variable: Vimeo", // WAFD gtag error
  'Vimeo is not defined', // WAFD gtag error
  'tealiumiq', // Homepoint gtag: Failed to load script https://tags.tiqcdn.com/libs/tealiumjs/latest/tealium_collect.min.js
];
