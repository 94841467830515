import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo';
import { type DocumentType, type IApplicationFilesDocumentsQuery } from '../../apollo/graphql';

export type IApplicationFileDocument = NonNullable<
  IApplicationFilesDocumentsQuery['applicationFile']
>['documents']['items'][0];

export type IApplicationFileDocumentTask = NonNullable<
  NonNullable<IApplicationFilesDocumentsQuery['applicationFile']>['documents']['items'][0]['task']
>;

const QApplicationFilesDocuments = graphql(`
  query IApplicationFilesDocuments($applicationFileId: ID!, $types: [DocumentType!]) {
    applicationFile(id: $applicationFileId) {
      id
      documents(types: $types, options: { perPage: 10000, orderBy: { created_at: "desc" } }) {
        items {
          id
          filename
          size
          description
          createdAt
          url
          type
          task {
            id
            meta {
              title
            }
            isVisible
            primaryApplicantId
            common
          }
          uploadedBy {
            id
            name
          }
        }
      }
    }
  }
`);

export const useApplicationFileDocuments = (applicationFileId: string, types?: DocumentType[]) => {
  const [data, loading] = useQuery(
    QApplicationFilesDocuments,
    {
      applicationFileId,
      types,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const result: IApplicationFileDocument[] = data?.applicationFile?.documents.items || [];

  return [result, loading] as const;
};
