import { useAuth } from '@mortgagehippo/auth';
import { decodeUrlSafeHash } from '@mortgagehippo/util';
import { useEffect, useMemo, useRef } from 'react';
import { type RouteComponentProps } from 'react-router';

import { useSiteAgent } from '../../hooks/use-site-agent';
import { history } from '../../services/history';

export const AssignPage = (props: RouteComponentProps<{ assignCode: string }>) => {
  const { match } = props;
  const { params } = match;
  const { assignCode } = params;
  const ref = useRef(false);

  const [, , auth] = useAuth();

  const agentId = useMemo(() => {
    if (!assignCode) {
      return '';
    }

    const decodedHash = decodeUrlSafeHash(assignCode);
    const [userType, userId] = decodedHash.split(':');

    if (userType!.toLowerCase() !== 'agent') {
      return '';
    }

    return userId!;
  }, [assignCode]);

  const [agent, agentLoading] = useSiteAgent(agentId);

  useEffect(() => {
    if (agentId && agentLoading) {
      return undefined;
    }

    if (ref.current) {
      return undefined;
    }

    const fn = async () => {
      ref.current = true;

      if (agent) {
        await auth.setApplicationMetadata({ agent_slug: agentId });
      }

      history.replace('/');

      ref.current = false;
    };

    fn();

    return () => {
      ref.current = false;
    };
  }, [agentId, agent, auth, agentLoading]);

  return null;
};
