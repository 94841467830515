import { Stateful } from '@mortgagehippo/stateful';
import { tween } from '@mortgagehippo/util';
import { useCallback, useRef } from 'react';

export const useLayoutResetScrollTop = Stateful.createHook(() => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const resetScrollTop = useCallback((duration?: number) => {
    if (scrollRef.current) {
      const el = scrollRef.current;
      if (el) {
        el.focus(); // for a11y
        if (el.scrollTop > 1) {
          const callback = (latest: number) => {
            el.scrollTop = latest;
          };
          tween(el.scrollTop, 0, duration, callback);
        }
      }
    }
  }, []);

  return { resetScrollTop, scrollRef };
});
