import { useLazyQuery } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';
import { useSafeCallback } from '@mortgagehippo/util';
import { useEffect, useRef } from 'react';

import { graphql } from '../apollo';
import { type ApplicationFilesQuery } from '../apollo/graphql';
import { useSiteId } from './use-site-id';

export const QApplicationFilesQuery = graphql(`
  query ApplicationFiles($siteId: ID!, $applicantId: ID!) {
    site(id: $siteId) {
      id
      applicant(id: $applicantId) {
        id
        applicationFiles(options: { perPage: 200, orderBy: { created_at: "desc" } }) {
          items {
            id
            detailedDescription
            createdAt
          }
        }
      }
    }
  }
`);

export type IListApplicationFile = NonNullable<
  NonNullable<ApplicationFilesQuery['site']>['applicant']
>['applicationFiles']['items'][0];

export const useApplicationFiles = () => {
  const executedRef = useRef(false);
  const siteId = useSiteId();
  const [user] = useAuth();
  const [execute, data, loading, , { refetch }] = useLazyQuery(QApplicationFilesQuery);

  const handleRefetch = useSafeCallback(() => {
    refetch();
  });

  useEffect(() => {
    if (user) {
      execute({ siteId, applicantId: user.id });
      executedRef.current = true;
    }
  }, [execute, siteId, user]);

  const loadingApplicationFiles = executedRef.current ? loading : true;

  return [
    data?.site?.applicant?.applicationFiles.items,
    loadingApplicationFiles,
    handleRefetch,
  ] as const;
};
