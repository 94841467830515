import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { useSiteId } from './use-site-id';

export const QSiteAgentData = graphql(`
  query SiteAgentData($siteId: ID!, $id: ID, $slug: String) {
    site(id: $siteId) {
      id
      agent(id: $id, slug: $slug) {
        name
        email
        phone
        license
        licensedStates
        bio
        jobTitle
        facebookUrl
        linkedinUrl
        twitterUrl
        avatarUrl
        visible
      }
    }
  }
`);

export const useSiteAgent = (idOrSlug: string, options: IUseApolloQueryOptions = {}) => {
  const isSlug = Number.isNaN(Number(idOrSlug));
  const siteId = useSiteId();

  const [data, loading] = useQuery(
    QSiteAgentData,
    {
      siteId,
      slug: (isSlug && idOrSlug) || undefined,
      id: (!isSlug && idOrSlug) || undefined,
    },
    {
      ...options,
      // we don't have ID (avoid errors trying to store to cache)
      fetchPolicy: 'no-cache',
    }
  );

  return [data?.site?.agent, loading] as const;
};
