import { useAuthService } from '@mortgagehippo/auth';
import { Alert, Button, T } from '@mortgagehippo/ds';
import qs from 'qs';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/login';

const StyledAlert = styled(Alert)`
  text-align: left;
`;

export const SessionTimeoutPage = (props: RouteComponentProps) => {
  const auth = useAuthService();
  const { location } = props;
  const { search } = location;
  const { n: returnTo } = qs.parse(search || '', {
    ignoreQueryPrefix: true,
  });

  const handleLogin = () => {
    auth.login(returnTo as string);
  };

  return (
    <Layout title="Session Timeout" titleCid="pageSessionTimeout:title">
      <Content>
        <StyledAlert type="warning">
          <T cid="pageSessionTimeout:message">
            Hi! For your security we have signed you out after a period of inactivity. Please sign
            in to view your application.
          </T>
        </StyledAlert>
        <Button
          importance="primary"
          icon="right-arrow"
          iconLocation="right"
          onClick={handleLogin}
          block
        >
          <T cid="pageSessionTimeout:signinButton.label">Sign in</T>
        </Button>
        <Button href="/" importance="tertiary" size="sm">
          <T cid="pageSessionTimeout:homeButton.label">or go to the home page...</T>
        </Button>
      </Content>
    </Layout>
  );
};
