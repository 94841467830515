import {
  ButtonLink,
  fontSize,
  fontWeight,
  Modal,
  T,
  useCustomizations,
  useModal,
} from '@mortgagehippo/ds';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { FooterContent } from './footer-content';

const FooterContainer = styled.footer``;

const OpenModalLink = styled(ButtonLink)`
  &&& {
    font-weight: ${fontWeight('light')};
  }
`;

const ModalDisclaimer = styled.div`
  font-size: ${fontSize('xs')};
`;

export const Footer = memo(
  (props: { modal?: boolean; invertLinks?: boolean; className?: string }) => {
    const { modal, invertLinks, className } = props;

    const [isModalOpen, openModal, closeModal] = useModal(false);
    const handleDisclaimerClick = useCallback(() => {
      openModal();
    }, [openModal]);

    const customizations = useCustomizations();
    const footerModalLinkHide = customizations.bool('app:footer.modalLink.hide', false);

    return (
      <>
        <FooterContainer className={className}>
          {modal ? (
            <ModalDisclaimer>
              <T cid="footer:modal.link.description" />{' '}
              {!footerModalLinkHide && (
                <OpenModalLink inverted={invertLinks} onClick={handleDisclaimerClick}>
                  <T cid="footer:modal.link.label">View disclaimer</T>
                </OpenModalLink>
              )}
            </ModalDisclaimer>
          ) : null}
          {!modal && <FooterContent invertLinks={invertLinks} />}
        </FooterContainer>

        {modal && !footerModalLinkHide ? (
          <Modal
            title={<T cid="footer:modal.title">Disclaimer</T>}
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            hideOkButton
            cancelButtonLabel={<T cid="footer:modal.closeButton.label">Close</T>}
          >
            <FooterContent />
          </Modal>
        ) : null}
      </>
    );
  }
);
