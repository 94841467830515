import { useChannel } from '@mortgagehippo/chat';
import {
  Button,
  fontFamily,
  fontSize,
  lineHeight,
  MediaBreakpoint,
  Modal,
  palette,
  spacing,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { useState } from 'react';
import styled from 'styled-components';

import { Drawer } from '$components/drawer';

import { usePrimaryAgent } from '../../hooks/use-primary-agent';
import { chatService } from '../../services/chat';
import { SIDE_BAR_WIDTH } from '../constants';
import { SidebarAgentBubble } from './sidebar-agent-bubble';
import { SidebarChat } from './sidebar-chat';
import { SidebarNewMessageLabel } from './sidebar-new-message-label';

const Container = styled('div')<{ background?: string }>`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  background: ${(p) => p.background || palette('white')};
  overflow-x: hidden;
  overflow-y: auto;
`;

const BubbleContainer = styled.div`
  position: absolute;
  z-index: 100;
  bottom: ${spacing(4)};
  right: ${spacing(5)};

  ${MediaBreakpoint.PHONE} {
    position: fixed;
    left: ${spacing(2)};
    right: unset;
  }
`;

const DrawerBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DrawerHeader = styled.div`
  display: flex;
  flex: 0 0 auto;
  padding: ${spacing(2)} ${spacing(3)};
  border-bottom: 1px solid ${palette('neutral50')};
  align-items: center;
`;

const DrawerHeaderLeft = styled.h4`
  flex: 1 1 auto;
  margin: 0;
  font-size: ${fontSize('xs')};
  font-family: ${fontFamily('secondary')};
  line-height: ${lineHeight('xs')};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${palette('neutral600')};
  text-align: center;
`;

const DrawerHeaderRight = styled.div`
  flex: 0 0 auto;
`;

const ModalContainer = styled.div`
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
`;

interface ISidebarProps {
  applicationFileId: string;
}

const UnreadLabel = (props: { applicationFileId: string }) => {
  const { applicationFileId } = props;
  const channel = useChannel(chatService, `applicationfile:${applicationFileId}`, {
    unreadCount: true,
  });

  const unreadMessageCount = channel.getUnreadCount();
  return <SidebarNewMessageLabel count={unreadMessageCount} />;
};

export const Sidebar = (props: ISidebarProps) => {
  const { applicationFileId } = props;
  const [agent] = usePrimaryAgent(applicationFileId);
  const [isOpen, setIsOpen] = useState(false);
  const responsive = useResponsive();

  const customizations = useCustomizations();
  const avatarBackground = customizations.color('app:agentBubble.avatar.background');
  const avatarColor = customizations.color('app:agentBubble.avatar.color');
  const agentBubbleDisabled = customizations.bool('app:agentBubble.disabled', false);

  const sideBarBackground = customizations.color('app:sideBar.background');
  const chatDisabled = customizations.bool('app:chat.disabled', true);

  const handleOpen = () => {
    if (chatDisabled) return;
    setIsOpen(true);
  };
  const handleClose = () => {
    if (chatDisabled) return;
    setIsOpen(false);
  };

  if (!agent) return null;

  return (
    <Container background={sideBarBackground} className="mh-content-sidebar">
      {!isOpen && !agentBubbleDisabled && agent ? (
        <BubbleContainer>
          <SidebarAgentBubble
            agent={agent}
            background={avatarBackground}
            color={avatarColor}
            chatEnabled={!chatDisabled}
            onClick={handleOpen}
          >
            {!chatDisabled && <UnreadLabel applicationFileId={applicationFileId} />}
          </SidebarAgentBubble>
        </BubbleContainer>
      ) : null}

      {responsive.PHONE.EXACT_OR_SMALLER ? (
        <Modal
          title="Messaging Center"
          isOpen={isOpen}
          onRequestClose={handleClose}
          hideCancelButton
          hideOkButton
        >
          <ModalContainer>
            <SidebarChat agent={agent} applicationFileId={applicationFileId} />
          </ModalContainer>
        </Modal>
      ) : null}

      {!responsive.PHONE.EXACT_OR_SMALLER && (
        <Drawer isOpen={isOpen} width={SIDE_BAR_WIDTH}>
          <DrawerBody>
            <DrawerHeader>
              <DrawerHeaderLeft>Messaging Center</DrawerHeaderLeft>
              <DrawerHeaderRight>
                <Button
                  type="neutral"
                  icon="close"
                  importance="tertiary"
                  size="xxs"
                  iconButton
                  iconButtonRound
                  compact
                  onClick={handleClose}
                  iconColor="neutral600"
                  iconButtonHumble
                />
              </DrawerHeaderRight>
            </DrawerHeader>
            <SidebarChat agent={agent} applicationFileId={applicationFileId} />
          </DrawerBody>
        </Drawer>
      )}
    </Container>
  );
};
