import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { type ApplicationFileApplicantsQuery } from '../apollo/graphql';

export const ApplicantFragment = graphql(`
  fragment IApplicantFragment on Applicant {
    id
    firstName
    lastName
    position
    email
    emailVerified
  }
`);

const QApplicationFileApplicants = graphql(`
  query ApplicationFileApplicants($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      applicants(options: { perPage: 1000 }) {
        items {
          ...IApplicantFragment
        }
      }
    }
  }
`);

export type IApplicationFileApplicant = NonNullable<
  ApplicationFileApplicantsQuery['applicationFile']
>['applicants']['items'][0];

export const useApplicationFileApplicants = (
  applicationFileId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QApplicationFileApplicants,
    { applicationFileId },
    {
      ...options,
      fetchPolicy: 'network-only',
    }
  );

  const result = data?.applicationFile?.applicants.items || [];

  return [result, loading] as const;
};
