import {
  Icon,
  MediaBreakpoint,
  ShadowScroll,
  spacing,
  T,
  Text,
  useCustomizations,
} from '@mortgagehippo/ds';
import {
  containsTheActiveTask,
  TaskState,
  toApplicationFileTaskGroups,
} from '@mortgagehippo/tasks';
import { find } from 'lodash-es';
import { forwardRef, memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { type IApplicationFile } from '../../hooks/use-application-file';
import { MAIN_CONTENT_ID } from '../../layouts/constants';
import { getTasksListSettings } from '../../util/get-tasks-list-settings';
import { TasksListMenu } from './tasks-list-menu';
import { TasksListMenuGroup } from './tasks-list-menu-group';
import { TasksListMenuGroupItem } from './tasks-list-menu-group-item';
import { TasksListSkeleton } from './tasks-list-skeleton';

const TaskNavContainer = styled('div')`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  ${MediaBreakpoint.PHONE} {
    min-height: 100vh;
  }
`;

const ScrollContainer = styled.div`
  flex: 0 0 auto;
  min-height: 0;
  height: 100%;
`;

const NoTasks = styled.div`
  padding: ${spacing(6)} ${spacing(1)} ${spacing(6)} ${spacing(5)};
`;

const StyledTasksListMenu = styled(TasksListMenu)`
  padding: 0 0 ${spacing(8)};

  ${MediaBreakpoint.PHONE} {
    padding-bottom: 40vh;
  }
`;

const handleClick = () => {
  // a11y - focus the main content container
  const el = document.getElementById(`${MAIN_CONTENT_ID}`);
  if (el && typeof el.focus === 'function') {
    el.focus();
  }
};

export interface ITaskListProps {
  activeTaskId?: string; // eslint-disable-line react/no-unused-prop-types
  applicationFile?: IApplicationFile; // eslint-disable-line react/no-unused-prop-types
  applicantId?: string; // eslint-disable-line react/no-unused-prop-types
}

export const TaskList = memo(
  forwardRef<HTMLDivElement, ITaskListProps>((props: ITaskListProps, ref) => {
    const { applicationFile, applicantId, activeTaskId } = props;

    const customizations = useCustomizations();
    const contentNavBackground = customizations.color('app:content.nav.background');

    const applicants = applicationFile?.applicants.items || [];

    const taskGroups = useMemo(() => {
      if (!applicationFile) {
        return [];
      }

      const { tasks } = applicationFile;
      const blueprint = applicationFile.blueprint?.json || {};

      return toApplicationFileTaskGroups(tasks, blueprint);
    }, [applicationFile]);

    const { showNames = false, showInitials = false } =
      (applicantId &&
        applicationFile &&
        getTasksListSettings(applicationFile.tasks, applicants, applicantId)) ||
      {};

    const showDividers = showNames;

    const totalGroups = taskGroups.length;
    const collapsible = totalGroups > 1;

    // if a task is selected open its group
    const activeTaskIdGroup =
      activeTaskId && taskGroups.find((g) => containsTheActiveTask(g, activeTaskId));

    const firstGroupWithOpenTask = find(
      taskGroups,
      (group) => (group.tasks && group.tasks.some((t) => t.state === TaskState.open)) || false // || false to make VS code happy
    );

    /*
     * which group to open:
     * 1. task selected - open it's group
     * 2. otherwise open the group with teh 1st open task
     * 3. otherwise open the last group
     */
    const defaultActiveKeys =
      (activeTaskIdGroup && [activeTaskIdGroup.key]) ||
      (firstGroupWithOpenTask && [firstGroupWithOpenTask.key]) ||
      (totalGroups > 0 && [taskGroups[totalGroups - 1]!.key]) ||
      undefined;

    const renderItem = useCallback(
      (itemProps: any) => {
        if (!applicationFile) return null;

        const { task } = itemProps;

        return (
          <TasksListMenuGroupItem
            {...itemProps}
            to={`/applications/${applicationFile.id}/tasks/${task.id}`}
            onClick={handleClick}
          />
        );
      },
      [applicationFile]
    );

    return (
      <TaskNavContainer>
        {(!applicationFile || !applicantId) && <TasksListSkeleton />}

        {applicationFile && applicantId ? (
          <ScrollContainer>
            <ShadowScroll
              paddingTop={1}
              paddingBottom={2}
              backgroundColor={contentNavBackground}
              ref={ref}
            >
              {!taskGroups.length && (
                <NoTasks>
                  <Text variant="secondary" size="sm">
                    <T cid="pageTasks:taskList.empty.message">There are no outstanding tasks</T>{' '}
                    <Icon name="satisfied" size="sm" outline />
                  </Text>
                </NoTasks>
              )}

              {taskGroups.length > 0 && (
                <StyledTasksListMenu
                  defaultActiveKeys={defaultActiveKeys}
                  collapsible={collapsible}
                >
                  {taskGroups.map((taskGroup) => (
                    <TasksListMenuGroup
                      key={taskGroup.key}
                      taskGroup={taskGroup}
                      activeTaskId={activeTaskId}
                      applicantId={applicantId}
                      applicants={applicants}
                      showNames={showNames}
                      showInitials={showInitials}
                      showDividers={showDividers}
                      renderItem={renderItem}
                    />
                  ))}
                </StyledTasksListMenu>
              )}
            </ShadowScroll>
          </ScrollContainer>
        ) : null}
      </TaskNavContainer>
    );
  })
);
