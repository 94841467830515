import { ButtonRouterLink } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

export interface INextTaskNavProps {
  nextTaskId?: string;
  applicationFileId: string;
  linkTitle?: string | ReactNode;
}

const NextTaskContainer = styled.div`
  text-align: center;
`;

export const NextTaskNav = (props: INextTaskNavProps) => {
  const { nextTaskId, applicationFileId, linkTitle = 'Continue' } = props;

  const url =
    (nextTaskId && `/applications/${applicationFileId}/tasks/${nextTaskId}`) ||
    `/applications/${applicationFileId}`;

  return (
    <NextTaskContainer>
      <ButtonRouterLink
        to={url}
        importance="primary"
        icon="right-arrow"
        iconLocation="right"
        compact
      >
        {linkTitle}
      </ButtonRouterLink>
    </NextTaskContainer>
  );
};
