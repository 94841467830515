import { spacing, Success, useCustomizations } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

export interface ITaskSuccessProps {
  startDelayMs?: number;
  finishDelayMs?: number;
  onFinish: () => void;
  children?: ReactNode;
}

const Container = styled.div`
  text-align: center;
  padding-top: ${spacing(8)};
`;

export const TaskSuccess = (props: ITaskSuccessProps) => {
  const { onFinish, startDelayMs, finishDelayMs, children } = props;

  const customizations = useCustomizations();
  const animationColor = customizations.color('app:dashboard.task.completed.animation.color');

  return (
    <Container>
      <Success
        onFinish={onFinish}
        finishDelay={finishDelayMs}
        startDelay={startDelayMs}
        message={children}
        color={animationColor}
      />
    </Container>
  );
};
