/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Alert,
  borderRadius,
  createLayout,
  createSlot,
  type IImageProps,
  Image,
  type IShadow,
  MediaBreakpoint,
  palette,
  shadow,
  spacing,
  Title,
  useCustomizations,
} from '@mortgagehippo/ds';
import { type FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle, css } from 'styled-components';

import { Footer } from '$components/footer';
import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';
import { useSite } from '../../hooks/use-site';

const DEFAULT_LOGO_HEIGHT = 44;

export const Container = styled('div')<{
  backgroundImageSrc?: string;
  containImageAboveFooter: boolean;
  backgroundProperties: string;
}>`
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${spacing(4)} 0 0;

  ${(p) =>
    p.backgroundImageSrc &&
    p.containImageAboveFooter &&
    css`
      background: url('${p.backgroundImageSrc}') ${p.backgroundProperties};
    `}
  ${MediaBreakpoint.PHONE} {
    padding: 0 ${spacing(2)};
  }
`;

const LogoImage = styled<FunctionComponent<IImageProps & { logoHeight: number }>>(
  ({ logoHeight: _logoHeight, ...rest }) => <Image {...rest} />
)<IImageProps & { logoHeight: number }>`
  height: ${(p) => p.logoHeight}px;
  position: relative;
  margin: 0 auto;
  display: block;
  max-width: 100%;
  object-fit: contain;
`;

const CONTENT_V_PADDING = 5;
const CONTENT_H_PADDING = 7;

const InsideContentLogoContainer = styled('div')<{
  logoInsideContentBackground?: string;
}>`
  ${(p) => css`
    background: ${p.logoInsideContentBackground || 'transparent'};
    padding: ${spacing(CONTENT_V_PADDING)} ${spacing(CONTENT_H_PADDING)}
      ${spacing(p.logoInsideContentBackground ? CONTENT_V_PADDING : 0)};
  `}
`;

export const Login = styled('main')<{
  loginShadow: IShadow;
  loginShadowIntense: boolean;
}>`
  box-sizing: border-box;
  z-index: 3;
  padding: ${spacing(CONTENT_V_PADDING)} ${spacing(CONTENT_H_PADDING)};
  border-radius: ${borderRadius(2)};
  overflow: hidden;
  box-shadow: ${(p) => shadow(p.loginShadow, (p.loginShadowIntense && 'intense') || undefined)};
  margin: 0 auto 12vh;
  width: 500px;
  flex: 0 0 auto;
  background: ${palette('white')};

  ${MediaBreakpoint.PHONE} {
    width: 100%;
    padding: ${spacing(5)} ${spacing(5)};
  }

  ${InsideContentLogoContainer} {
    // below margins are split like that so that prettier does not break the lines
    margin: auto -${spacing(CONTENT_H_PADDING)};
    margin-top: -${spacing(CONTENT_V_PADDING)};
    margin-bottom: ${spacing(6)};
  }
`;

const ContentComponent = styled.div`
  text-align: center;
  padding-top: ${spacing(3)};
`;

const Header = styled('div')`
  text-align: center;
  padding-bottom: ${spacing(5)};
`;

const FooterColor = styled('div')<{ background?: string; textColor?: string }>`
  background: ${(p) => p.background || 'transparent'};
  color: ${(p) => p.textColor || palette('primary50')};
  padding: ${spacing(4)} ${spacing(3)};
  min-height: 15vh;
`;

export interface ITemplateProps {
  title: string;
  titleCid?: string;
  isActive: any; // eslint-disable-line react/no-unused-prop-types
  hideTitle?: boolean;
}

/*
 * The UNIQUE COMMENT below starting with --- XXX --- is required so that styled components creates a new ID that does not conflict with other FUNCTION global styles (this is fixed in v5.0+)
 * https://github.com/styled-components/styled-components/issues/3097
 */
export const LayoutGlobalStyles = createGlobalStyle<{
  backgroundImageSrc?: string;
  containImageAboveFooter: boolean;
  backgroundProperties: string;
}>`
    // --- LOGIN --- 14d5cda0-faef-40da-8954-4ead4f01b56e
  ${(p) =>
    p.backgroundImageSrc &&
    !p.containImageAboveFooter &&
    css`
      body {
        background: url('${p.backgroundImageSrc}') ${p.backgroundProperties};
      }
    `}
`;

const Template = (props: ITemplateProps) => {
  const { title: rawTitle, titleCid, hideTitle } = props;

  const [site] = useSite();

  const customizations = useCustomizations();
  const title = customizations.text(titleCid || '', rawTitle || 'Start Application');
  const pageTitle = (site && `${title} | ${site.name} `) || title;

  const faviconSrc = useFavicon();

  const logoHeight = customizations.number('app:start.logo.height', DEFAULT_LOGO_HEIGHT);

  const logoShowInsideContent = customizations.bool('app:start.logo.showInsideContent', false);

  const logoInsideContentBackground = customizations.color(
    'app:start.logo.insideContent.background'
  );

  const startBackgroundSrc = customizations.image('app:start.background.image');
  const containImageAboveFooter = customizations.bool(
    'app:start.background.containAboveFooter',
    false
  );
  const backgroundProperties = customizations.text(
    'app:start.background.properties',
    'center/cover no-repeat fixed'
  );
  const appBackgroundSrc = customizations.image('app:background.image');

  const footerBackgroundColor = customizations.color('app:start.footer.background');
  const footerColor = customizations.color('app:start.footer.color');
  const footerInvertLinks = customizations.bool('app:start.footer.links.invert', true);

  const loginShadow = customizations.shadow('app:start.content.shadow.level', 5);
  const loginShadowIntense = customizations.bool('app:start.content.shadow.intense', true);

  const backgroundImage = startBackgroundSrc || appBackgroundSrc || undefined;

  return (
    <>
      <LayoutGlobalStyles
        backgroundImageSrc={backgroundImage}
        containImageAboveFooter={containImageAboveFooter}
        backgroundProperties={backgroundProperties}
      />
      <GlobalScripts />
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <Container
        backgroundImageSrc={backgroundImage}
        containImageAboveFooter={containImageAboveFooter}
        backgroundProperties={backgroundProperties}
        className="mh-layout"
      >
        <Header className="mh-header">
          {!logoShowInsideContent && (
            <LogoImage
              logoHeight={logoHeight}
              cid="app:start.logo"
              src="/blank.svg"
              alt={`${site?.name || ''} logo`}
              className="mh-logo"
            />
          )}
        </Header>
        <Login
          loginShadow={loginShadow}
          loginShadowIntense={loginShadowIntense}
          className="mh-content"
        >
          {logoShowInsideContent ? (
            <InsideContentLogoContainer
              logoInsideContentBackground={logoInsideContentBackground}
              className="mh-content-header"
            >
              <LogoImage
                logoHeight={logoHeight}
                cid="app:start.logo"
                src="/blank.svg"
                alt={`${site?.name || ''} logo`}
                className="mh-content-logo"
              />
            </InsideContentLogoContainer>
          ) : null}
          {!hideTitle && (
            <Title align="center" className="mh-title">
              {title}
            </Title>
          )}
          <AlertComponent.Slot />
          <Content.Slot />
        </Login>
      </Container>
      <FooterColor background={footerBackgroundColor} textColor={footerColor} className="mh-footer">
        <Footer invertLinks={footerInvertLinks} className="mh-footer-content" />
      </FooterColor>
    </>
  );
};

export const Layout = createLayout(Template);
export const Content = createSlot(Layout, ContentComponent);
export const AlertComponent = createSlot(Layout, Alert);
