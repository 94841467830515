import {
  Button,
  fontSize,
  fontWeight,
  MediaBreakpoint,
  palette,
  Popover,
  spacing,
  useResponsive,
} from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type IPrimaryAgent } from '../../hooks/use-primary-agent';
import { SidebarAgentAvatar } from './sidebar-agent-avatar';
import { SidebarAgentCard } from './sidebar-agent-card';

const AvatarContainer = styled('div')`
  position: relative;
  text-align: center;
  max-width: 130px;
  min-width: 80px;

  ${MediaBreakpoint.PHONE} {
    max-width: 80px;
  }

  ${Button} {
    padding: 0;
    border-radius: 50%;
    min-width: 0;
    background: none;
  }
`;

const ContactInfo = styled('div')`
  position: relative;
  z-index: 0;
  font-size: ${fontSize('xs')};
  text-align: center;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -${spacing(1)};
    left: -${spacing(1)};
    right: -${spacing(1)};
    bottom: -${spacing(1)};
    border-radius: 4px;
    z-index: -1;
    backdrop-filter: blur(7px);
  }
`;

const JobTitle = styled('span')`
  position: relative;
  display: block;
  margin-top: ${spacing(1)};
  line-height: 1.2em;
  font-weight: ${fontWeight('light')};
  color: ${palette('neutral700')};
  z-index: 1;
`;

const Name = styled('span')`
  display: block;
  line-height: 1.2em;
  margin-top: ${spacing(1)};
  font-weight: ${fontWeight('semibold')};
  position: relative;
  z-index: 1;
`;

const StyledPopover = styled(Popover)`
  position: relative;
  z-index: 1;
`;

interface IAgentBubbleProps {
  agent: IPrimaryAgent;
  background?: string;
  color?: string;
  chatEnabled: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

export const SidebarAgentBubble = (props: IAgentBubbleProps) => {
  const { agent, color, background, chatEnabled, onClick, children } = props;
  const responsive = useResponsive();

  return (
    <AvatarContainer onClick={onClick}>
      {!chatEnabled && (
        <StyledPopover
          content={<SidebarAgentCard agent={agent} />}
          buttonProps={{ compact: true }}
          align={(responsive.PHONE.EXACT_OR_SMALLER && 'TopLeft') || 'TopRight'}
          maxWidthCSS="none"
        >
          {children}
          <SidebarAgentAvatar
            avatarUrl={agent.avatarUrl || undefined}
            name={agent.name || undefined}
            color={color}
            background={background}
            chatEnabled={chatEnabled}
          />
        </StyledPopover>
      )}

      {chatEnabled ? (
        <Button importance="tertiary" onClick={onClick} showHoverStyles={false} compact>
          {children}
          <SidebarAgentAvatar
            avatarUrl={agent.avatarUrl || undefined}
            name={agent.name || undefined}
            color={color}
            chatEnabled={chatEnabled}
          />
        </Button>
      ) : null}

      {responsive.PHONE.BIGGER ? (
        <ContactInfo>
          {agent.name ? <Name>{agent.name}</Name> : null}
          {agent.jobTitle ? <JobTitle>{agent.jobTitle}</JobTitle> : null}
        </ContactInfo>
      ) : null}
    </AvatarContainer>
  );
};
