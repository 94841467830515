import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';
import { useSiteId } from './use-site-id';

const MSendVerificationEmail = graphql(`
  mutation SendVerificationEmail(
    $siteId: ID
    $typeName: String!
    $email: String!
    $force: Boolean!
  ) {
    emailVerification {
      sendVerificationEmail(siteId: $siteId, typeName: $typeName, email: $email, force: $force) {
        success
        error_code
        error_message
      }
    }
  }
`);

export const useSendVerificationEmail = () => {
  const siteId = useSiteId();

  const fn = useMutation(MSendVerificationEmail);

  return useCallback(
    async (typeName: string, email: string, force: boolean) => {
      const result = await fn({ siteId, typeName, email, force });

      return result.data!.emailVerification.sendVerificationEmail;
    },
    [fn, siteId]
  );
};
