import { Link } from 'react-router-dom';

import { type IApplicationFileApplicant } from '../../hooks/use-application-file-applicants';
import { TasksListTitle } from '../tasks/tasks-list-title';
import { type IApplicationFileDocumentTask } from './use-application-file-documents';

interface ITaskColumnProps {
  applicationFileId: string;
  task: IApplicationFileDocumentTask;
  applicant: IApplicationFileApplicant;
  showName?: boolean;
  showInitial?: boolean;
}

export const TaskColumn = (props: ITaskColumnProps) => {
  const { applicationFileId, task, applicant, showName, showInitial } = props;
  const { id: taskId, isVisible } = task;

  const url = isVisible ? `/applications/${applicationFileId}/tasks/${taskId}` : null;

  if (url) {
    return (
      <Link to={url}>
        <TasksListTitle
          task={task}
          applicant={applicant}
          showName={showName}
          showInitial={showInitial}
        />
      </Link>
    );
  }

  return (
    <strong>
      <TasksListTitle
        task={task}
        applicant={applicant}
        showName={showName}
        showInitial={showInitial}
      />
    </strong>
  );
};
