import {
  borderRadiuses,
  fontWeight,
  Icon,
  type IconNames,
  type IShadow,
  MediaBreakpoint,
  shadow,
  spacing,
  T,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { usePreQualificationLetterConfiguration } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';
import { Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const NAV_ITEM_WIDTH_MOBILE = 85;

export interface INavItem {
  link: string;
  icon: IconNames;
  children?: ReactNode;
}

/*
 * no css here to avoid passing props to a custom component that will end up in the DOM
 * Link styles are defined in NavList
 */
const Link = styled(NavLink)``;

const NavList = styled('ul')<{
  itemNormalColor: string;
  itemHoverColor: string;
  itemHoverBackground: string;
  itemActiveColor: string;
  itemActiveBackground: string;
  spacingLeft?: number;
  spacingRight?: number;
  mobileSpacingTop?: number;
  mobileSpacingBottom?: number;
  borderRadiusLeft?: number;
  borderRadiusRight?: number;
  mobileBorderRadiusTop?: number;
  mobileBorderRadiusBottom?: number;
  selectedShadow: IShadow;
}>`
  color: white;
  list-style: none;
  margin: 0;
  padding: 0;

  ${MediaBreakpoint.PHONE} {
    text-align: left;
  }

  // && is for specificity over a global styles
  && ${Link} {
    display: block;
    text-align: center;
    text-decoration: none !important;
    color: ${(p) => p.itemNormalColor};
    font-weight: ${fontWeight('light')};
    padding: ${spacing(3)} 0 ${spacing(2)};
    margin: ${spacing(4)} 0 0;
    position: relative;

    ${MediaBreakpoint.PHONE} {
      padding: ${spacing(2)} 0 ${spacing(1)};
      margin: 2px 0 0;
    }

    svg {
      position: relative;
      z-index: 1;
    }

    &:before {
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      left: ${(p) => (p.spacingLeft !== undefined ? `${p.spacingLeft}px` : '3px')};
      right: ${(p) => (p.spacingRight !== undefined ? `${p.spacingRight}px` : '0')};
      position: absolute;
      background: transparent;
      border-radius: ${borderRadiuses(2, 0, 0, 2)};
      ${(p) =>
        p.borderRadiusLeft !== undefined &&
        css`
          border-top-left-radius: ${p.borderRadiusLeft}px;
          border-bottom-left-radius: ${p.borderRadiusLeft}px;
        `}
      ${(p) =>
        p.borderRadiusRight !== undefined &&
        css`
          border-top-right-radius: ${p.borderRadiusRight}px;
          border-bottom-right-radius: ${p.borderRadiusRight}px;
        `}
      z-index: 0;
      transition: background 500ms;

      ${MediaBreakpoint.PHONE} {
        border-radius: ${borderRadiuses(2, 2, 0, 0)};
        left: 2px;
        right: 2px;
        top: ${(p) => (p.mobileSpacingTop !== undefined ? `${p.mobileSpacingTop}px` : '0')};
        bottom: ${(p) =>
          p.mobileSpacingBottom !== undefined ? `${p.mobileSpacingBottom}px` : '0'};
        ${(p) =>
          p.mobileBorderRadiusTop !== undefined &&
          css`
            border-top-left-radius: ${p.mobileBorderRadiusTop}px;
            border-top-right-radius: ${p.mobileBorderRadiusTop}px;
          `}
        ${(p) =>
          p.mobileBorderRadiusBottom !== undefined &&
          css`
            border-bottom-left-radius: ${p.mobileBorderRadiusBottom}px;
            border-bottom-right-radius: ${p.mobileBorderRadiusBottom}px;
          `}
      }
    }

    & > .mh-icon {
      font-size: 30px;
    }

    &:hover {
      color: ${(p) => p.itemHoverColor};

      &:before {
        background: ${(p) => p.itemHoverBackground};
        transition: background 300ms;
      }
    }

    &.active {
      color: ${(p) => p.itemActiveColor};
      font-weight: ${fontWeight('semibold')};

      &:before {
        background: ${(p) => p.itemActiveBackground};
        box-shadow: ${(p) => shadow(p.selectedShadow)(p)};
      }
    }
  }
`;

const Item = styled('li')`
  position: relative;
  display: block;
  margin: 0;
  padding: 0;

  ${MediaBreakpoint.PHONE} {
    width: ${NAV_ITEM_WIDTH_MOBILE}px;
    display: inline-block;
    vertical-align: bottom;
  }
`;

const Label = styled('span')`
  display: block;
  position: relative;
  z-index: 1;
  font-size: 11px; // exception for setting font-size in px
  line-height: 14px; // exception for setting line-height in px
  padding: 0 ${spacing(1)};

  ${MediaBreakpoint.PHONE} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    top: -2px;
  }
`;

export const NavListItem = ({ icon, link, children }: INavItem) => {
  const responsive = useResponsive();

  return (
    <Route path={link}>
      {(props) => {
        const { match } = props;
        const active = !!match;
        return (
          <Item>
            <Link to={link} aria-current={active ? 'page' : undefined}>
              <Icon
                name={icon}
                size={(responsive.PHONE.EXACT_OR_SMALLER && 'md') || 'lg'}
                outline={!active}
              />
              <Label>{children}</Label>
            </Link>
          </Item>
        );
      }}
    </Route>
  );
};

export interface IDefaultNavigationProps {
  applicationFileId?: string;
}

export const DefaultNavigation = (props: IDefaultNavigationProps) => {
  const { applicationFileId: appFileId } = props;

  const [preQualBorrowerConfiguration, preQualBorrowerConfigurationLoading] =
    usePreQualificationLetterConfiguration(appFileId!, false, {
      skip: !appFileId,
    });

  const customizations = useCustomizations();
  const disableDocumentsLink = customizations.bool(
    'app:appNav.menuItems.documents.disabled',
    false
  );
  const disableTasksLink = customizations.bool('app:appNav.menuItems.tasks.disabled', false);
  const disablePreQualLink =
    preQualBorrowerConfigurationLoading || !preQualBorrowerConfiguration?.borrowerLettersEnabled;
  const itemNormalColor = customizations.color('app:appNav.item.normal.color', 'primary100');
  const spacingLeft = customizations.number('app:appNav.item.spacing.left');
  const spacingRight = customizations.number('app:appNav.item.spacing.right');
  const borderRadiusLeft = customizations.number('app:appNav.item.borderRadius.left');
  const borderRadiusRight = customizations.number('app:appNav.item.borderRadius.right');
  const mobileBorderRadiusTop = customizations.number('app:appNav.mobile.item.borderRadius.top');
  const mobileBorderRadiusBottom = customizations.number(
    'app:appNav.mobile.item.borderRadius.bottom'
  );
  const mobileSpacingTop = customizations.number('app:appNav.mobile.item.spacing.top');
  const mobileSpacingBottom = customizations.number('app:appNav.mobile.item.spacing.bottom');
  const itemHoverColor = customizations.color('app:appNav.item.hover.color', 'white');
  const itemHoverBackground = customizations.color(
    'app:appNav.item.hover.background',
    'primary500'
  );
  const itemActiveColor = customizations.color('app:appNav.item.active.color', 'white');
  const itemActiveBackground = customizations.color(
    'app:appNav.item.active.background',
    'primary800'
  );
  const selectedShadow = customizations.shadow('app:appNav.item.active.shadow', 0);

  const navigationDisabled = disableTasksLink && disableDocumentsLink;

  if (navigationDisabled) {
    return null;
  }

  return (
    <Route
      path="/applications/:applicationFileId"
      render={({ match }) => {
        const { params } = match;
        const { applicationFileId } = params;

        return (
          <NavList
            aria-label="Application navigation"
            itemNormalColor={itemNormalColor!}
            itemHoverColor={itemHoverColor!}
            itemHoverBackground={itemHoverBackground!}
            itemActiveColor={itemActiveColor!}
            itemActiveBackground={itemActiveBackground!}
            selectedShadow={selectedShadow}
            spacingLeft={spacingLeft}
            spacingRight={spacingRight}
            mobileSpacingTop={mobileSpacingTop}
            mobileSpacingBottom={mobileSpacingBottom}
            borderRadiusLeft={borderRadiusLeft}
            borderRadiusRight={borderRadiusRight}
            mobileBorderRadiusTop={mobileBorderRadiusTop}
            mobileBorderRadiusBottom={mobileBorderRadiusBottom}
          >
            {!disableTasksLink && (
              <NavListItem link={`/applications/${applicationFileId}/tasks`} icon="tasks">
                <T cid="appNav:menu.tasks.label">Tasks</T>
              </NavListItem>
            )}
            {!disableDocumentsLink && (
              <NavListItem link={`/applications/${applicationFileId}/documents`} icon="documents">
                <T cid="appNav:menu.documents.label">Documents</T>
              </NavListItem>
            )}
            {!disablePreQualLink && (
              <NavListItem
                link={`/applications/${applicationFileId}/prequalification`}
                icon="prequal"
              >
                <T cid="appNav:menu.preQualification.label">Pre-Qual Letter</T>
              </NavListItem>
            )}
          </NavList>
        );
      }}
    />
  );
};
