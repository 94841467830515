import {
  ApplyStyleIf,
  Content as CustomizableContent,
  fontWeight,
  MediaBreakpoint,
  spacing,
  Text,
  Title,
} from '@mortgagehippo/ds';
import styled, { css } from 'styled-components';

import { ApplicationStateImage } from './application-state-image';

const TaskLockedTitle = styled(Title)``;

const TaskLockedContent = styled.div`
  text-align: center;
  padding: ${spacing(4)} 0 0;

  ${MediaBreakpoint.PHONE} {
    padding: 0;
  }

  ${TaskLockedTitle},
  h1 {
    // adding h1 to account for custom html content
    font-weight: ${fontWeight('semibold', 'secondary')};
  }
`;

const applyIfCSS = css`
  && * {
    text-align: left;
  }
`;

export const TaskLocked = () => (
  <TaskLockedContent>
    <ApplicationStateImage cid="dashboard:task.locked.image" src="task-locked.svg" />
    <TaskLockedTitle align="center" cid="dashboard:task.locked.title">
      Task Locked
    </TaskLockedTitle>
    <ApplyStyleIf wordsMoreThan={40} applyCSS={applyIfCSS}>
      <CustomizableContent cid="dashboard:task.locked.description">
        <Text align="center" as="p">
          You can&apos;t complete this task at the moment. Please be on the watch as it will be
          available in the future.
        </Text>
      </CustomizableContent>
    </ApplyStyleIf>
  </TaskLockedContent>
);
