import { useAuth } from '@mortgagehippo/auth';
import { Alert, Button, T } from '@mortgagehippo/ds';
import { Redirect } from 'react-router';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/login';

const StyledAlert = styled(Alert)`
  text-align: left;
`;

export const ForcedLogoutPage = () => {
  const [applicant] = useAuth();
  // if the user is logged in go to the application
  if (applicant) {
    return <Redirect to="/applications" />;
  }

  return (
    <Layout title="You Were Signed Out" titleCid="pageForcedLogout:title">
      <Content>
        <StyledAlert type="warning">
          <T cid="pageForcedLogout:message">
            Hi! We have signed you out because you have signed in from another tab. The best option
            is to proceed to the home page and continue from there.
          </T>
        </StyledAlert>
        <Button href="/" importance="primary" icon="right-arrow" iconLocation="right" block>
          <T cid="pageForcedLogout:button.label">Proceed to home</T>
        </Button>
      </Content>
    </Layout>
  );
};
