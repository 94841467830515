import { useLazyQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useEffect } from 'react';

import { graphql } from '../apollo';
import { type ApplicationFileQuery } from '../apollo/graphql';

const QApplicationFileQuery = graphql(`
  query ApplicationFile($applicationFileId: ID!) {
    applicationFile(id: $applicationFileId) {
      id
      voeIntegrationType
      voaIntegrationType
      milestones {
        id
        key
        name
        active
      }
      applicants {
        items {
          ...IApplicantFragment
        }
      }
      tasks {
        ...ITaskFragment
      }
      blueprint {
        id
        json
      }
    }
  }
`);

export type IApplicationFile = NonNullable<ApplicationFileQuery['applicationFile']>;

export type IApplicationFileApplicant = IApplicationFile['applicants']['items'][0];

export const useApplicationFile = (applicationFileId: string) => {
  const [execute, data, loading, , result] = useLazyQuery(QApplicationFileQuery, {
    fetchPolicy: 'network-only',
  });
  const { refetch } = result;

  useEffect(() => {
    execute({ applicationFileId });
  }, [applicationFileId, execute]);

  const handleRefetch = useSafeCallback(() => refetch({ applicationFileId }));

  return [data?.applicationFile, loading, handleRefetch] as const;
};
