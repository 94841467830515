import { type FormSubmitHandler, ModalForm, useCustomizations, YesNo } from '@mortgagehippo/ds';

const INITIAL_VALUES = { import_data: true };

export interface IDuplicateApplicationForm {
  import_data?: boolean;
}

interface IStartApplicationModalProps {
  hasExistingApplication: boolean;
  onSubmit: FormSubmitHandler<IDuplicateApplicationForm>;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const StartApplicationModalDialog = (props: IStartApplicationModalProps) => {
  const { hasExistingApplication, onSubmit, onRequestClose, isOpen } = props;

  const customizations = useCustomizations();
  const title = customizations.text('modalStartApplication:title', 'Start New Application');

  const explanation = customizations.text(
    'modalStartApplication:explanation',
    'Would you like to copy over the information from the current application to the new one?'
  );

  const okButtonLabel = customizations.text(
    'modalStartApplication:okButton.label',
    'Yes, create a new application'
  );

  const cancelButtonLabel = customizations.text(
    'modalStartApplication:cancelButton.label',
    'Cancel'
  );

  return (
    <ModalForm<IDuplicateApplicationForm>
      title={title}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      okButtonLabel={okButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      initialValues={hasExistingApplication ? INITIAL_VALUES : undefined}
    >
      {hasExistingApplication ? (
        <YesNo.Box
          name="import_data"
          label={explanation}
          description="This includes loan, property and personal information. You might be asked to reenter some employment and assets information."
          labelYes="Yes, copy over information"
          labelNo="No, start a blank application"
          hideRequiredLabel
          displayVertical
          initialValue
          required
        />
      ) : null}
    </ModalForm>
  );
};
