import { Alert, Button, T, useCustomizations } from '@mortgagehippo/ds';
import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/login';
import { auth } from '../../services/auth';

const StyledAlert = styled(Alert)`
  text-align: left;
`;

export const ForbiddenErrorPage = (_props: IErrorComponentProps<any>) => {
  const customizations = useCustomizations();

  const titleForbidden = customizations.text('pageAuthError:forbidden.title', 'You Must Sign In');
  const messageForbidden = customizations.text(
    'pageAuthError:forbidden.message',
    `Please sign in order to continue.`
  );
  const buttonLabelForbidden = customizations.text(
    'pageAuthError:forbidden.signInButton.label',
    'Sign in'
  );

  const handleLogin = () => {
    auth.login(window.location.pathname);
  };

  return (
    <Layout title={titleForbidden}>
      <Content>
        <StyledAlert type="warning">{messageForbidden}</StyledAlert>

        <Button
          onClick={handleLogin}
          importance="primary"
          icon="right-arrow"
          iconLocation="right"
          block
        >
          <T>{buttonLabelForbidden}</T>
        </Button>
      </Content>
    </Layout>
  );
};
