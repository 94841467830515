import { AuthService } from '@mortgagehippo/auth';

import { config } from '../config';

export const auth = new AuthService({
  endpoint: config.AUTHENTICATE_ENDPOINT,
  loginPath: '/login',
  afterLoginPath: '/applications',
  callbackPath: '/authorize',
  afterInactivityTimeoutPath: '/session-timeout',
  afterForcedLogoutPath: '/forced-logout',
  defaultInactivityMs: config.DEFAULT_INACTIVITY_TIMEOUT,
  inactivityCountdownMs: config.INACTIVITY_COUNTDOWN,
});
