import { type RouteComponentProps } from 'react-router';

import { ApplicationsContainer } from './applications-container';

interface IApplicationsPageParams {
  applicationFileId?: string;
}

export const ApplicationsPage = (props: RouteComponentProps<IApplicationsPageParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  return <ApplicationsContainer applicationFileId={applicationFileId} />;
};
