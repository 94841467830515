import { ForbiddenError } from '@mortgagehippo/auth';
import {
  EncompassDisclosuresIframe,
  type IEncompassDisclosuresTask,
  useEncompassDisclosuresPackage,
  useTask,
} from '@mortgagehippo/tasks';
import { NotFoundError } from '@mortgagehippo/util';
import { type RouteComponentProps } from 'react-router';

import { Content, Layout } from '../../layouts/disclosures';

interface IDisclosuresPagePropsParams {
  applicationFileId: string;
  taskId: string;
}

export const DisclosuresPage = (props: RouteComponentProps<IDisclosuresPagePropsParams>) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId, taskId } = params;

  const [task, taskApplicant, taskLoading] = useTask<IEncompassDisclosuresTask>(taskId);

  const [disclosuresPackage, disclosuresPackageLoading] = useEncompassDisclosuresPackage(
    applicationFileId,
    taskId
  );

  const { signable } = disclosuresPackage || {};

  if (taskLoading || disclosuresPackageLoading) {
    return null;
  }

  if (!task || !disclosuresPackage) {
    throw new NotFoundError();
  }

  if (!signable) {
    /*
     * the iframe will only work if you are logged into auth0 as the
     * expected user.
     */
    throw new ForbiddenError();
  }

  const { meta } = task;
  const { title: taskTitle } = meta;

  const applicantName = taskApplicant?.firstName || undefined;
  const title = `${applicantName ? `${applicantName}: ` : ''}${taskTitle}}`;

  return (
    <Layout title={title} applicationFileId={applicationFileId} taskId={taskId}>
      <Content>
        <EncompassDisclosuresIframe disclosuresPackage={disclosuresPackage} />
      </Content>
    </Layout>
  );
};
