import { Button, spacing, T, Text, Title } from '@mortgagehippo/ds';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

const Centered = styled.div`
  margin-top: ${spacing(7)};
  text-align: center;

  & > * {
    text-align: center;
  }
`;

interface IApplicationsEmptyProps {
  onStartApplication: () => Promise<any>;
}

export const ApplicationsEmpty = (props: IApplicationsEmptyProps) => {
  const { onStartApplication } = props;
  const [loading, setLoading] = useState(false);

  const handleStartApplication = useCallback(async () => {
    setLoading(true);

    await onStartApplication();

    setLoading(false);
  }, [onStartApplication]);

  return (
    <Centered>
      <Title cid="applicationsPage:empty.title">No Applications Found</Title>
      <Text cid="applicationsPage:empty.description" as="p">
        You haven&apos;t started an application yet but you can start one now!
      </Text>
      <Button onClick={handleStartApplication} loading={loading} importance="primary">
        <T cid="applicationsPage:startApplication.button.label">Start new application</T>
      </Button>
    </Centered>
  );
};
