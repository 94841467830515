import {
  Dropdown,
  fontFamily,
  fontSize,
  fontWeight,
  type IButtonProps,
  Icon,
  type IDropdownActionList,
  type IIconProps,
  lineHeight,
  palette,
  spacing,
  T,
  useCustomizations,
  useModal,
  useResponsive,
} from '@mortgagehippo/ds';
import { ApplicationDescription, ApplicationStartDate } from '@mortgagehippo/tasks';
import { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { type IListApplicationFile } from '../../hooks/use-application-files';
import {
  type IDuplicateApplicationForm,
  StartApplicationModalDialog,
} from './start-application-modal-dialog';

interface IProfileMenuProps {
  applicationFiles: IListApplicationFile[];
  inverted?: boolean;
  onStartApplication: (importData: boolean) => Promise<boolean>;
  applicantFirstName?: string;
  buttonColor?: string;
  activeApplicationFileId?: string;
}

const StyledIcon = styled(Icon)`
  margin-right: ${spacing(1)};
  vertical-align: middle;
`;

const DropdownLabel = styled.span`
  vertical-align: middle;
`;

const DropdownWrapper = styled('span')<{ buttonColor?: string }>`
  ${(p) =>
    p.buttonColor &&
    css`
      && button {
        &,
        &:hover,
        &:active {
          color: ${p.buttonColor};
          background: transparent;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    `}
`;

const ApplicationName = styled('span')`
  display: block;
  font-size: ${fontSize('normal')};
  line-height: ${lineHeight('sm')};
`;

const ApplicationDescriptionWrapper = styled.span`
  display: block;
  color: ${palette('neutral600')};
  font-size: ${fontSize('xs')};
  line-height: 1.25em;
  font-weight: ${fontWeight('light', 'secondary')};
  padding-top: ${spacing(1)};
`;

const Application = styled.span<{
  selected?: boolean;
}>`
  font-family: ${fontFamily('secondary')};

  ${(p) => css`
    ${(p.selected &&
      css`
        ${ApplicationName} {
          color: ${palette('primary600')};
        }

        ${ApplicationDescriptionWrapper} {
          color: ${palette('primary500')};
        }
      `) ||
    ''}
  `}
`;

const selectedApplicationIconProps: IIconProps = {
  name: 'circle',
  color: 'primary600',
  size: 'xs',
};

export const ProfileMenu = (props: IProfileMenuProps) => {
  const {
    applicationFiles,
    inverted = true,
    onStartApplication,
    applicantFirstName,
    buttonColor,
    activeApplicationFileId,
  } = props;
  const [modalOpen, openModal, closeModal] = useModal(false);

  const customizations = useCustomizations();
  const disableStartApplicationAction = customizations.bool(
    'header:profile.newApplication.disabled',
    false
  );

  const responsive = useResponsive();

  const handleStartClick = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleStartSubmit = useCallback(
    async (values: IDuplicateApplicationForm) => {
      const { import_data: importData } = values || {};
      const success = await onStartApplication(!!importData);

      if (!success) {
        return;
      }

      closeModal();
    },
    [closeModal, onStartApplication]
  );

  const dropdownButtonProps: IButtonProps = useMemo(
    () =>
      responsive.PHONE.EXACT_OR_SMALLER
        ? {
            size: 'xs',
            inverted,
            importance: 'tertiary',
            icon: 'user',
            iconButton: true,
            iconButtonHumble: true,
            iconButtonRound: true,
            compact: true,
          }
        : {
            size: 'sm',
            inverted,
            importance: 'tertiary',
            icon: 'down-arrow-small',
            iconLocation: 'right',
            compact: true,
          },
    [responsive.PHONE.EXACT_OR_SMALLER, inverted]
  );

  const dropdownActions = useMemo(() => {
    const actions: IDropdownActionList = [];

    actions.push({
      key: 'change-password',
      cid: 'header:profile.password.label',
      to: '/settings',
      label: 'Change password',
    });

    if (!disableStartApplicationAction) {
      actions.push({
        key: 'new-application',
        cid: 'header:profile.newApplication.label',
        onAction: handleStartClick,
        label: 'Start new application',
      });
    }

    const totalApplicationFiles = applicationFiles.length;

    if (totalApplicationFiles > 0) {
      const applicationFileActions = applicationFiles.map((applicationFile, index) => {
        const { id, detailedDescription, createdAt } = applicationFile;

        const labelId = totalApplicationFiles - index;
        const selected = id === activeApplicationFileId;

        return {
          key: id,
          label: (
            <Application selected={selected}>
              <ApplicationName>Application {labelId}</ApplicationName>
              <ApplicationDescriptionWrapper>
                <ApplicationDescription description={detailedDescription}>
                  <ApplicationStartDate createdAt={createdAt} />
                </ApplicationDescription>
              </ApplicationDescriptionWrapper>
            </Application>
          ),
          to: `/applications/${id}/tasks`,
          iconProps: selected ? selectedApplicationIconProps : undefined,
        };
      });

      actions.push(
        {
          separator: true,
        },
        {
          title: <T cid="header:yourApplications.title">Your Applications</T>,
          actions: applicationFileActions,
        },
        {
          separator: true,
        }
      );
    }

    actions.push({
      key: 'logout',
      cid: 'header:profile.logout.label',
      to: '/logout',
      label: 'Sign out',
    });

    return actions;
  }, [activeApplicationFileId, applicationFiles, disableStartApplicationAction, handleStartClick]);

  const dropdownButtonLabel = useMemo(
    () => (
      <>
        {responsive.PHONE.BIGGER ? <StyledIcon name="user" size="md" /> : null}
        <DropdownLabel>
          {applicantFirstName || <T cid="header:profile.label">Profile</T>}
        </DropdownLabel>
      </>
    ),
    [applicantFirstName, responsive.PHONE.BIGGER]
  );

  return (
    <DropdownWrapper buttonColor={buttonColor}>
      <Dropdown
        buttonProps={dropdownButtonProps}
        label={dropdownButtonLabel}
        actions={dropdownActions}
      />

      <StartApplicationModalDialog
        hasExistingApplication={!!applicationFiles.length}
        onSubmit={handleStartSubmit}
        onRequestClose={closeModal}
        isOpen={modalOpen}
      />
    </DropdownWrapper>
  );
};
