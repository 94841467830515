import { Storage } from '@mortgagehippo/util';
import { useCallback, useState } from 'react';

const ACTIVE_APPLICATION_STORAGE_KEY = 'bp:existing-application';
const storage = new Storage('local');

export const useContinueApplication = () => {
  const [activeApplicationValue, setActiveApplicationValue] = useState(
    storage.get(ACTIVE_APPLICATION_STORAGE_KEY)
  );

  const setActiveApplication = useCallback((applicationId: string) => {
    storage.set(ACTIVE_APPLICATION_STORAGE_KEY, applicationId);
    setActiveApplicationValue(applicationId);
  }, []);

  return [activeApplicationValue, setActiveApplication] as const;
};
