import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';
import { useSafeCallback } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';
import { orderBy } from 'lodash-es';
import { useMemo } from 'react';

import { graphql } from '../apollo';
import { type LatestApplicationFileQuery } from '../apollo/graphql';
import { useSiteId } from './use-site-id';

export const QLatestApplicationFileQuery = graphql(`
  query LatestApplicationFile($siteId: ID!, $applicantId: ID!) {
    site(id: $siteId) {
      id
      applicant(id: $applicantId) {
        id
        applicationFiles(options: { perPage: 200, orderBy: { created_at: "desc" } }) {
          items {
            id
            createdAt
            lastAccessedAt
          }
        }
      }
    }
  }
`);

type IUseLatestApplicationFileIdResult = [
  string | undefined | null,
  boolean,
  () => Promise<ApolloQueryResult<LatestApplicationFileQuery> | undefined>,
];

/*
 * returns the most recently accessed application file or the last created application file
 * if one has been created since last access.
 */
export const useLatestApplicationFileId = (
  options: IUseApolloQueryOptions = {}
): IUseLatestApplicationFileIdResult => {
  const siteId = useSiteId();
  const [user] = useAuth();

  const { skip, ...restOptions } = options;

  const [data, loading, , { refetch }] = useQuery(
    QLatestApplicationFileQuery,
    { siteId, applicantId: user.id },
    {
      fetchPolicy: 'network-only',
      skip: !user || skip,
      ...restOptions,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    if (!user) {
      return undefined;
    }

    return refetch({ siteId, applicantId: user.id });
  });

  const id = useMemo(() => {
    if (!data?.site?.applicant) {
      return undefined;
    }

    const applicationFiles = data.site.applicant.applicationFiles.items;
    const sortedApplicationFiles = orderBy(
      applicationFiles,
      [(a) => a.lastAccessedAt || a.createdAt],
      ['desc']
    );

    const latest = sortedApplicationFiles[0];

    return latest?.id || null;
  }, [data]);

  return [id, loading, handleRefresh];
};
