import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo';

const MTouchApplicationFile = graphql(`
  mutation TouchApplicationFile($applicationFileId: ID!) {
    touchApplicationFile(applicationFileId: $applicationFileId) {
      id
    }
  }
`);

export const useTouchApplicationFile = () => {
  const touchApplicationFile = useMutation(MTouchApplicationFile);

  return useCallback(
    async (applicationFileId: string) => {
      await touchApplicationFile({ applicationFileId });
    },
    [touchApplicationFile]
  );
};
