import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import { type SiteQuery, type SiteQueryVariables } from '../apollo/graphql';
import { getSiteId, useSiteId } from './use-site-id';

export type ISite = NonNullable<SiteQuery['site']>;

const QSite = graphql(`
  query Site($domain: ID!) {
    site(id: $domain) {
      id
      name
      partnerId
      partnerName
      liveSite
    }
  }
`);

export const useSite = () => {
  const siteId = useSiteId();

  const [data, loading] = useQuery(QSite, { domain: siteId });

  const result = data?.site;

  return [result, loading] as const;
};

export const getSite = async () => {
  const siteId = getSiteId();

  const result = await client.query<SiteQuery, SiteQueryVariables>({
    query: QSite,
    variables: { domain: siteId },
  });

  const { data } = result;

  return data.site;
};
