import {
  Avatar,
  fontSize,
  fontWeight,
  Format,
  palette,
  spacing,
  T,
  useResponsive,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type IPrimaryAgent } from '../../hooks/use-primary-agent';

const Container = styled.div`
  display: flex;
`;

const AvatarColumn = styled.div`
  flex: 0 0 auto;
  margin-right: ${spacing(3)};
`;

const ContentColumn = styled.div`
  flex: 1 1 auto;
`;

const Name = styled('span')`
  font-weight: ${fontWeight('bold')};
  line-height: 1.3em;
`;

const Title = styled('span')`
  display: block;
  font-weight: ${fontWeight('light')};
  color: ${palette('neutral600')};
  margin-bottom: ${spacing(2)};
  font-size: ${fontSize('xs')};
  line-height: 1.3em;
  font-style: italic;
`;

const Details = styled('span')`
  display: block;
  font-weight: ${fontWeight('light')};
  color: ${palette('neutral600')};
  margin-bottom: ${spacing(2)};
  font-size: ${fontSize('xs')};
  line-height: 1.3em;

  ${Title} + & {
    margin-top: -${spacing(2)};
  }
`;

const Link = styled.a`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${fontSize('xs')};
  line-height: 1.3em;
`;

interface IAgentBubblePopoverProps {
  agent: IPrimaryAgent;
  avatarColor?: string;
}

export const SidebarAgentCard = (props: IAgentBubblePopoverProps) => {
  const { agent, avatarColor } = props;

  const responsive = useResponsive();

  return (
    <Container>
      {responsive.PHONE.BIGGER ? (
        <AvatarColumn>
          <Avatar
            src={agent.avatarUrl}
            name={agent.name || ''}
            alt={agent.name || ''}
            size="normal"
            compact
            color={avatarColor || undefined}
          />
        </AvatarColumn>
      ) : null}
      <ContentColumn>
        <Name>{agent.name}</Name>
        <Title>{agent.jobTitle}</Title>
        {agent.license ? (
          <Details>
            <T cid="agentBubble:license.label">NMLS ID</T> {agent.license}
          </Details>
        ) : null}
        {agent.phone ? (
          <Link href={`tel:${agent.phone}`}>
            <Format.Phone value={agent.phone} />
          </Link>
        ) : null}
        {agent.email ? <Link href={`mailto:${agent.email}`}>{agent.email}</Link> : null}
      </ContentColumn>
    </Container>
  );
};
