import {
  Button,
  type ButtonIconLocation,
  ButtonRouterLink,
  Dropdown,
  fontSize,
  type IButtonProps,
  type IconNames,
  type IDropdownActionList,
  lineHeight,
  palette,
  type PaletteColor,
  spacing,
  Title,
} from '@mortgagehippo/ds';
import { memo, type ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';

const ACTION_WIDTH_PX = 80;
const HEIGHT_PX = 40;

interface IMobilePageNavAction {
  to?: string; // route - 1st priority behavior
  onClick?: (e: any) => void; // action - 2nd priority behavior
  dropdownActions?: IDropdownActionList; // dropdown - 3rd priority behavior
  icon?: IconNames;
  label: ReactNode;
  iconButton?: boolean;
  iconLocation?: ButtonIconLocation;
}

export interface IMobilePageNavBarProps {
  background?: PaletteColor;
  children?: ReactNode;
  leftAction?: IMobilePageNavAction;
  rightAction?: IMobilePageNavAction;
}

const StyledButton = styled(Button)``;
const StyledButtonRouterLink = styled(ButtonRouterLink)``;

const NavWrapper = styled('div')<{ background?: PaletteColor }>`
  padding: ${spacing(1)} ${spacing(2)};
  display: flex;
  width: 100%;
  height: ${HEIGHT_PX}px;
  max-width: 100%;
  align-items: center;
  background: ${(p) => p.background || palette('neutral50')};
  border-bottom: 1px solid ${palette('neutral100')};
`;

const NavLeft = styled('div')`
  flex: 0 0 ${ACTION_WIDTH_PX}px;
  width: ${ACTION_WIDTH_PX}px;
  text-align: left;
  ${StyledButton},
  ${StyledButtonRouterLink} {
    margin-left: -${spacing(2)};
  }
`;

const NavCenter = styled('div')<{ hasActions?: boolean }>`
  flex: 1 1 auto;
  text-align: center;
  max-width: ${(p) => (p.hasActions && `calc(100% - 2 * ${ACTION_WIDTH_PX}px)`) || '100%'};
`;

const NavTitle = styled(Title)`
  font-size: ${fontSize('normal')};
  line-height: ${lineHeight('normal')};

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
`;

const NavRight = styled('div')`
  flex: 0 0 ${ACTION_WIDTH_PX}px;
  width: ${ACTION_WIDTH_PX}px;

  text-align: right;

  ${StyledButton},
  ${StyledButtonRouterLink} {
    margin-right: -${spacing(2)};
  }
`;

export const MobilePageNavBar = memo((props: IMobilePageNavBarProps) => {
  const { background, children, leftAction, rightAction } = props;

  const getAction = useCallback((action?: IMobilePageNavAction, location?: ButtonIconLocation) => {
    if (!action) {
      return null;
    }

    const { to, onClick, dropdownActions, iconButton, icon, label, iconLocation } = action;

    const buttonProps: IButtonProps = {
      size: 'xs',
      compact: true,
      importance: 'tertiary',
      iconLocation: iconLocation || location,
      icon,
      iconButton,
      iconButtonRound: iconButton,
    };

    if (to) {
      return (
        <StyledButtonRouterLink to={to} {...buttonProps}>
          {label}
        </StyledButtonRouterLink>
      );
    }

    if (onClick) {
      return (
        <StyledButton onClick={onClick} {...buttonProps}>
          {label}
        </StyledButton>
      );
    }

    if (dropdownActions) {
      return <Dropdown buttonProps={buttonProps} label={label} actions={dropdownActions} />;
    }

    return null;
  }, []);

  const leftActionContent = useMemo(() => getAction(leftAction, 'left'), [getAction, leftAction]);

  const rightActionContent = useMemo(
    () => getAction(rightAction, 'right'),
    [getAction, rightAction]
  );

  const hasActions = !!(leftAction || rightAction);

  return (
    <NavWrapper background={background}>
      {hasActions ? <NavLeft>{leftActionContent}</NavLeft> : null}
      <NavCenter hasActions={hasActions}>
        {children ? (
          <NavTitle level={2} align="center">
            {children}
          </NavTitle>
        ) : null}
      </NavCenter>
      {hasActions ? <NavRight>{rightActionContent}</NavRight> : null}
    </NavWrapper>
  );
});
