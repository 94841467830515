import {
  borderRadius,
  borderRadiuses,
  Button,
  fontSize,
  fontWeight,
  Icon,
  palette,
  spacing,
  T,
  tabFocusShadowInset,
  Title,
  useCustomizations,
} from '@mortgagehippo/ds';
import {
  containsTheActiveTask,
  getGroupState,
  type IBaseTask,
  isFallbackTaskGroup,
  type ITaskApplicant,
  type ITaskGroup,
  TaskGroupStateIcon,
  TaskState,
} from '@mortgagehippo/tasks';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { AnimatePresence, motion } from 'framer-motion';
import { keyBy } from 'lodash-es';
import {
  type KeyboardEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { type ITasksListMenuGroupItemProps } from './tasks-list-menu-group-item';

// prettier-ignore
const Toggle = styled.div<{
  collapsible?: boolean;
  spacingLeft?: number;
  titleColor?: string
}>`
  display: flex;
  position: relative;
  padding: ${spacing(2)} 0 ${spacing(2)} ${p => (p.spacingLeft !== undefined ? `${p.spacingLeft}px` : spacing(3))};
  color: ${palette('neutral900')};
  outline: 0;
  font-size: ${fontSize('lg')};
  line-height: 1.3em;
  border-radius: ${borderRadius(2)};

  color: ${p => p.titleColor || palette('neutral700')};

  ${p =>
    p.collapsible &&
    css`
      cursor: pointer;
      transform: ${p.theme.clickable.initial.transform};
      transition: ${p.theme.clickable.initial.transition};
      transform-origin: 0 center;

      body.tab-key-pressed &:focus {
        box-shadow: ${tabFocusShadowInset};
      }

      &:active {
        transform: ${p.theme.clickable.active.transform};
        transition: ${p.theme.clickable.active.transition};
      }

    `}

  &:hover {
    opacity: 1;
  }
`;

const SpanTitle = styled.span`
  vertical-align: middle;
`;

const SpanTitleIcon = styled('span')<{ completedIconColor?: string }>`
  display: inline;
  white-space: nowrap;
  vertical-align: middle;

  && svg {
    vertical-align: middle;
    margin: 0;
    top: -1px;
    position: relative;
    ${(p) =>
      p.completedIconColor &&
      css`
        color: ${p.completedIconColor};
      `}
  }
`;

const ToggleStateIcon = styled(Icon)<{ isActive?: boolean }>`
  ${(p) => css`
    flex: 0 0 auto;
    position: absolute;
    opacity: 0.6;
    top: 50%;
    // same em as line-height of Toggle
    top: calc(1.3em / 2);
    left: ${spacing(1)};
    transition: transform 300ms;
    transform: rotate(-90deg);

    ${p.isActive &&
    css`
      transform: rotate(0deg);
    `}
  `}
`;

const GroupTitle = styled(Title)`
  margin-bottom: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
`;

const GroupTitleContainer = styled('span')`
  padding: 0 ${spacing(4)};
  position: relative;
  > svg {
    vertical-align: middle;
  }
`;

const CollapsibleContent = styled(motion.div)``;

const List = styled.ol`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Li = styled(
  ({
    spacingV: _spacingV,
    dividerBackground: _dividerBackground,
    showDivider: _showDivider,
    ...rest
  }) => <motion.li {...rest} />
)<{
  spacingV?: number;
  dividerBackground?: string;
  showDivider?: boolean;
}>`
  position: relative;
  padding: ${(p) => (p.spacingV !== undefined ? `${p.spacingV}px` : spacing(1))} 0;

  ${(p) => {
    const fromColor = p.dividerBackground || palette('neutral100');
    const separatorSpacing = 4;
    return (
      p.showDivider &&
      css`
        padding-top: calc(
          ${p.spacingV !== undefined ? `${p.spacingV}px` : spacing(1)} +
            ${spacing(separatorSpacing)}
        );

        &:before {
          position: absolute;
          content: '';
          left: ${spacing(7)};
          right: 0;
          display: block;
          height: 0;
          top: calc(${spacing(separatorSpacing)} / 2);
          border-image: linear-gradient(to right, transparent 0%, ${fromColor} 5%, transparent 100%)
            1;
          border-width: 1px 0 0;
          border-style: solid;
        }
      `
    );
  }}
`;

const ListItemButtonContainer = styled('div')<{
  spacingLeft?: number;
  spacingRight?: number;
  borderRadiusLeft?: number;
  borderRadiusRight?: number;
  lockedIconColor?: string;
}>`
  padding-left: ${(p) => (p.spacingLeft !== undefined ? `${p.spacingLeft}px` : spacing(3))};
  padding-right: ${(p) => (p.spacingRight !== undefined ? `${p.spacingRight}px` : '0')};

  ${Button} {
    display: block;
    width: 100%;
    text-align: left;
    border-width: 0;
    font-weight: ${fontWeight('regular')};
    padding: ${spacing(3)} ${spacing(4)};

    border-radius: ${borderRadiuses(2, 0, 0, 2)};
    ${(p) =>
      p.borderRadiusLeft !== undefined &&
      css`
        border-top-left-radius: ${p.borderRadiusLeft}px;
        border-bottom-left-radius: ${p.borderRadiusLeft}px;
      `}
    ${(p) =>
      p.borderRadiusRight !== undefined &&
      css`
        border-top-right-radius: ${p.borderRadiusRight}px;
        border-bottom-right-radius: ${p.borderRadiusRight}px;
      `}

    svg {
      margin-right: ${spacing(2)} !important;
      ${(p) =>
        p.lockedIconColor &&
        css`
          color: ${p.lockedIconColor};
        `}
    }
  }
`;

const partitionTasks = <T extends IBaseTask>(tasks: T[]) => {
  const allTasks: T[] = tasks;
  const currentTasks: T[] = [];
  const upcomingTasks: T[] = [];

  tasks.forEach((task: T) => {
    switch (task.state) {
      case TaskState.open: {
        currentTasks.push(task);
        break;
      }
      case TaskState.locked: {
        upcomingTasks.push(task);
        break;
      }
      case TaskState.completed: {
        currentTasks.push(task);
        break;
      }
      default: {
        throw new UnreachableCaseError(task.state);
      }
    }
  });

  return { allTasks, currentTasks, upcomingTasks };
};

export interface ITasksListMenuGroupProps<T extends IBaseTask, A extends ITaskApplicant> {
  taskGroup: ITaskGroup<T>;
  activeTaskId?: string;
  applicantId?: string;
  applicants: A[];
  showNames?: boolean;
  showInitials?: boolean;
  showDividers?: boolean;
  collapsible?: boolean;
  isActive?: boolean;
  onToggle?: (key: string) => void;
  renderItem: (
    props: Omit<ITasksListMenuGroupItemProps<T, A>, 'to'>
  ) => ReactElement<ITasksListMenuGroupItemProps<T, A>> | null;
}

export const TasksListMenuGroup = <T extends IBaseTask, A extends ITaskApplicant>(
  props: ITasksListMenuGroupProps<T, A>
) => {
  const {
    taskGroup,
    activeTaskId,
    applicants,
    showNames,
    showInitials,
    collapsible,
    isActive,
    onToggle,
    renderItem,
  } = props;
  const groupState = getGroupState(taskGroup);
  const initialStateRef = useRef<TaskState>(groupState);

  const customizations = useCustomizations();
  const groupTitleColor = customizations.color('app:taskList.group.title.color');
  const spacingLeft = customizations.number('app:taskList.item.spacing.left');
  const spacingRight = customizations.number('app:taskList.item.spacing.right');
  const borderRadiusLeft = customizations.number('app:taskList.item.borderRadius.left');
  const borderRadiusRight = customizations.number('app:taskList.item.borderRadius.right');
  const completedIconColor = customizations.color('app:taskList.item.completed.icon.color');
  const lockedIconColor = customizations.color('app:taskList.item.locked.icon.color');
  const alwaysShowUpcomingTasks = customizations.bool('app:taskList.alwaysShowUpcomingTasks', true);
  const spacingV = customizations.number('app:taskList.item.spacing.vertical');
  const [showUpcoming, setShowUpcoming] = useState(alwaysShowUpcomingTasks);

  const { upcomingTasks, tasks } = useMemo(() => {
    const partitionedTasks = partitionTasks(taskGroup.tasks || []);

    const nextTasks = showUpcoming ? partitionedTasks.allTasks : partitionedTasks.currentTasks;

    return { ...partitionedTasks, tasks: nextTasks };
  }, [ taskGroup.tasks, showUpcoming]);

  const upcomingTaskCount = upcomingTasks.length;

  const keyedApplicants = keyBy(applicants, (v) => v.id);

  const handleToggleClick = useCallback(() => {
    if (!onToggle) {
      return;
    }

    onToggle(taskGroup.key);
  }, [onToggle, taskGroup.key]);

  const handleToggleKeyDown = useCallback(
    (e: KeyboardEvent<any>) => {
      if (onToggle && e.key === 'Enter') {
        onToggle(taskGroup.key);
      }
    },
    [onToggle, taskGroup.key]
  );

  const handleUpcomingClick = useCallback(() => {
    setShowUpcoming((show) => !show);
  }, []);

  useEffect(() => {
    if (!collapsible || !onToggle || !activeTaskId) {
      return;
    }

    if (!isActive && containsTheActiveTask(taskGroup, activeTaskId)) {
      onToggle(taskGroup.key);
    }
    // we don't want this to run when isActive changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTaskId, taskGroup, collapsible, onToggle]);

  useEffect(() => {
    if (!collapsible || !onToggle) {
      return;
    }

    if (isActive && initialStateRef.current !== groupState && groupState === TaskState.completed) {
      onToggle(taskGroup.key);
    }
    // we don't want this to run when isActive changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupState, taskGroup.key, collapsible, onToggle]);

  const groupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive && groupRef.current) {
      groupRef.current.focus();
    }
  }, [isActive, showUpcoming]);

  const menuItems = 

    (tasks || []).map((task) => {
        const taskApplicant = keyedApplicants[task.primaryApplicantId]! || {};

        const itemProps: Omit<ITasksListMenuGroupItemProps<T, A>, 'to'> = {
          task,
          isActive: task.id === activeTaskId,
          applicant: taskApplicant as A,
          showName: showNames,
          showInitial: showInitials,
        };

        return (
          <Li
            spacingV={spacingV}
            key={task.id}
            initial={{ height: 0, overflow: 'hidden' }}
            animate={{
              height: 'auto',
              overflow: 'visible',
              transition: {
                staggerChildren: 0.07,
                ease: 'linear',
                type: 'tween',
              },
            }}
            exit={{
              height: 0,
              overflow: 'hidden',
              transition: {
                staggerChildren: 0.05,
                staggerDirection: -1,
                ease: 'linear',
                type: 'tween',
              },
            }}
          >
            {renderItem(itemProps)}
          </Li>
        );
      });

  const toggleProps =
    (collapsible && {
      role: 'button',
      onClick: handleToggleClick,
      onKeyDown: handleToggleKeyDown,
      tabIndex: 0,
      'aria-controls': `task_group_${taskGroup.key}`,
      'aria-expanded': (isActive ? 'true' : 'false') as 'true' | 'false',
      'data-task-list-group-button': taskGroup.key,
    }) ||
    {};

  const groupProps =
    (collapsible && {
      'aria-labelledby': `task_group_title_${taskGroup.key}`,
      role: 'region',
      tabIndex: -1,
    }) ||
    {};

  const title = (!isFallbackTaskGroup(taskGroup) && taskGroup.label) || (
    <T cid="pageTasks:taskList.title">Tasks</T>
  );

  return (
    <>
      <Toggle
        collapsible={collapsible}
        spacingLeft={spacingLeft}
        titleColor={groupTitleColor}
        {...toggleProps}
      >
        <GroupTitleContainer>
          {collapsible ? (
            <ToggleStateIcon size="xs" name="down-arrow-small" isActive={isActive} />
          ) : null}
          <GroupTitle level={2} id={`task_group_title_${taskGroup.key}`}>
            <SpanTitle>{title}</SpanTitle>
            {groupState === TaskState.completed && (
              <SpanTitleIcon completedIconColor={completedIconColor}>
                &nbsp;
                <TaskGroupStateIcon state={groupState} />
              </SpanTitleIcon>
            )}
          </GroupTitle>
        </GroupTitleContainer>
      </Toggle>
      <div {...groupProps} ref={groupRef} id={`task_group_${taskGroup.key}`}>
        <AnimatePresence initial={false}>
          {isActive ? (
            <CollapsibleContent
              key="content"
              initial={{ height: 0, overflow: 'hidden' }}
              animate={{
                height: 'auto',
                overflow: 'visible',
                transition: { ease: 'easeOut', type: 'tween' },
              }}
              exit={{
                height: 0,
                overflow: 'hidden',
                transition: { ease: 'easeIn', type: 'tween' },
              }}
            >
              <List aria-labelledby={`task_group_title_${taskGroup.key}`}>
                <AnimatePresence initial={false}>{menuItems}</AnimatePresence>
              </List>

              {upcomingTaskCount > 0 && !alwaysShowUpcomingTasks && (
                <ListItemButtonContainer
                  spacingLeft={spacingLeft}
                  spacingRight={spacingRight}
                  borderRadiusLeft={borderRadiusLeft}
                  borderRadiusRight={borderRadiusRight}
                  lockedIconColor={lockedIconColor}
                >
                  <Button
                    icon={showUpcoming ? 'close-circle-dual' : 'locked-circle-dual'}
                    iconSize="md"
                    dimIcon
                    size="xxs"
                    compact
                    type="neutral"
                    importance="tertiary"
                    role="switch"
                    aria-checked={showUpcoming ? 'true' : 'false'}
                    onClick={handleUpcomingClick}
                  >
                    <T
                      cid={
                        showUpcoming
                          ? 'pageTasks:taskList.collapse.upcoming.hideButton.label'
                          : 'pageTasks:taskList.collapse.upcoming.showButton.label'
                      }
                      count={upcomingTaskCount}
                    >
                      {showUpcoming ? 'hide upcoming tasks' : `+${upcomingTaskCount} upcoming`}
                    </T>
                  </Button>
                </ListItemButtonContainer>
              )}
            </CollapsibleContent>
          ) : null}
        </AnimatePresence>
      </div>
    </>
  );
};
