import {
  ApplyStyleIf,
  Button,
  Content,
  fontWeight,
  MediaBreakpoint,
  spacing,
  T,
  Text,
  Title,
} from '@mortgagehippo/ds';
import type * as H from 'history';
import qs from 'qs';
import { useState } from 'react';
import { Redirect } from 'react-router';
import styled, { css } from 'styled-components';

import { ApplicationStateImage } from './application-state-image';

const TaskAuthenticateTitle = styled(Title)``;

const TaskAuthenticateContent = styled.div`
  text-align: center;
  padding: ${spacing(4)} 0 0;

  ${MediaBreakpoint.PHONE} {
    padding: 0;
  }

  ${TaskAuthenticateTitle},
  h1 {
    // adding h1 to account for custom html content
    font-weight: ${fontWeight('semibold', 'secondary')};
  }
`;

const applyIfCSS = css`
  && * {
    text-align: left;
  }
`;

export const TaskAuthenticate = () => {
  const [redirectTo, setRedirectTo] = useState<H.LocationDescriptor>();

  const handleSignup = () => {
    setRedirectTo({
      pathname: '/signup',
      search: `?${qs.stringify({ r: window.location.pathname })}`,
    });
  };

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <TaskAuthenticateContent>
      <ApplicationStateImage cid="dashboard:task.authenticate.image" src="task-authenticate.svg" />
      <Content cid="dashboard:task.authenticate.dangerouslyReplaceContent.html">
        <TaskAuthenticateTitle align="center" cid="dashboard:task.authenticate.title">
          You must create an account to continue
        </TaskAuthenticateTitle>
        <ApplyStyleIf wordsMoreThan={40} applyCSS={applyIfCSS}>
          <Content cid="dashboard:task.authenticate.description">
            <Text align="center" as="p">
              From this point on you need to create an account. Click on the button below
            </Text>
          </Content>
        </ApplyStyleIf>
        <Button importance="primary" onClick={handleSignup}>
          <T cid="dashboard:task.authenticate.createAccountButton.label">Create an account</T>
        </Button>
      </Content>
    </TaskAuthenticateContent>
  );
};
