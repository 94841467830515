import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';
import { useSafeCallback } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import { type LastCreatedApplicationFileQuery } from '../apollo/graphql';
import { useSiteId } from './use-site-id';

export const QLastCreatedApplicationFileQuery = graphql(`
  query LastCreatedApplicationFile($siteId: ID!, $applicantId: ID!) {
    site(id: $siteId) {
      id
      applicant(id: $applicantId) {
        id
        applicationFiles(options: { perPage: 1, orderBy: { created_at: "desc" } }) {
          items {
            id
            createdAt
          }
        }
      }
    }
  }
`);

type IUseLastCreatedApplicationFileIdResult = [
  string | undefined | null,
  boolean,
  () => Promise<ApolloQueryResult<LastCreatedApplicationFileQuery> | undefined>,
];

export const useLastCreatedApplicationFileId = (
  options: IUseApolloQueryOptions = {}
): IUseLastCreatedApplicationFileIdResult => {
  const siteId = useSiteId();
  const [user] = useAuth();

  const { skip, ...restOptions } = options;

  const [data, loading, , { refetch }] = useQuery(
    QLastCreatedApplicationFileQuery,
    { siteId, applicantId: user.id },
    {
      fetchPolicy: 'network-only',
      skip: !user || skip,
      ...restOptions,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    if (!user) {
      return undefined;
    }

    return refetch({ siteId, applicantId: user.id });
  });

  if (loading || !data?.site?.applicant) {
    return [undefined, loading, handleRefresh];
  }

  const [applicationFile] = data.site.applicant.applicationFiles.items;
  const id = applicationFile?.id || null;

  return [id, loading, handleRefresh];
};

export const getLastCreatedApplicationFileId = async (siteId: string, applicantId: string) => {
  const result = await client.query({
    query: QLastCreatedApplicationFileQuery,
    variables: { siteId, applicantId },
    fetchPolicy: 'network-only',
  });

  if (!result.data.site?.applicant) {
    return undefined;
  }

  const [applicationFile] = result.data.site.applicant.applicationFiles.items;
  return applicationFile?.id;
};
