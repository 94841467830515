/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createLayout,
  createSlot,
  type ITemplateProps,
  MediaBreakpoint,
  palette,
  spacing,
  useCustomizations,
} from '@mortgagehippo/ds';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';

import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';

const LayoutGlobalStyles = createGlobalStyle<{ backgroundImageSrc?: string }>`
  body, html {
    overflow: auto;
    background: ${palette('neutral50')};
  }
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;

export const BodyComponent = styled('div')`
  position: relative;
  background: ${palette('neutral50')};
`;

const Box = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 5;
  max-width: 800px;
  margin: 0 auto;
  padding: ${spacing(3)} ${spacing(3)};
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1 1 auto;
  z-index: 2;
  overflow: hidden;
  padding: ${spacing(4)} 0 ${spacing(9)};
`;

export const ContentComponent = styled('main')`
  box-sizing: border-box;
  width: 100%;
  position: relative;

  ${MediaBreakpoint.PHONE} {
    padding: ${spacing(4)} ${spacing(4)} ${spacing(6)} ${spacing(4)};
  }
`;

interface ILegalTemplateProps extends ITemplateProps {
  title?: string;
  titleCid?: string;
}

const Template = (props: ILegalTemplateProps) => {
  const { title: rawTitle, titleCid } = props;

  const customizations = useCustomizations();
  const title = customizations.text(titleCid || '', rawTitle || 'Legal');

  const faviconSrc = useFavicon();

  return (
    <>
      <LayoutGlobalStyles />
      <GlobalScripts />
      <Helmet>
        <title>{title}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <Container id="container">
        <BodyComponent>
          <Box>
            <ContentContainer>
              <Content.Slot />
            </ContentContainer>
          </Box>
        </BodyComponent>
      </Container>
    </>
  );
};

export const Layout = createLayout(Template);
export const Content = createSlot(Layout, ContentComponent);
