export const QuickApplyLogo = () => (
  <svg width="158" height="35" viewBox="0 0 158 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Quick Apply logo</title>
    <g clipPath="url(#clip0_82_19450)">
      <path
        opacity="0.45"
        d="M143.92 6.07031H48.5876C47.8462 6.07031 47.3881 6.89104 47.7335 7.54707V7.54707C47.9007 7.86444 48.2273 8.07031 48.586 8.07031H143.92C150.57 8.07031 155.98 13.4803 155.98 20.1303C155.98 26.7803 150.57 32.1903 143.92 32.1903H46.9058C46.5957 32.1903 46.3073 32.3436 46.1274 32.5962V32.5962C45.6649 33.2458 46.1101 34.1903 46.9076 34.1903H143.92C151.67 34.1903 157.98 27.8803 157.98 20.1303C157.98 12.3803 151.67 6.07031 143.92 6.07031Z"
        fill="#0075B5"
      />
      <path
        d="M26.1601 23.67C26.9101 24.46 28.1701 24.43 28.8801 23.61L41.8201 8.67998C42.4901 7.90998 42.4001 6.74998 41.6301 6.07998C40.8601 5.40998 39.7001 5.49998 39.0301 6.26998L27.4401 19.68L23.3301 15.37C22.6301 14.63 21.4601 14.61 20.7301 15.31C19.9901 16.01 19.9701 17.18 20.6701 17.91L26.1601 23.68"
        fill="#0075B5"
      />
      <path
        d="M56.1698 25.6002C55.7598 25.7602 55.3398 25.8802 54.8998 25.9602C54.4598 26.0402 54.0098 26.0802 53.5398 26.0802C51.9698 26.0802 50.6998 25.5902 49.7298 24.6102C48.7598 23.6302 48.2798 22.3502 48.2798 20.7602C48.2798 19.4702 48.5798 18.1602 49.1898 16.8302C49.7998 15.5002 50.6498 14.4902 51.7398 13.7902C52.8398 13.0902 54.0898 12.7402 55.4898 12.7402C57.0798 12.7402 58.3698 13.2302 59.3498 14.2202C60.3298 15.2102 60.8198 16.5202 60.8198 18.1602C60.8198 19.4102 60.5598 20.6202 60.0298 21.7602C59.4998 22.9102 58.7998 23.8402 57.9198 24.5502C58.4798 25.1002 59.0698 25.5502 59.6998 25.9002L58.4498 27.5402C57.6298 27.1402 56.8698 26.4902 56.1798 25.5702L56.1698 25.6002ZM56.3898 22.7402C56.9198 22.2002 57.3498 21.5002 57.6798 20.6502C57.9998 19.7902 58.1698 18.9902 58.1698 18.2302C58.1698 17.2102 57.8998 16.4102 57.3498 15.8302C56.7998 15.2502 56.1098 14.9602 55.2698 14.9602C54.6898 14.9602 54.1498 15.0902 53.6598 15.3602C53.1698 15.6302 52.7098 16.0102 52.2998 16.5202C51.8898 17.0202 51.5498 17.6502 51.2898 18.3902C51.0298 19.1302 50.8998 19.8802 50.8998 20.6202C50.8998 21.6202 51.1798 22.4102 51.7298 22.9902C52.2798 23.5702 52.9898 23.8602 53.8498 23.8602C54.0898 23.8602 54.3398 23.8402 54.6098 23.8102C54.6998 23.7902 54.7598 23.7702 54.7798 23.7602C54.7998 23.7402 54.8098 23.7202 54.8098 23.6802C54.8098 23.6302 54.7198 23.5302 54.5498 23.3602C54.1798 23.0102 53.7898 22.7602 53.3698 22.6002L54.3398 21.1202C55.1298 21.5202 55.8198 22.0502 56.3998 22.7302L56.3898 22.7402Z"
        fill="#0075B5"
      />
      <path
        d="M63.3497 16.5198H65.8697L64.7197 22.0498C64.5797 22.7098 64.5097 23.1298 64.5097 23.2898C64.5097 23.5598 64.6097 23.7898 64.8097 23.9798C65.0097 24.1698 65.2597 24.2698 65.5697 24.2698C65.7897 24.2698 66.0097 24.2298 66.2297 24.1398C66.4497 24.0498 66.6697 23.9298 66.8997 23.7498C67.1297 23.5698 67.3297 23.3798 67.5197 23.1598C67.7097 22.9398 67.8697 22.6398 68.0297 22.2798C68.1397 22.0098 68.2597 21.5698 68.3897 20.9598L69.3197 16.5098H71.8397L69.8897 25.8398H67.5297L67.7897 24.5698C66.7597 25.5598 65.6397 26.0598 64.4197 26.0598C63.6497 26.0598 63.0497 25.8398 62.6097 25.3998C62.1697 24.9598 61.9497 24.3698 61.9497 23.6298C61.9497 23.3098 62.0497 22.6698 62.2497 21.7098L63.3397 16.5098L63.3497 16.5198Z"
        fill="#0075B5"
      />
      <path
        d="M74.3597 16.5207H76.8797L74.9297 25.8507H72.4097L74.3597 16.5207ZM75.0997 12.9707H77.6197L77.1497 15.2607H74.6297L75.0997 12.9707Z"
        fill="#0075B5"
      />
      <path
        d="M83.74 22.3405L86.19 22.7405C85.79 23.8305 85.19 24.6605 84.4 25.2305C83.61 25.8005 82.69 26.0805 81.65 26.0805C80.46 26.0805 79.52 25.7305 78.82 25.0205C78.12 24.3105 77.77 23.3405 77.77 22.0905C77.77 21.0805 77.98 20.1205 78.4 19.2205C78.82 18.3205 79.46 17.6105 80.31 17.0905C81.16 16.5705 82.09 16.3105 83.1 16.3105C84.2 16.3105 85.08 16.6005 85.74 17.1705C86.4 17.7405 86.77 18.5005 86.85 19.4405L84.45 19.6905C84.39 19.1705 84.23 18.7805 83.97 18.5405C83.71 18.3005 83.37 18.1705 82.95 18.1705C82.46 18.1705 82.01 18.3505 81.59 18.7105C81.17 19.0705 80.84 19.6205 80.62 20.3405C80.4 21.0605 80.28 21.7405 80.28 22.3705C80.28 22.9205 80.41 23.3505 80.68 23.6405C80.95 23.9305 81.29 24.0905 81.69 24.0905C82.09 24.0905 82.48 23.9405 82.86 23.6505C83.24 23.3605 83.53 22.9205 83.74 22.3305V22.3405Z"
        fill="#0075B5"
      />
      <path
        d="M87.3799 25.8605L90.0799 12.9805H92.5999L91.1899 19.6905L94.4799 16.5305H97.7599L94.0099 19.9205L96.0099 25.8605H93.4899L92.2099 21.5405L90.4999 23.0505L89.9099 25.8505H87.3899L87.3799 25.8605Z"
        fill="#0075B5"
      />
      <path
        d="M105.89 23.0105H100.78L99.2298 25.8605H96.5098L103.8 12.9805H106.74L108.83 25.8605H106.32L105.89 23.0105ZM105.57 20.8705L104.82 15.6805L101.71 20.8705H105.57Z"
        fill="#0075B5"
      />
      <path
        d="M112.31 16.5198H114.7L114.5 17.4798C115.01 17.0598 115.49 16.7598 115.93 16.5698C116.37 16.3798 116.85 16.2998 117.36 16.2998C118.34 16.2998 119.12 16.6398 119.72 17.3098C120.32 17.9798 120.61 18.9898 120.61 20.3198C120.61 21.9998 120.1 23.4198 119.07 24.5798C118.19 25.5698 117.15 26.0698 115.95 26.0698C114.75 26.0698 113.78 25.5698 113.15 24.5698L112.14 29.4098H109.62L112.31 16.5198ZM113.83 21.8798C113.83 22.6098 114.01 23.1698 114.36 23.5698C114.71 23.9698 115.13 24.1698 115.61 24.1698C116.03 24.1698 116.43 24.0198 116.81 23.7198C117.19 23.4198 117.52 22.9198 117.77 22.2198C118.02 21.5198 118.15 20.8698 118.15 20.2698C118.15 19.5698 117.98 19.0398 117.65 18.6698C117.32 18.2998 116.89 18.1098 116.38 18.1098C115.9 18.1098 115.46 18.2798 115.07 18.6198C114.67 18.9598 114.37 19.4698 114.15 20.1298C113.93 20.7898 113.82 21.3798 113.82 21.8698L113.83 21.8798Z"
        fill="#0075B5"
      />
      <path
        d="M123.3 16.5198H125.69L125.49 17.4798C126 17.0598 126.48 16.7598 126.92 16.5698C127.36 16.3798 127.84 16.2998 128.35 16.2998C129.33 16.2998 130.11 16.6398 130.71 17.3098C131.31 17.9798 131.6 18.9898 131.6 20.3198C131.6 21.9998 131.09 23.4198 130.06 24.5798C129.18 25.5698 128.14 26.0698 126.94 26.0698C125.74 26.0698 124.77 25.5698 124.14 24.5698L123.13 29.4098H120.61L123.3 16.5198ZM124.82 21.8798C124.82 22.6098 125 23.1698 125.35 23.5698C125.7 23.9698 126.12 24.1698 126.6 24.1698C127.02 24.1698 127.42 24.0198 127.8 23.7198C128.18 23.4198 128.51 22.9198 128.76 22.2198C129.01 21.5198 129.14 20.8698 129.14 20.2698C129.14 19.5698 128.97 19.0398 128.64 18.6698C128.31 18.2998 127.88 18.1098 127.37 18.1098C126.89 18.1098 126.45 18.2798 126.06 18.6198C125.66 18.9598 125.36 19.4698 125.14 20.1298C124.92 20.7898 124.81 21.3798 124.81 21.8698L124.82 21.8798Z"
        fill="#0075B5"
      />
      <path d="M132.41 25.8605L135.11 12.9805H137.62L134.93 25.8605H132.41Z" fill="#0075B5" />
      <path
        d="M138.21 16.5205H140.72L141.35 21.1705C141.51 22.3705 141.6 23.2005 141.61 23.6805C141.94 22.7205 142.39 21.7205 142.96 20.6605L145.19 16.5205H147.86L142.09 26.7705C141.62 27.6205 141.24 28.2105 140.97 28.5505C140.7 28.8905 140.37 29.1605 139.99 29.3505C139.61 29.5405 139.14 29.6405 138.61 29.6405C138.08 29.6405 137.48 29.5505 136.81 29.3605L137.03 27.4805C137.33 27.5705 137.63 27.6105 137.92 27.6105C138.69 27.6105 139.33 27.0205 139.83 25.8505L138.2 16.5205H138.21Z"
        fill="#0075B5"
      />
      <g opacity="0.45">
        <path
          d="M16.52 23.2902C17.26 23.2902 17.86 22.6902 17.86 21.9502C17.86 21.2102 17.26 20.6102 16.52 20.6102H1.34C0.6 20.6102 0 21.2102 0 21.9502C0 22.6902 0.6 23.2902 1.34 23.2902H16.52Z"
          fill="#0075B5"
        />
        <path
          d="M7.09 26.2302H18.26C19 26.2302 19.6 26.8302 19.6 27.5702C19.6 28.3102 19 28.9102 18.26 28.9102H7.09C6.35 28.9102 5.75 28.3102 5.75 27.5702C5.75 26.8302 6.35 26.2302 7.09 26.2302Z"
          fill="#0075B5"
        />
        <path
          d="M13.22 31.5902H20.38C21.12 31.5902 21.72 32.1902 21.72 32.9302C21.72 33.6702 21.12 34.2702 20.38 34.2702H13.22C12.48 34.2702 11.88 33.6702 11.88 32.9302C11.88 32.1902 12.48 31.5902 13.22 31.5902Z"
          fill="#0075B5"
        />
        <path
          d="M28.8 2.67023V5.17023L28.82 5.16023C30.5685 5.3088 32.2235 5.7588 33.7358 6.45626C34.5065 6.81169 34.6639 7.8027 34.1105 8.4461V8.4461C33.6844 8.94142 32.9712 9.05929 32.3743 8.79367C30.8815 8.12945 29.2288 7.76023 27.49 7.76023H27.47C22.22 7.75023 17.57 11.1502 16 16.1602C15.77 16.8302 15.05 17.2002 14.37 17.0102C13.66 16.8102 13.25 16.0702 13.45 15.3602C13.99 13.6602 14.84 12.0802 15.94 10.6802L14.71 9.50023C14.19 8.98023 14.19 8.13023 14.71 7.61023C15.23 7.09023 16.08 7.09023 16.6 7.61023L17.77 8.74023C20.11 6.69023 23.04 5.43023 26.14 5.16023V2.67023H24.87C24.13 2.67023 23.53 2.07023 23.53 1.33023C23.53 0.590234 24.13 -0.00976562 24.87 -0.00976562H30.07C30.81 -0.00976562 31.41 0.590234 31.41 1.33023C31.41 2.07023 30.81 2.67023 30.07 2.67023H28.8Z"
          fill="#0075B5"
        />
        <path
          d="M39.3038 14.8575C38.9569 15.2608 38.866 15.8198 39.0194 16.3291C39.3515 17.4314 39.53 18.6001 39.53 19.8102C39.53 26.4602 34.13 31.8502 27.48 31.8502C27.25 31.8502 27.01 31.8402 26.74 31.8202C26.01 31.7902 25.39 32.3602 25.36 33.0902C25.33 33.8302 25.91 34.4602 26.65 34.4902C26.7448 34.4936 26.8385 34.4981 26.9317 34.5027C27.114 34.5115 27.2948 34.5202 27.48 34.5202C35.6 34.5702 42.23 28.0202 42.28 19.9002C42.2906 18.2678 42.0336 16.692 41.5485 15.2163C41.2258 14.2348 39.9776 14.0742 39.3038 14.8575V14.8575Z"
          fill="#0075B5"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_82_19450">
        <rect width="157.98" height="34.53" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
