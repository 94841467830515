import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useAuth } from '@mortgagehippo/auth';
import { QSaveAnswersMutation } from '@mortgagehippo/tasks';
import { isFunction } from 'lodash-es';
import { useCallback } from 'react';

import { graphql } from '../apollo';
import { client } from '../apollo/apollo-client';
import {
  type AnonymousSessionApplicationFileQuery,
  type AnonymousSessionApplicationFileQueryVariables,
} from '../apollo/graphql';
import { useSiteId } from './use-site-id';

export const QAnonymousSessionApplicationFile = graphql(`
  query AnonymousSessionApplicationFile($siteId: ID!, $applicantId: ID!) {
    site(id: $siteId) {
      id
      applicant(id: $applicantId) {
        id
        applicationFiles(options: { perPage: 1, orderBy: { created_at: "desc" } }) {
          items {
            id
            answers
          }
        }
      }
    }
  }
`);

export const useStartAnonymousSession = () => {
  const siteId = useSiteId();
  const [, , auth] = useAuth();

  const saveAnswers = useMutation(QSaveAnswersMutation);

  return useCallback(
    async (
      answers: Record<string, any> | ((initialAnswers: Record<string, any>) => Record<string, any>),
      shouldThrow = false
    ) => {
      const payload = await auth.maybeStartAnonymousSession();

      if (payload) {
        // we started an anonymous session
        const applicantId = payload.id;

        try {
          /*
           * look up their application file id (since we just created them
           * they will only have one)
           */
          const result = await client.query<
            AnonymousSessionApplicationFileQuery,
            AnonymousSessionApplicationFileQueryVariables
          >({
            query: QAnonymousSessionApplicationFile,
            variables: {
              siteId,
              applicantId,
            },
          });

          const applicationFile =
            (result?.data?.site?.applicant &&
              result.data.site.applicant.applicationFiles.items.length > 0 &&
              result.data.site.applicant.applicationFiles.items[0]) ||
            null;

          if (!applicationFile) {
            throw new Error('Application file not found');
          }

          const { id: applicationFileId, answers: initialAnswers } = applicationFile;

          if (!initialAnswers) {
            // shouldn't happen?
            throw new Error('Answers not found');
          }

          const newAnswers = isFunction(answers) ? answers(initialAnswers) : answers;

          await saveAnswers({
            applicationFileId,
            applicantId,
            answers: newAnswers,
          });
        } catch (e) {
          console.error(e);
          /*
           * we probably want the user to just carry on and possibly re-enter
           * a little bit of info (from the quick quotes) rather than throwing
           * them to the error page?
           */
          if (shouldThrow) {
            throw e;
          }
        }
      }

      return payload;
    },
    [auth, saveAnswers, siteId]
  );
};
