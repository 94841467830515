import { useAuthService } from '@mortgagehippo/auth';
import {
  Alert,
  Button,
  ButtonLink,
  fontSize,
  Image,
  MediaBreakpoint,
  palette,
  spacing,
  T,
  useCustomizations,
  useModalConfirm,
  useResponsive,
} from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled from 'styled-components';

import { useSite } from '../../hooks/use-site';
import { DEFAULT_LOGO_HEIGHT } from '../constants';

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 5;
  gap: ${spacing(5)};
`;

const LogoColumn = styled.div<{ logoHeight: number }>`
  flex: 0 0 auto;
  padding-left: ${spacing(4)};

  & img {
    height: ${(p) => p.logoHeight}px;
    width: auto;
  }

  ${MediaBreakpoint.PHONE} {
    padding-left: ${spacing(3)};
    height: 100%;
    & img {
      height: 100%;
    }
  }
`;

const AlertColumn = styled.div`
  flex: 1 1 auto;
  max-width: 930px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${MediaBreakpoint.PHONE} {
    margin: 0 ${spacing(1)};
    flex: 1 1 100%;
    align-items: flex-end;
    padding-right: ${spacing(2)};
  }
`;

const Section = styled.span`
  margin-right: ${spacing(3)};

  strong {
    color: ${palette('secondary600')};
    font-size: ${fontSize('xs')};
  }

  ${MediaBreakpoint.PHONE} {
    display: block;
    margin: ${spacing(3)} 0;

    strong {
      font-size: ${fontSize('normal')};
    }
  }
`;

interface IDisclosuresHeaderProps {
  applicationFileId: string;
  taskId: string;
}

export const DefaultHeader = (props: IDisclosuresHeaderProps) => {
  const { applicationFileId, taskId } = props;
  const [site] = useSite();

  const auth = useAuthService();
  const customizations = useCustomizations();
  const logoHeight = customizations.number('app:header.logo.height', DEFAULT_LOGO_HEIGHT);

  const responsive = useResponsive();

  const handleRefresh = (e: any) => {
    e.preventDefault();
    auth.login(window.location.href, {}, false);
  };

  const instructionsContent = (
    <>
      <Section>
        <strong>1)</strong>
        &nbsp;Enter your authorization code. Need one? Contact your Loan Officer.{' '}
      </Section>
      <Section>
        <strong>2)</strong>
        &nbsp;Review, sign and click &quot;Done&quot; for all tasks.{' '}
      </Section>
      <Section>
        <strong>3)</strong>
        &nbsp;Once completed, close this browser tab or return to the{' '}
        <ButtonLink to={`/applications/${applicationFileId}/tasks/${taskId}`}>
          <T>Disclosures task</T>
        </ButtonLink>
        .{' '}
      </Section>
      <Section>
        <strong>4)</strong>
        &nbsp;Page not loading? Try to&nbsp;
        <ButtonLink onClick={handleRefresh}>
          <T>sign in again</T>
        </ButtonLink>
        .
      </Section>
    </>
  );

  const [confirm, modalConfirm] = useModalConfirm(instructionsContent, {
    type: 'info',
    hideOkButton: true,
    cancelButtonLabel: 'Close',
    icon: undefined,
    title: 'Help',
  });
  const handleInstructionsClick = useCallback(() => {
    confirm();
  }, [confirm]);

  return (
    <Row>
      <LogoColumn logoHeight={logoHeight!}>
        <Image cid="app:header.logo" src="/maxwell.svg" alt={`${site?.name || ''} logo`} />
      </LogoColumn>
      <AlertColumn>
        {responsive.PHONE.BIGGER ? (
          <Alert size="sm" type="info" showIcon={false}>
            {instructionsContent}
          </Alert>
        ) : (
          <>
            <Button compact size="xxs" importance="secondary" onClick={handleInstructionsClick}>
              Need help?
            </Button>
            {modalConfirm}
          </>
        )}
      </AlertColumn>
    </Row>
  );
};
