import { ApolloHooksProvider } from '@mortgagehippo/apollo-hooks';
import {
  AnonymousGatewayRoute,
  AuthCallbackRoute,
  AuthenticationError,
  AuthLogoutRoute,
  AuthMode,
  AuthProvider,
  AuthUniversalLoginRoute,
  ForbiddenError,
  NoAccountRoute,
  ProtectedRoute,
} from '@mortgagehippo/auth';
import { CustomizationProvider, useCustomizations } from '@mortgagehippo/ds';
import { Stateful } from '@mortgagehippo/stateful';
import {
  DOCUSIGN_EDITOR_PATH,
  DOCUSIGN_SIGN_PATH,
  DOCUSIGN_SIGNED_CALLBACK_PATH,
  DocusignEditorRoute,
  DocusignSignedCallbackRoute,
  DocusignSignRoute,
  UpscopeInit,
  useJornaya,
} from '@mortgagehippo/tasks';
import { ErrorBoundary, NotFoundError, withErrorBoundary } from '@mortgagehippo/util';
import { Route, Router, Switch } from 'react-router';

import { LogoutCountdown } from '$components/logout-countdown';

import { client } from './apollo/apollo-client';
import { config } from './config';
import { AgentLandingPage } from './pages/agent-landing-page';
import { ApplicationsPage } from './pages/applications';
import { AssignPage } from './pages/assign';
import { AuthErrorPage } from './pages/auth-error';
import { ClaimAccountPage } from './pages/claim-account';
import { ConnectPage } from './pages/connect';
import { DisclosuresPage } from './pages/disclosures';
import { DocumentsPage } from './pages/documents';
import { ErrorPage } from './pages/error';
import { ForcedLogoutPage } from './pages/forced-logout';
import { LandingPage } from './pages/landing-page';
import { LegalPage } from './pages/legal';
import { LoadingPage } from './pages/loading';
import { NotFoundPage } from './pages/not-found';
import { ApplicationPreQualificationPageRoute } from './pages/pre-qualification';
import { SessionTimeoutPage } from './pages/session-timeout';
import { SettingsPage } from './pages/settings/settings-page';
import { StartPage } from './pages/start';
import { TasksPage } from './pages/tasks';
import { TeamPage } from './pages/team';
import { withTerms } from './pages/terms';
import { VerifyEmailPage } from './pages/verify-email';
import { auth } from './services/auth';
import { customizationsService } from './services/customizations';
import { history } from './services/history';

const App = () => {
  /*
   * preload BP Customizations.
   * Using customizations.namespace('app') so we can use customizations.loading()
   */
  const customizations = useCustomizations();
  customizations.namespace('default');
  customizations.namespace('app');
  customizations.namespace('settings');

  const pageLoginAllowSignup = customizations.bool('auth:pageLogin.allowSignup', false);
  const pageSignupAllowLogin = customizations.bool('auth:pageSignup.allowLogin', false);

  useJornaya(auth);

  if (customizations.loading()) {
    return <LoadingPage />;
  }

  return (
    <ApolloHooksProvider client={client}>
      <CustomizationProvider service={customizationsService} fallback={<LoadingPage />}>
        <ErrorBoundary
          errors={[ForbiddenError, AuthenticationError]}
          errorComponent={AuthErrorPage}
        >
          <AuthProvider auth={auth} loadingComponent={LoadingPage}>
            <ErrorBoundary errors={[NotFoundError]} errorComponent={NotFoundPage}>
              {config.UPSCOPE_JS_PACKAGE_KEY ? (
                <UpscopeInit packageKey={config.UPSCOPE_JS_PACKAGE_KEY} />
              ) : null}
              <Stateful.Provider>
                <Router history={history}>
                  <Switch>
                    <NoAccountRoute path="/" component={StartPage} exact />
                    <AnonymousGatewayRoute path="/start" allowLogin={pageSignupAllowLogin} exact />
                    <Route path="/continue/:applicationFileId?" component={StartPage} exact />
                    <ProtectedRoute
                      path="/applications/:applicationFileId?"
                      component={withTerms(ApplicationsPage)}
                      exact
                    />
                    <ProtectedRoute
                      path="/applications/:applicationFileId/tasks/:taskId?/:showMobileTaskList?"
                      component={withTerms(TasksPage)}
                      exact
                    />
                    <ProtectedRoute
                      path="/applications/:applicationFileId/disclosures/:taskId"
                      component={withTerms(DisclosuresPage)}
                      exact
                    />
                    <ProtectedRoute
                      path={DOCUSIGN_SIGNED_CALLBACK_PATH}
                      component={DocusignSignedCallbackRoute}
                    />
                    <ProtectedRoute path={DOCUSIGN_EDITOR_PATH} component={DocusignEditorRoute} />
                    <ProtectedRoute path={DOCUSIGN_SIGN_PATH} component={DocusignSignRoute} />
                    <ProtectedRoute
                      path="/applications/:applicationFileId/documents"
                      component={withTerms(DocumentsPage)}
                      exact
                    />
                    <ProtectedRoute
                      path="/applications/:applicationFileId/prequalification"
                      component={withTerms(ApplicationPreQualificationPageRoute)}
                      exact
                    />
                    <ProtectedRoute
                      path="/settings"
                      component={withTerms(SettingsPage)}
                      allowAnonymous={false}
                    />
                    <Route path="/people/:slug" component={AgentLandingPage} exact />
                    <Route path="/team/:slug" component={TeamPage} exact />
                    <Route path="/legal/:slug" component={LegalPage} exact />
                    <Route path="/session-timeout" component={SessionTimeoutPage} />
                    <Route path="/forced-logout" component={ForcedLogoutPage} />
                    <Route path="/connect/:id?" component={ConnectPage} />
                    <Route path="/assign/:ignored/:assignCode" component={AssignPage} />
                    <AuthUniversalLoginRoute
                      mode={AuthMode.LOGIN}
                      path="/login"
                      allowSignup={pageLoginAllowSignup}
                      exact
                    />
                    <AuthUniversalLoginRoute
                      mode={AuthMode.SIGN_UP}
                      path="/signup"
                      allowLogin={pageSignupAllowLogin}
                      exact
                    />
                    <Route path="/claim/:claimId?" component={ClaimAccountPage} exact />
                    <Route
                      path="/verify-email/:applicantId/:code"
                      component={VerifyEmailPage}
                      exact
                    />
                    <AuthCallbackRoute path="/authorize" />
                    <AuthLogoutRoute path="/logout" />
                    <Route component={LandingPage} />
                  </Switch>
                </Router>
                <LogoutCountdown />
              </Stateful.Provider>
            </ErrorBoundary>
          </AuthProvider>
        </ErrorBoundary>
      </CustomizationProvider>
    </ApolloHooksProvider>
  );
};

export const Root = withErrorBoundary(ErrorPage)(App);
