import { spacing, Title, useCustomizations } from '@mortgagehippo/ds';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { Footer } from '$components/footer';

import { Content, Layout } from '../../layouts/main';
import { ApplicationPreQualificationPage } from './pre-qualification-page';

interface IApplicationPreQualificationRouteParams {
  applicationFileId: string;
}

const Disclaimer = styled.div`
  margin-top: ${spacing(7)};
  padding-bottom: ${spacing(7)};
  text-align: center;
`;

export const ApplicationPreQualificationPageRoute = (
  props: RouteComponentProps<IApplicationPreQualificationRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { applicationFileId } = params;

  const customizations = useCustomizations();
  const prequalLetterLabel = customizations.text(
    'preQualification:letter.label',
    'Pre-Qualification Letter'
  );

  return (
    <Layout applicationFileId={applicationFileId}>
      <Content>
        <Title level={1} cid="pagePreQualification:title">
          {prequalLetterLabel}
        </Title>
        <ApplicationPreQualificationPage applicationFileId={applicationFileId} />
        <Disclaimer>
          <Footer modal />
        </Disclaimer>
      </Content>
    </Layout>
  );
};
