import { get } from 'lodash-es';
import * as uuid from 'uuid';

/*
 * TODO: this logic should really be in the backend? in the saga?
 * refactor to pass the answers thru the auth service so that the saga itself
 * can set the initial answers when creating an application file?
 */
export const reconcileTargetProperty = (answers: any, initialAnswers: any) => {
  if (!get(answers, 'collaterals')) {
    // we are not messing with collaterals, no need to do anything
    return answers;
  }

  const newTargetProperty = answers.collaterals.find((c: any) => c.target_loan_property === true);

  if (!newTargetProperty) {
    // we are not messing with the target property / no need to do anything
    return answers;
  }

  /*
   * if the collateral with `target_loan_property` already exists and we are changing it
   * we are going to need its id to be able to perform any updates
   */
  const { collaterals = [] } = initialAnswers || {};

  const initialTargetProperty = collaterals.find((c: any) => c.target_loan_property === true);

  if (initialTargetProperty) {
    newTargetProperty.id = initialTargetProperty.id;
  } else {
    newTargetProperty.__id = uuid.v4();
  }

  return {
    ...answers,
    collaterals: [
      // pass along any new collaterals we may be trying to add
      ...answers.collaterals.filter((c: any) => c.target_loan_property === false),
      newTargetProperty,
    ],
  };
};
