export const MAIN_NAV_SIZE = 80;

export const HEADER_SIZE = 85;
export const HEADER_SIZE_PHONE = 40;

export const CONTENT_NAV_WIDTH = 400;
export const SIDE_BAR_WIDTH = 400;

export const DEFAULT_LOGO_HEIGHT = 44;
export const DEFAULT_LOGO_HEIGHT_MOBILE = 25;

export const APPLICATION_NAVIGATION_ID = 'application_navigation';
export const CONTENT_NAVIGATION_ID = 'content_navigation';
export const MAIN_CONTENT_ID = 'main_content';
