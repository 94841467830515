/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  borderRadius,
  borderRadiuses,
  createLayout,
  createSlot,
  type IShadow,
  type ITemplateProps,
  MediaBreakpoint,
  palette,
  shadow,
  SkipLinks,
  type SkipLinksArray,
  spacing,
  useCustomizations,
  useResponsive,
} from '@mortgagehippo/ds';
import { ErrorBoundary } from '@mortgagehippo/util';
import { type ComponentType, forwardRef, useMemo } from 'react';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle, css } from 'styled-components';

import { GlobalScripts } from '$components/global-scripts';

import { useFavicon } from '../../hooks/use-favicon';
import { useSite } from '../../hooks/use-site';
import {
  APPLICATION_NAVIGATION_ID,
  CONTENT_NAV_WIDTH,
  CONTENT_NAVIGATION_ID,
  HEADER_SIZE,
  HEADER_SIZE_PHONE,
  MAIN_CONTENT_ID,
  MAIN_NAV_SIZE,
} from '../constants';
import { ContentError } from './content-error';
import { DefaultHeader } from './default-header';
import { DefaultNavigation } from './default-navigation';
import { Sidebar } from './sidebar';
import { useLayoutResetScrollTop } from './use-layout-reset-scroll-top';

const LayoutGlobalStyles = createGlobalStyle<{ bgrImageSrc?: string }>`
  body, html {
    overflow: hidden;

    ${MediaBreakpoint.PHONE} {
      overflow: auto;

      // enables body scrolling to hide browser nav bars and remove 
      // double scroll bar issue on iPhone Safari
      height: auto !important;
    }

    ${(p) =>
      p.bgrImageSrc &&
      css`
        background-image: url(${p.bgrImageSrc});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
      `}
  }
`;

const Container = styled('div')<{
  maxWidth?: number;
  borderColor?: string;
  shadow: IShadow;
  shadowIntense?: boolean;
}>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  max-width: ${(p) => (p.maxWidth && `${p.maxWidth}px`) || 'none'};

  border: ${(p) => (p.borderColor && `1px solid ${p.borderColor}`) || 'none'};
  border-top: none;
  border-bottom: none;
  box-shadow: ${(p) => shadow(p.shadow, (p.shadowIntense && 'intense') || undefined)};

  /* do not display side borders if not at min width */
  @media screen and (max-width: ${(p) => (p.maxWidth && `${p.maxWidth}px`) || '10000px'}) {
    border: none;
  }

  ${MediaBreakpoint.PHONE} {
    height: auto;
    border: none;
    box-shadow: none;
  }
`;

/*
 * we need the header bgr to be a separate element to be positioned below
 * Box and below HeaderComponent. This way HeaderComponent can be above Box.
 * This combination will allow the Box's Content shadow to be above the header background
 * while the header content is above the Box to allow for Header dropdown and milestone content to
 * overflow above the content and Box as necessary.
 */
const HeaderBackground = styled('div')<{
  bgColor: string;
  headerSize: number;
  headerSizeMobile: number;
  aboveContent?: boolean;
}>`
  height: ${(p) => p.headerSize}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${(p) => p.bgColor};
  z-index: ${(p) => (p.aboveContent ? '15' : '0')};

  ${MediaBreakpoint.PHONE} {
    /* the header background element will be placed inside the header */
    height: 100%;
    z-index: 0;
  }
`;

const HeaderComponent = styled('header')<{
  headerSize: number;
  headerSizeMobile: number;
  headerBorderColor?: string;
  headerShadow?: IShadow;
  headerShadowIntense?: boolean;
}>`
  flex: 0 0 ${(p) => p.headerSize}px;
  height: ${(p) => p.headerSize}px;
  position: relative;
  z-index: 20;

  box-shadow: ${(p) =>
    shadow(p.headerShadow || 0, (p.headerShadowIntense && 'intense') || undefined)};

  border-bottom: ${(p) =>
    p.headerBorderColor && p.headerBorderColor.toLowerCase() !== 'transparent'
      ? `1px solid ${p.headerBorderColor}`
      : 'none'};

  ${MediaBreakpoint.PHONE} {
    min-height: ${(p) => p.headerSizeMobile}px;
    max-height: none;
    height: auto;
    flex: 0 0 auto;
    padding: ${spacing(2)} 0;
  }
`;

export const BodyComponent = styled('div')<{ headerSize: number }>`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  height: calc(100vh - ${(p) => p.headerSize}px);

  ${MediaBreakpoint.PHONE} {
    flex-direction: column;
    height: auto;
  }
`;

const NavComponent = styled('nav')<{
  mainNavSize: number;
  background: string;
  backgroundMobile?: string;
  'aria-label': string;
}>`
  flex: 0 0 ${(p) => p.mainNavSize}px;
  position: relative;
  background: ${(p) => p.background};
  z-index: 0;

  &:empty {
    display: none;
  }

  &:after {
    /* this adds background under the main content top left corner if it has border-radius */
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    top: 0;
    right: -20px;
    background: ${(p) => p.background};
    position: absolute;
    z-index: 0;
  }

  ${MediaBreakpoint.PHONE} {
    flex-basis: auto;
    background: ${(p) => p.backgroundMobile || p.background};

    &:after {
      display: none;
    }
  }
`;

const Box = styled.div<{
  shadow: IShadow;
  shadowIntense: boolean;
  shadowMobile: IShadow;
  shadowIntenseMobile: boolean;
  borderRadius?: number;
  contentBorderColor?: string;
}>`
  display: flex;
  flex: 1 1 auto;
  border-radius: ${(p) =>
    p.borderRadius !== undefined ? `${p.borderRadius}px 0 0 0` : borderRadiuses(2, 0, 0, 0)};
  box-shadow: ${(p) => shadow(p.shadow, (p.shadowIntense && 'intense') || undefined)};
  background: ${palette('neutral50')};
  overflow: hidden;
  border-left: ${(p) => (p.contentBorderColor && `1px solid ${p.contentBorderColor}`) || 'none'};
  position: relative;
  z-index: 5;

  ${NavComponent}:empty + & {
    border-radius: 0;
  }

  ${MediaBreakpoint.PHONE} {
    border-radius: 0;
    flex-direction: column;
    box-shadow: ${(p) => shadow(p.shadowMobile, (p.shadowIntenseMobile && 'intense') || undefined)};
    border-left: none;
    border-top: ${(p) => (p.contentBorderColor && `1px solid ${p.contentBorderColor}`) || 'none'};
  }
`;

/*
 * flex component that contains the non-flex scrollable area.
 * This acrobatics is needed to show the scrollbars in Chrome when scrolling
 */
const ContentNavContainer = styled.div<{ contentNavWidth: number }>`
  flex: 0 0 ${(p) => p.contentNavWidth}px;
  z-index: 3;
  overflow: hidden;

  &:empty {
    display: none;
  }

  ${MediaBreakpoint.PHONE} {
    flex-basis: auto;
  }
`;

const ContentNavComponent = styled('nav')<{
  backgroundColor?: string;
  borderColor?: string;
  'aria-label': string;
}>`
  width: 100%;
  height: 100%;
  background: ${(p) => p.backgroundColor || palette('white')};
  border-right: ${(p) => (p.borderColor ? `1px solid ${p.borderColor}` : 'none')};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  ${MediaBreakpoint.PHONE} {
    overflow-x: hidden;
    overflow-y: hidden;
    height: auto;
    border-right: none;
  }
`;

/*
 * flex component that contains the non-flex scrollable area.
 * This acrobatics is needed to show the scrollbars in Chrome when scrolling
 */
const ContentContainer = styled.div`
  flex: 1 1 auto;
  z-index: 2;
  overflow: hidden;

  &:first-child {
    border-radius: ${borderRadiuses(2, 0, 0, 0)};
  }

  &:nth-child(2) {
    /* border-top-right-radius: ${borderRadius(3)}; */
    /* box-shadow: ${shadow(2)}; */
  }

  &:last-child {
    border-top-right-radius: 0;
  }

  ${MediaBreakpoint.PHONE} {
    border-radius: 0;
    box-shadow: none;
  }
`;

export const StyledContentComponent = styled('main')<{
  backgroundColor?: string;
}>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;

  background: ${(p) => p.backgroundColor || palette('neutral50')};
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  padding: ${spacing(6)} ${spacing(7)};

  ${MediaBreakpoint.PHONE} {
    overflow-x: hidden;
    overflow-y: hidden;
    padding: ${spacing(5)} ${spacing(4)} ${spacing(6)} ${spacing(4)};
    height: auto;
    min-height: 100vh;
  }
`;

const ContentComponent: ComponentType<any> = forwardRef<any>((props, ref) => (
  <ErrorBoundary errorComponent={ContentError}>
    <StyledContentComponent tabIndex={-1} {...props} ref={ref} />
  </ErrorBoundary>
));

interface IMainTemplateProps extends ITemplateProps {
  title?: string;
  titleCid?: string;
  applicationFileId?: string;
}

export const Template = (props: IMainTemplateProps) => {
  const { title: rawTitle, titleCid, applicationFileId } = props;
  const { scrollRef: contentRef } = useLayoutResetScrollTop();

  const [site] = useSite();
  const customizations = useCustomizations();
  const faviconSrc = useFavicon();
  const responsive = useResponsive();
  const title = customizations.text(titleCid, rawTitle || 'Application');
  const pageTitle = (site && `${title} | ${site.name} `) || title;
  const mainNavSize = customizations.number('app:appNav.width', MAIN_NAV_SIZE);
  const mainNavBackground = customizations.color('app:appNav.background', 'transparent');
  const mainNavBackgroundMobile = customizations.color('app:appNav.backgroundMobile');
  const contentNavBackground = customizations.color('app:content.nav.background');
  const contentNavBorderColor = customizations.color('app:content.nav.border.color');
  const contentBackground = customizations.color('app:content.background');
  const contentNavWidth = customizations.number('app:content.nav.width', CONTENT_NAV_WIDTH);
  const contentShadow = customizations.shadow('app:content.shadow.level', 3);
  const contentShadowIntense = customizations.bool('app:content.shadow.intense', true);
  const contentShadowMobile = customizations.shadow('app:content.shadow.levelMobile', 3);
  const contentShadowIntenseMobile = customizations.bool('app:content.shadow.intenseMobile', true);
  const contentBorderRadius = customizations.number('app:content.border.radius');
  const contentBorderColor = customizations.color('app:content.border.color');
  const headerSize = customizations.number('app:header.height', HEADER_SIZE);
  const headerSizeMobile = customizations.number('app:header.heightMobile', HEADER_SIZE_PHONE);
  const headerBgColor = customizations.color('app:header.background', 'transparent');
  const headerBorderColor = customizations.color('app:header.border.color', 'transparent');
  const appBgrSrc = customizations.image('app:background.image');
  const siteShadow = customizations.shadow('app:site.shadow.level', 0);
  const siteShadowIntense = customizations.bool('app:site.shadow.intense', false);
  const headerShadow = customizations.shadow('app:header.shadow.level');
  const headerShadowIntense = customizations.bool('app:header.shadow.intense', false);
  const siteBorderColor = customizations.color('app:site.border.color');
  const siteMaxWidth = customizations.number('app:site.maxWidth');
  const skipLabelApplicationNav = customizations.text(
    'layoutMain:skipLink.applicationNav',
    'Skip to application navigation'
  );
  const skipLabelContentNav = customizations.text(
    'layoutMain:skipLink.contentNav',
    'Skip to content navigation'
  );
  const skipLabelMainContent = customizations.text(
    'layoutMain:skipLink.mainContent',
    'Skip to main content'
  );
  const skipLinks: SkipLinksArray = [
    {
      label: skipLabelApplicationNav,
      id: APPLICATION_NAVIGATION_ID,
    },
    {
      label: skipLabelContentNav,
      id: CONTENT_NAVIGATION_ID,
    },
    {
      label: skipLabelMainContent,
      id: MAIN_CONTENT_ID,
    },
  ];

  const HeaderBackgroundEl = useMemo(
    () => (
      <HeaderBackground
        bgColor={headerBgColor!}
        headerSize={headerSize!}
        headerSizeMobile={headerSizeMobile!}
        aboveContent={!!headerShadow}
      />
    ),
    [headerBgColor, headerSize, headerSizeMobile, headerShadow]
  );

  return (
    <>
      <LayoutGlobalStyles bgrImageSrc={appBgrSrc || undefined} />
      <GlobalScripts />
      <Helmet>
        <title>{pageTitle}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <Container
        id="container"
        maxWidth={siteMaxWidth}
        borderColor={siteBorderColor}
        shadow={siteShadow!}
        shadowIntense={siteShadowIntense}
        className="mh-layout"
      >
        {!responsive.PHONE.EXACT_OR_SMALLER && HeaderBackgroundEl}
        <Header.Slot
          headerSize={headerSize}
          headerSizeMobile={headerSizeMobile}
          headerBorderColor={headerBorderColor}
          headerShadow={headerShadow}
          headerShadowIntense={headerShadowIntense}
          className="mh-header"
        >
          {responsive.PHONE.EXACT_OR_SMALLER ? HeaderBackgroundEl : null}
          <SkipLinks links={skipLinks} />
          <DefaultHeader />
        </Header.Slot>
        <BodyComponent headerSize={headerSize!} className="mh-layout-body">
          <Nav.Slot
            id={APPLICATION_NAVIGATION_ID}
            mainNavSize={mainNavSize}
            background={mainNavBackground}
            backgroundMobile={mainNavBackgroundMobile}
            aria-label="Application Navigation"
            className="mh-app-nav"
          >
            <DefaultNavigation applicationFileId={applicationFileId} />
          </Nav.Slot>
          <Box
            shadow={contentShadow}
            shadowIntense={contentShadowIntense}
            shadowMobile={contentShadowMobile}
            shadowIntenseMobile={contentShadowIntenseMobile}
            borderRadius={contentBorderRadius}
            contentBorderColor={contentBorderColor}
            className="mh-content-container"
          >
            <ContentNavContainer contentNavWidth={contentNavWidth} className="mh-content-nav">
              <ContentNav.Slot
                backgroundColor={contentNavBackground}
                borderColor={contentNavBorderColor}
                id={CONTENT_NAVIGATION_ID}
              />
            </ContentNavContainer>
            <ContentContainer className="mh-content">
              <Content.Slot
                ref={contentRef}
                backgroundColor={contentBackground || undefined}
                id={MAIN_CONTENT_ID}
              />
            </ContentContainer>
            {applicationFileId ? <Sidebar applicationFileId={applicationFileId} /> : null}
          </Box>
        </BodyComponent>
      </Container>
    </>
  );
};

export const Layout = createLayout(Template);
export const Header = createSlot(Layout, HeaderComponent);
export const Nav = createSlot(Layout, NavComponent);
export const ContentNav = createSlot(Layout, ContentNavComponent);
export const Content = createSlot(Layout, ContentComponent);
