import { fontWeight, T } from '@mortgagehippo/ds';
import { type ITaskApplicant } from '@mortgagehippo/tasks';
import { trim } from 'lodash-es';
import styled, { css } from 'styled-components';

import { type IApplicationFileDocumentTask } from '../documents/use-application-file-documents';

const ApplicantName = styled.span<{ noWrap?: boolean }>`
  font-weight: ${fontWeight('light')};

  ${(p) =>
    p.noWrap &&
    css`
      white-space: nowrap;
    `}
`;

interface ITasksListTitleProps {
  task: IApplicationFileDocumentTask;
  applicant: ITaskApplicant;
  showName?: boolean;
  showInitial?: boolean;
  noWrap?: boolean;
  className?: string;
}

export const TasksListTitle = (props: ITasksListTitleProps) => {
  const { task, applicant, showName, showInitial, noWrap, className } = props;
  const { meta, common } = task;
  const { title } = meta;

  const applicantInitial =
    (showInitial && applicant.lastName && `${applicant.lastName[0]?.toUpperCase()}.`) || '';
  const applicantName = (applicant.firstName &&
    trim(`${applicant.firstName} ${applicantInitial}`)) || (
    <>
      <T cid="pageTasks:taskList.borrower.genericLabel">Borrower</T>
      {` #${(applicant.position || 0) + 1}`}
    </>
  );

  return (
    <span className={className}>
      {!common && showName ? (
        <ApplicantName noWrap={noWrap}>{applicantName}:&nbsp;</ApplicantName>
      ) : null}
      {title}
    </span>
  );
};
