import { Stateful } from '@mortgagehippo/stateful';
import { useCallback, useState } from 'react';

export const useTaskId = Stateful.createHook(() => {
  const [applicationFileId, setApplicationFileId] = useState<string | null>();
  const [taskId, setTaskId] = useState<string | null>();

  const set = useCallback((nextApplicationFileId: string, nextTaskId: string) => {
    setApplicationFileId(nextApplicationFileId);
    setTaskId(nextTaskId);
  }, []);

  return [{ applicationFileId, taskId }, set] as const;
});
