import { useQuery } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { history } from '../../../services/history';
import { PasswordSettingsDisabled } from './password-settings-disabled';
import { PasswordSettingsForm } from './password-settings-form';
import { QCanResetPassword } from './queries';
import { useResetPassword } from './use-reset-password';

const LOGOUT_DELAY = 7000;

export const PasswordSettings = () => {
  const [canResetPasswordData, canResetPasswordLoading] = useQuery(QCanResetPassword);

  const canResetPassword =
    !!canResetPasswordData && !!canResetPasswordData.me && canResetPasswordData.me.canResetPassword;

  const resetPassword = useResetPassword();

  const handleSubmit = useCallback(
    async (values: any) => {
      const { currentPassword, newPassword } = values;

      const data = {
        newPassword,
        currentPassword,
      };

      try {
        const response = await resetPassword(data);

        const { status, message } = response;

        if (status !== 200) {
          // TODO: what are these errors? can we customize them?
          notifications.error({
            title: 'An Error Occurred',
            message,
          });
          return;
        }

        notifications.success({
          messageCid: 'pageSettings:changePassword.success.message',
          title: 'Password Reset Successfully',
          message: 'You will be redirected to sign in with your new password.',
          duration: LOGOUT_DELAY,
        });
        setTimeout(() => {
          history.push('/logout');
        }, LOGOUT_DELAY);
      } catch (error) {
        notifications.error({
          messageCid: 'pageSettings:changePassword.error.processing.message',
          message: 'There was an error processing your request, please try again later.',
        });
      }
    },
    [resetPassword]
  );

  if (canResetPasswordLoading) {
    return null;
  }

  if (!canResetPassword) {
    return <PasswordSettingsDisabled />;
  }

  return <PasswordSettingsForm onSubmit={handleSubmit} />;
};
