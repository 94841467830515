import { Alert, Button, T, useCustomizations } from '@mortgagehippo/ds';
import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/login';
import { auth } from '../../services/auth';

const StyledAlert = styled(Alert)`
  text-align: left;
`;

export const UnknownAuthErrorPage = (_props: IErrorComponentProps<any>) => {
  const customizations = useCustomizations();
  const title = customizations.text('pageAuthError:general.title', 'Authentication Failed');
  const message = customizations.text(
    'pageAuthError:general.message',
    `We could not verify your account credentials, possibly because your session expired. Please sign in again.`
  );
  const buttonLabel = customizations.text(
    'pageAuthError:general.signInButton.label',
    'Sign in again'
  );

  const handleLogin = () => {
    auth.login();
  };

  return (
    <Layout title={title}>
      <Content>
        <StyledAlert type="warning">{message}</StyledAlert>

        <Button
          onClick={handleLogin}
          importance="primary"
          icon="right-arrow"
          iconLocation="right"
          block
        >
          <T>{buttonLabel}</T>
        </Button>
      </Content>
    </Layout>
  );
};
