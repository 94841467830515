import { AuthState, useAuth } from '@mortgagehippo/auth';
import { LandingPage, useAgentLandingPage } from '@mortgagehippo/tasks';
import { NotFoundError } from '@mortgagehippo/util';
import { useCallback, useEffect, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../hooks/use-site';
import { useSiteAgent } from '../../hooks/use-site-agent';
import { useSiteId } from '../../hooks/use-site-id';
import { useStartAnonymousSession } from '../../hooks/use-start-anonymous-session';
import { Content, Layout } from '../../layouts/landing';
import { history } from '../../services/history';
import { reconcileTargetProperty } from '../landing-page/util';

interface IAgentLandingPageParams {
  slug: string;
}

type IAgentLandingPageProps = RouteComponentProps<IAgentLandingPageParams>;

export const AgentLandingPage = (props: IAgentLandingPageProps) => {
  const { match } = props;
  const { params } = match;
  const { slug } = params;

  const siteId = useSiteId();
  const [site, siteLoading] = useSite();
  const [agent, agentLoading] = useSiteAgent(slug);
  const [{ data: landingPage, loading: landingPageLoading }] = useAgentLandingPage(siteId);

  const [, authState, auth] = useAuth();

  const startAnonymousSession = useStartAnonymousSession();

  const loading = siteLoading || agentLoading || landingPageLoading;

  const handleStartApplication = useCallback(
    async (answers?: any) => {
      /*
       * since we don't necessarily want to redirect to /start we need
       * to handle this stuff here
       */
      if (authState !== AuthState.NO_ACCOUNT) {
        history.push(auth.getAfterLoginPath());
        return;
      }

      if (!auth.getAllowAnonymousAccounts()) {
        history.push('/signup');
        return;
      }

      if (answers) {
        // manually start an anonymous session so we can save answers
        await startAnonymousSession((initialAnswers) =>
          reconcileTargetProperty(answers, initialAnswers)
        );

        history.push(auth.getAfterLoginPath());
        return;
      }

      history.push('/start');
    },
    [auth, authState, startAnonymousSession]
  );

  const handleResumeApplication = useCallback(() => {
    // will automatically redirect person to afterLoginPath if they are already logged in
    history.push('/login');
  }, []);

  useEffect(() => {
    if (authState === AuthState.NO_ACCOUNT && agent?.visible) {
      auth.setApplicationMetadata({ agent_slug: slug });
    }
  }, [agent, slug, auth, authState]);

  const variables = useMemo(() => {
    if (!site || !agent) {
      return {};
    }

    const { name } = site;
    const {
      name: agentName,
      email,
      phone,
      license,
      licensedStates,
      bio,
      jobTitle,
      facebookUrl,
      linkedinUrl,
      twitterUrl,
      avatarUrl,
    } = agent;

    /*
     * need to maintain backwards compatibility after the apollo
     * camel case changes.
     */
    return {
      site: {
        name,
      },
      agent: {
        name: agentName,
        email,
        phone,
        license,
        license_states: licensedStates,
        bio,
        job_title: jobTitle,
        facebook_url: facebookUrl,
        linkedin_url: linkedinUrl,
        twitter_url: twitterUrl,
        avatar_url: avatarUrl,
      },
    };
  }, [agent, site]);

  if (loading) {
    return null;
  }

  if (!site || !landingPage || !agent?.visible) {
    throw new NotFoundError();
  }

  return (
    <Layout title={agent.name || 'Page'}>
      <Content>
        <LandingPage
          landingPage={landingPage}
          variables={variables}
          onStartApplication={handleStartApplication}
          onResumeApplication={handleResumeApplication}
        />
      </Content>
    </Layout>
  );
};
