import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo';
import { useSiteId } from './use-site-id';

const QClaimEmail = graphql(`
  query ClaimEmail($siteId: ID!, $claimId: String!) {
    site(id: $siteId) {
      id
      applicantEmail(claimId: $claimId)
    }
  }
`);

export const useClaimEmail = (claimId: string, options: IUseApolloQueryOptions = {}) => {
  const siteId = useSiteId();

  const [data, loading] = useQuery(
    QClaimEmail,
    { siteId, claimId },
    {
      ...options,
      throw: false,
    }
  );

  const result = data?.site?.applicantEmail;

  return [result, loading] as const;
};
